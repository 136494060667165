import { checkResponse, getHeader } from "../../utils/utils";

export default {
  async updateProjectTickets(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body) {
      if (body[key] && typeof body[key] === "object")
        bodyFormData.append(key, JSON.stringify(body[key]));
      else bodyFormData.append(key, body[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/ProjectTickets/${body.project_id}`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async updateProjectTask(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body) {
      if (body[key] && typeof body[key] === "object")
        bodyFormData.append(key, JSON.stringify(body[key]));
      else bodyFormData.append(key, body[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/project/task/${body.task_id}`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async createProjectTask(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body) {
      if (body[key] && typeof body[key] === "object")
        bodyFormData.append(key, JSON.stringify(body[key]));
      else bodyFormData.append(key, body[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/project/task`,
      {
        method: "POST",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async reorderTask(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body) {
      if (body[key] && typeof body[key] === "object")
        bodyFormData.append(key, JSON.stringify(body[key]));
      else bodyFormData.append(key, body[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/project/task/reordertask`,
      {
        method: "POST",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async reodertaskrow(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body) {
      if (body[key] && typeof body[key] === "object")
        bodyFormData.append(key, JSON.stringify(body[key]));
      else bodyFormData.append(key, body[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/project/task/reodertaskrow`,
      {
        method: "POST",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async getProjectAllTasks(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/project/task/${body.project_id}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
};
