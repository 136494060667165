import api from "../api";
const getDefaultState = () => {
  return {};
};

export default {
  state: getDefaultState(),
  actions: {
    async authorizeNewtin({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.authorizeNewtin(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async getTypeOfWork({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.getTypeOfWork(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async getMeansOfExcavation({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.getMeansOfExcavation(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async getCounties({ state }, payload) {
      const resp = await api.newtin.getPlaces(payload);
      return resp;
    },
    async getPlaces({ state }, payload) {
      const resp = await api.newtin.getPlaces(payload);
      return resp;
    },
    async getCenterTickets({ state }, payload) {
      const resp = await api.newtin.getTickets(payload);
      return resp;
    },
    async getTicketsInfo({ state }, payload) {
      const resp = await api.newtin.getTicketsInfo(payload);
      return resp;
    },
    async getAddress({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.getAddress(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async getAutoSuggestion({ state }, body) {
      let suggestionResponse = {};
      const resp = await api.newtin.getAutoSuggestion(body);
      suggestionResponse = resp;
      return suggestionResponse;
    },
    async getAutoSuggestionCross({ state }, body) {
      let suggestionResponse = {};
      const resp = await api.newtin.getAutoSuggestionCross(body);
      suggestionResponse = resp;
      return suggestionResponse;
    },
    async getAutoSuggestionBetween({ state }, body) {
      let suggestionResponse = {};
      const resp = await api.newtin.getAutoSuggestionBetween(body);
      suggestionResponse = resp;
      return suggestionResponse;
    },
    async getCountyPlacesWithReverseGeocode({ state }, body) {
      let suggestionResponse = {};
      const resp = await api.newtin.getCountyPlacesWithReverseGeocode(body);
      suggestionResponse = resp;
      if (resp && resp.token) {
        suggestionResponse.status = "success";
      }
      return suggestionResponse;
    },
    async extendTicketJulie({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.extendTicketJulie(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async secondNoticeTicketJulie({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.secondNoticeTicketJulie(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async secondNoticeTicketCO({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.secondNoticeTicketCO(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async utilityNotFoundTicketCO({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.utilityNotFoundTicketCO(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async cancelTicket({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.cancelTicket(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async commentTicket({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.commentTicket(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async retransmitTicket({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.retransmitTicket(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async relocateRefreshTicket({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.relocateRefreshTicket(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async repeatTicket({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.repeatTicket(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async addMembersTicket({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.addMembersTicket(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async hourNoticeTicket({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.hourNoticeTicket(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async getMembersDetailsInCounty({ state }, payload) {
      let authStatus = {};
      const resp = await api.newtin.getMembersDetailsInCounty(payload);
      authStatus = resp;
      if (resp && resp.token) {
        authStatus.status = "success";
      }
      return authStatus;
    },
    async getGeoCoderDescribe({ state }, body) {
      let suggestionResponse = {};
      const resp = await api.newtin.getGeoCoderDescribe(body);
      suggestionResponse = resp;
      if (resp && resp.token) {
        suggestionResponse.status = "success";
      }
      return suggestionResponse;
    },
    async secondaryFunctionsEligibility({ state }, payload) {
      const resp = await api.newtin.secondaryFunctionsEligibility(payload);
      return resp;
    },
    async secondaryFunctionsAllowed({ state }, payload) {
      const resp = await api.newtin.secondaryFunctionsAllowed(payload);
      return resp;
    },
  },
};
