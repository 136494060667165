var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "pa-0",
      attrs: {
        fullscreen: "",
        persistent: "",
        "max-width": _vm.isCompanyInformation ? "80%" : "990px",
        height: "auto",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            color: _vm.$vuetify.breakpoint.mdAndUp ? "grey lighten-4" : "white",
            elevation: "0",
          },
        },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "py-0 px-0 grey--text text--darken-1 font-weight-medium subtitle-1",
            },
            [
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _vm.step === 2
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mt-2 ml-n6",
                              attrs: {
                                fab: "",
                                text: "",
                                absolute: "",
                                plain: "",
                                left: "",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.step = 1
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("img", {
                            staticClass:
                              "pt-6 py-lg-9 py-md-9 mx-auto pt-sm-9 py-xs-5",
                            attrs: {
                              width: "160",
                              src: require("@/assets/images/logo.png"),
                              alt: "Dig Logix Logo",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { cols: "11" },
                        },
                        [
                          _c("img", {
                            staticClass:
                              "pt-10 py-lg-9 py-md-9 mx-auto pt-sm-9 py-xs-5",
                            attrs: {
                              width: "160",
                              src: require("@/assets/images/logo.png"),
                              alt: "Dig Logix Logo",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "ma-auto mb-16 pt-6",
              attrs: {
                height: "auto",
                width: _vm.$vuetify.breakpoint.mdAndUp ? "80%" : "100%",
                elevation: "0",
              },
            },
            [
              _c(
                "v-row",
                { staticClass: "px-4" },
                [
                  _vm.step === 2 && _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ml-5 mt-2",
                          attrs: { fab: "", text: "", absolute: "", plain: "" },
                          on: {
                            click: function ($event) {
                              _vm.step = 1
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-arrow-left"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "justify-center grey--text text--darken-1 font-weight-medium subtitle-1 my-6",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("completeYourAccountText"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.step === 1
                ? _c("CompanySubscription", {
                    attrs: {
                      subscriptions: _vm.subscriptions,
                      "default-selected-user": _vm.defaultSelectedUser,
                    },
                    on: { updateStep: _vm.updateStep },
                  })
                : _vm._e(),
              _vm.step === 2
                ? _c("CompanyInformation", {
                    attrs: {
                      subscriptions: _vm.subscriptions,
                      "selected-subscription": _vm.selectedSubscription,
                    },
                    on: { submitCompanyDetails: _vm.saveCompanyDetails },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-16" },
            [_c("CompanyFooter", { attrs: { "show-full-width": true } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }