import { checkResponse, getHeader } from "../../utils/utils";

export default {
  async submitTicket(body) {
    const response = await fetch(`${body.url}/tickets`, {
      method: "POST",
      body: JSON.stringify(body.data),
      headers: getHeader("json", true, body.token),
    });
    return await checkResponse(response);
  },
  async createTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/createticket`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async getTickets(filters) {
    // loop through filters and add to url
    let url = `${process.env.VUE_APP_DIGLOGIX_API}/ticket`;

    let filterString = "";
    if (filters) {
      for (const key in filters) {
        if (
          filters[key] !== "" &&
          filters[key] !== null &&
          filters[key] !== undefined
        ) {
          // handle removing ticket revision if added to ticket number
          if (key === "center_ticket_id") {
            let center_ticket_id = filters[key].split("-");
            center_ticket_id = center_ticket_id[0].trim();
            filterString += `${key}=${center_ticket_id}&`;
          } else {
            filterString += `${key}=${filters[key]}&`;
          }
        }
      }
      if (filterString !== "") {
        // remove last &
        filterString = filterString.slice(0, -1);
        url += `?${filterString}`;
      }
    }
    const response = await fetch(url, {
      method: "GET",
      headers: getHeader("form", true),
    });
    return await checkResponse(response);
  },
  async getMyTickets(filters) {
    // loop through filters and add to url
    let url = `${process.env.VUE_APP_DIGLOGIX_API}/ticket/myticket`;
    let filterString = "";
    if (filters) {
      for (const key in filters) {
        if (
          filters[key] !== "" &&
          filters[key] !== null &&
          filters[key] !== undefined
        ) {
          // handle removing ticket revision if added to ticket number
          if (key === "center_ticket_id") {
            let center_ticket_id = filters[key].split("-");
            center_ticket_id = center_ticket_id[0].trim();
            filterString += `${key}=${center_ticket_id}&`;
          } else {
            filterString += `${key}=${filters[key]}&`;
          }
        }
      }
      if (filterString !== "") {
        // remove last &
        filterString = filterString.slice(0, -1);
        url += `?${filterString}`;
      }
    }

    const response = await fetch(url, {
      method: "GET",
      headers: getHeader("form", true),
    });
    return await checkResponse(response);
  },
  async getLegalDate(filters) {
    let url = `${process.env.VUE_APP_DIGLOGIX_API}/ticket/legal-dates`;
    let filterString = "";

    if (filters) {
      for (const key in filters) {
        if (
          filters[key] !== "" &&
          filters[key] !== null &&
          filters[key] !== undefined
        ) {
          filterString += `${key}=${encodeURIComponent(filters[key])}&`;
        }
      }
      if (filterString !== "") {
        filterString = filterString.slice(0, -1);
        url += `?${filterString}`;
      }
    }
    const response = await fetch(url, {
      method: "GET",
      headers: getHeader("form", true),
    });
    return await checkResponse(response);
  },

  async getTicketById(filters) {
    // loop through filters and add to url
    let url = `${process.env.VUE_APP_DIGLOGIX_API}/ticket/tickets/${filters.ticket_id}`;
    const response = await fetch(url, {
      method: "GET",
      headers: getHeader("form", true),
    });
    return await checkResponse(response);
  },
  async getCompanyTickets(filters) {
    // loop through filters and add to url
    let url = `${process.env.VUE_APP_DIGLOGIX_API}/ticket/companyticket`;
    let filterString = "";
    if (filters) {
      for (const key in filters) {
        if (
          filters[key] !== "" &&
          filters[key] !== null &&
          filters[key] !== undefined
        ) {
          // handle removing ticket revision if added to ticket number
          if (key === "center_ticket_id") {
            let center_ticket_id = filters[key].split("-");
            center_ticket_id = center_ticket_id[0].trim();
            filterString += `${key}=${center_ticket_id}&`;
          } else {
            filterString += `${key}=${filters[key]}&`;
          }
        }
      }
      if (filterString !== "") {
        // remove last &
        filterString = filterString.slice(0, -1);
        url += `?${filterString}`;
      }
    }

    const response = await fetch(url, {
      method: "GET",
      headers: getHeader("form", true),
    });
    return await checkResponse(response);
  },
  async scheduleTicket(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body) {
      if (body[key] && typeof body[key] === "object")
        bodyFormData.append(key, JSON.stringify(body[key]));
      else bodyFormData.append(key, body[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/scheduleticket`,
      {
        method: "POST",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async updateTicket(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body) {
      if (body[key] && typeof body[key] === "object")
        bodyFormData.append(key, JSON.stringify(body[key]));
      else bodyFormData.append(key, body[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/${body.ticket_id}`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async updateScheduleTicket(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body.payload) {
      if (body.payload[key] && typeof body.payload[key] === "object")
        bodyFormData.append(key, JSON.stringify(body.payload[key]));
      else bodyFormData.append(key, body.payload[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/scheduleticket/${body.scheduled_ticket_id}`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async getScheduleTicketData() {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/scheduleticket`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async deleteScheduleTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/scheduleticket/${body.scheduled_ticket_id}`,
      {
        method: "DELETE",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async getSuspends() {
    // loop through filters and add to url
    let url = `${process.env.VUE_APP_DIGLOGIX_API}/suspends`;

    const response = await fetch(url, {
      method: "GET",
      headers: getHeader("form", true),
    });
    return await checkResponse(response);
  },
  async submitSuspend(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/suspendticket`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async suspendTicket(body) {
    const response = await fetch(`${process.env.VUE_APP_DIGLOGIX_API}/ticket`, {
      method: "POST",
      body: new URLSearchParams(body.data),
      headers: getHeader("form"),
    });
    return await checkResponse(response);
  },
  async submitRevisionUpdateTicket(body) {
    const response = await fetch(`${body.url}`, {
      method: "POST",
      body: JSON.stringify(body.data),
      headers: getHeader("json", true, body.token),
    });
    return await checkResponse(response);
  },
  async revisionUpdateTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/update/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionDestroyedMarksTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/destroyed-marks/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionRetransmitTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/retransmit/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionDeepNoticeTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/three-hour-deep-notice/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionDiginTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/digin/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionRepeatTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/repeat/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionCorrectionTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/correction/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionRemarkTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/remark/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionAddMembersicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/add-members/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionUtilityNotFoundTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/utility-not-found/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionHourNoticeTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/three-hour-notice/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionExtendTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/extend/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionRelocateRefreshTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/relocate-refresh/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionSecondNoticeTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/second-notice/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionNoResponseTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/no-response/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionCancelTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/cancel/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async revisionCommentTicket(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/revisions/comment/${body.ticket_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
};
