import { checkResponse, getHeader } from "../../utils/utils";

export default {
  async addApproval(body) {
    let bodyFormData = new URLSearchParams();
    for (let obj in body) {
      if (
        typeof body[obj] === "object" &&
        ["options", "comments"].includes(obj)
      )
        bodyFormData.append(obj, JSON.stringify(body[obj]));
      else bodyFormData.append(obj, body[obj]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/approval`,
      {
        method: "POST",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async getAwaitingApprovals() {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/approval`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async getPendingApprovals(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/approval/${body.user_id}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async updateApproval(body) {
    let bodyFormData = new URLSearchParams();
    for (let obj in body) {
      if (
        typeof body[obj] === "object" &&
        ["options", "comments"].includes(obj)
      )
        bodyFormData.append(obj, JSON.stringify(body[obj]));
      else bodyFormData.append(obj, body[obj]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/approval/${body.approval_id}`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
};
