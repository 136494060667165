var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-divider"),
      _c(
        "v-row",
        { staticClass: "ml-1 mr-2 my-2" },
        [
          !_vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                {
                  staticClass: "py-0 mb-6 px-1",
                  attrs: { cols: "12", lg: "3", xl: "3", md: "3", sm: "12" },
                },
                [
                  _c("v-text-field", {
                    staticClass: "rounded-lg",
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      label: "Search by ticket number",
                      "single-line": "",
                      "hide-details": "",
                      flat: "",
                      solo: "",
                      dense: "",
                      "background-color": "grey lighten-4",
                    },
                    on: {
                      change: _vm.searchTicketNumber,
                      keypress: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchTicketNumber.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.searchTicket,
                      callback: function ($$v) {
                        _vm.searchTicket = $$v
                      },
                      expression: "searchTicket",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "py-0 px-1",
              attrs: { cols: "4", sm: "4", lg: "1", xl: "1", md: "1" },
            },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.states,
                  "item-value": "state",
                  "item-text": "state",
                  "menu-props": { bottom: true, offsetY: true },
                  outlined: "",
                  required: "",
                  "hide-details": _vm.$vuetify.breakpoint.mdAndUp
                    ? true
                    : false,
                  dense: "",
                  label: _vm.$t("state"),
                },
                on: { change: _vm.onSelectState },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function (ref) {
                      var item = ref.item
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", {
                                  staticClass: "text-left",
                                  domProps: { textContent: _vm._s(item.state) },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selectedState,
                  callback: function ($$v) {
                    _vm.selectedState = $$v
                  },
                  expression: "selectedState",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0 px-1",
              attrs: { cols: "8", sm: "8", lg: "2", xl: "2", md: "2" },
            },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.viewTickets,
                  "item-value": "code",
                  "item-text": "label",
                  "menu-props": { bottom: true, offsetY: true },
                  outlined: "",
                  required: "",
                  "hide-details": _vm.$vuetify.breakpoint.mdAndUp
                    ? true
                    : false,
                  dense: "",
                  label: _vm.$t("viewTickets"),
                },
                on: { change: _vm.onSelectState },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function (ref) {
                      var item = ref.item
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", {
                                  staticClass: "text-left",
                                  domProps: { textContent: _vm._s(item.label) },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selectedViewTicket,
                  callback: function ($$v) {
                    _vm.selectedViewTicket = $$v
                  },
                  expression: "selectedViewTicket",
                },
              }),
            ],
            1
          ),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                {
                  staticClass: "py-0 mb-2 px-1",
                  attrs: { cols: "12", lg: "3", xl: "3", md: "3", sm: "12" },
                },
                [
                  _c("v-text-field", {
                    staticClass: "rounded-lg",
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      label: "Search by ticket number",
                      "single-line": "",
                      "hide-details": "",
                      flat: "",
                      solo: "",
                      dense: "",
                      "background-color": "grey lighten-4",
                    },
                    on: {
                      change: _vm.searchTicketNumber,
                      keypress: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchTicketNumber.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.searchTicket,
                      callback: function ($$v) {
                        _vm.searchTicket = $$v
                      },
                      expression: "searchTicket",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", xl: "7", lg: "7", md: "7", sm: "12" } },
            [
              _c("div", {
                ref: "map-root",
                staticClass: "mb-2",
                staticStyle: {
                  height: "75vh",
                  border: "1px solid #ccc",
                  background: "white",
                },
                style: {
                  height: this.$vuetify.breakpoint.xs ? "65vh" : "75vh",
                },
                attrs: { id: "map" },
              }),
            ]
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", xl: "5", lg: "5", md: "5", sm: "12" } },
            [
              _c("v-data-table", {
                class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-2" : null,
                attrs: {
                  id: "main-tickets-table",
                  "fixed-header": "",
                  headers: _vm.headers,
                  height: "calc(100vh - 270px)",
                  "disable-sort": "",
                  items: _vm.ticketMapTickets,
                  "item-key": "ticket_id",
                  "mobile-breakpoint": "950",
                  color: "primary",
                  "checkbox-color": "primary",
                  "items-per-page": !_vm.$vuetify.breakpoint.mdAndUp ? -1 : 15,
                  "hide-default-footer": !_vm.$vuetify.breakpoint.mdAndUp,
                  "loading-text": _vm.$t("loading"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.start_date",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("div", { staticClass: "nowrap" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.start_date || item.started
                                    ? _vm
                                        .moment(
                                          _vm.selectedViewTicket ===
                                            "nearTicket"
                                            ? item.started
                                            : item.start_date
                                        )
                                        .format("MM/DD/YYYY hh:mm A")
                                    : item.start_date
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.center_ticket_id",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "nowrap" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary--text",
                                  attrs: {
                                    elevation: "0",
                                    block: "",
                                    color: "white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectTicketNumber(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedViewTicket === "nearTicket"
                                          ? item.ticket
                                          : item.center_ticket_id
                                      ) +
                                      "-" +
                                      _vm._s(item.revision)
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.street_address",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedViewTicket === "nearTicket"
                                  ? item.st_from_address + " " + item.street
                                  : item.street_address
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    !_vm.$vuetify.breakpoint.mdAndUp
                      ? {
                          key: "body",
                          fn: function (ref) {
                            var items = ref.items
                            return [
                              _c(
                                "tbody",
                                _vm._l(items, function (item) {
                                  return _c(
                                    "tr",
                                    {
                                      key: item.scheduled_ticket_id,
                                      staticClass: "pr-2 pb-4",
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          attrs: {
                                            height: "150",
                                            elevation: "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "ml-1",
                                                  attrs: { cols: "9" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-subtitle-1 font-weight-bold",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "primary--text",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectTicketNumber(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.selectedViewTicket ===
                                                                  "nearTicket"
                                                                  ? item.ticket
                                                                  : item.center_ticket_id
                                                              ) +
                                                              "-" +
                                                              _vm._s(
                                                                item.revision
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("v-spacer"),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "ml-1" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("startDate")
                                                    ) + ":"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 text-body-2 text--darken-2 font-weight-medium",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.start_date ||
                                                          item.started
                                                          ? _vm
                                                              .moment(
                                                                _vm.selectedViewTicket ===
                                                                  "nearTicket"
                                                                  ? item.started
                                                                  : item.start_date
                                                              )
                                                              .format(
                                                                "MM/DD/YYYY hh:mm A"
                                                              )
                                                          : item.start_date
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "ml-1" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("priority")) +
                                                      ":"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 text-body-2 text--darken-2 font-weight-medium",
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(item.priority)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "ml-1" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("type")) + ":"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 text-body-2 text--darken-2 font-weight-medium",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.ticket_type
                                                          ? item.ticket_type
                                                          : item.type
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "ml-1" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("address")) +
                                                      ":"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 text-body-2 text--darken-2 font-weight-medium",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.selectedViewTicket ===
                                                          "nearTicket"
                                                          ? item.st_from_address +
                                                              " " +
                                                              item.street
                                                          : item.street_address
                                                      ) +
                                                      ", " +
                                                      _vm._s(item.place) +
                                                      ", " +
                                                      _vm._s(item.county) +
                                                      ", " +
                                                      _vm._s(item.state)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ]
                          },
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "div",
            { staticClass: "ol-popup-view", attrs: { id: "popup" } },
            [
              _c(
                "v-row",
                { staticClass: "ma-0", attrs: { "no-gutters": "" } },
                [
                  _vm.selectedViewTicket !== "nearTicket"
                    ? _c(
                        "a",
                        {
                          staticClass: "col-10",
                          staticStyle: {
                            "text-decoration": "none",
                            color: "#1976d2",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit(
                                "onTicketDetails",
                                _vm.ticketsFound[0]
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.ticketsFound[0]
                                  ? this.selectedViewTicket === "nearTicket"
                                    ? _vm.ticketsFound[0].ticket
                                    : _vm.ticketsFound[0].center_ticket_id
                                  : ""
                              ) +
                              "-" +
                              _vm._s(
                                _vm.ticketsFound[0]
                                  ? _vm.ticketsFound[0].revision
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "col-10",
                          staticStyle: {
                            "text-decoration": "none",
                            color: "#1976d2",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.ticketsFound[0]
                                  ? this.selectedViewTicket === "nearTicket"
                                    ? _vm.ticketsFound[0].ticket
                                    : _vm.ticketsFound[0].center_ticket_id
                                  : ""
                              ) +
                              "-" +
                              _vm._s(
                                _vm.ticketsFound[0]
                                  ? _vm.ticketsFound[0].revision
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      ),
                  _c(
                    "a",
                    { attrs: { id: "popup-closer" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "col-2 ml-3",
                          attrs: { "aria-label": "cross", icon: "" },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { attrs: { id: "popup-content" } }),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-body-2", attrs: { cols: "5" } },
                    [_vm._v(" Start Date: ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 font-weight-medium",
                      attrs: { cols: "7" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ticketsFound[0]
                              ? _vm
                                  .moment(_vm.ticketsFound[0].start_date)
                                  .format("MM/DD/YYYY hh:mm A")
                              : ""
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-body-2", attrs: { cols: "5" } },
                    [_vm._v(" Priority: ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 font-weight-medium",
                      attrs: { cols: "7" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ticketsFound[0]
                              ? _vm.ticketsFound[0].priority
                              : ""
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-body-2", attrs: { cols: "5" } },
                    [_vm._v(" Type: ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 font-weight-medium",
                      attrs: { cols: "7" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ticketsFound[0] ? _vm.ticketsFound[0].type : ""
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-body-2", attrs: { cols: "5" } },
                    [_vm._v(" Expiration Date: ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 font-weight-medium",
                      attrs: { cols: "7" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ticketsFound[0]
                              ? _vm
                                  .moment(_vm.ticketsFound[0].expiration_date)
                                  .format("MM/DD/YYYY hh:mm A")
                              : ""
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-body-2", attrs: { cols: "5" } },
                    [_vm._v(" User: ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 font-weight-medium",
                      attrs: { cols: "7" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ticketsFound[0]
                              ? this.selectedViewTicket === "nearTicket"
                                ? _vm.ticketsFound[0].caller
                                : _vm.companyUserMap[
                                    _vm.ticketsFound[0].user_id
                                  ].name
                              : ""
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-body-2", attrs: { cols: "5" } },
                    [_vm._v(" Phone Number: ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 font-weight-medium",
                      attrs: { cols: "7" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ticketsFound[0] ? _vm.ticketsFound[0].phone : ""
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-body-2", attrs: { cols: "5" } },
                    [_vm._v(" Center: ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 font-weight-medium",
                      attrs: { cols: "7" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ticketsFound[0]
                              ? _vm.centerMap[_vm.ticketsFound[0].center_id]
                                  .center_name
                              : ""
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-body-2", attrs: { cols: "5" } },
                    [_vm._v(" Street Address: ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 font-weight-medium",
                      attrs: { cols: "7" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ticketsFound[0]
                              ? this.selectedViewTicket === "nearTicket"
                                ? _vm.ticketsFound[0].st_from_address +
                                  " " +
                                  _vm.ticketsFound[0].street
                                : _vm.ticketsFound[0].street_address
                              : ""
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-body-2", attrs: { cols: "5" } },
                    [_vm._v(" Place: ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 font-weight-medium",
                      attrs: { cols: "7" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ticketsFound[0] ? _vm.ticketsFound[0].place : ""
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-body-2", attrs: { cols: "5" } },
                    [_vm._v(" County: ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 font-weight-medium",
                      attrs: { cols: "7" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ticketsFound[0]
                              ? _vm.ticketsFound[0].county
                              : ""
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-body-2", attrs: { cols: "5" } },
                    [_vm._v(" State: ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 font-weight-medium",
                      attrs: { cols: "7" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ticketsFound[0] ? _vm.ticketsFound[0].state : ""
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-dialog",
            {
              staticClass: "mx-0",
              attrs: {
                "hide-overlay": "",
                "content-class": "bottom-dialog-hey mx-0 mb-0",
                "full-width": "",
                transition: "dialog-bottom-transition",
                width: "100%",
                "max-height": "200",
                persistent: "",
              },
              model: {
                value: _vm.dialogOverlay,
                callback: function ($$v) {
                  _vm.dialogOverlay = $$v
                },
                expression: "dialogOverlay",
              },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "px-4 pt-4",
                  attrs: { width: "100%", height: "100%" },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pl-0", attrs: { cols: "9" } },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "col-10 pl-0",
                                  staticStyle: {
                                    "text-decoration": "none",
                                    color: "#1976d2",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "onTicketDetails",
                                        _vm.ticketsFound[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ticketsFound[0]
                                          ? this.selectedViewTicket ===
                                            "nearTicket"
                                            ? _vm.ticketsFound[0].ticket
                                            : _vm.ticketsFound[0]
                                                .center_ticket_id
                                          : ""
                                      ) +
                                      "-" +
                                      _vm._s(
                                        _vm.ticketsFound[0]
                                          ? _vm.ticketsFound[0].revision
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "col-2 ml-6",
                              attrs: {
                                "aria-label": "cross",
                                left: "",
                                icon: "",
                              },
                              on: { click: _vm.closeDialog },
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2",
                              attrs: { cols: "5" },
                            },
                            [_vm._v(" Start Date: ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2 font-weight-medium",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ticketsFound[0]
                                      ? this.moment(
                                          _vm.ticketsFound[0].start_date
                                        ).format("MM/DD/YYYY hh:mm A")
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2",
                              attrs: { cols: "5" },
                            },
                            [_vm._v(" Priority: ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2 font-weight-medium",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ticketsFound[0]
                                      ? _vm.ticketsFound[0].priority
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2",
                              attrs: { cols: "5" },
                            },
                            [_vm._v(" Type: ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2 font-weight-medium",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ticketsFound[0]
                                      ? _vm.ticketsFound[0].type
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2",
                              attrs: { cols: "5" },
                            },
                            [_vm._v(" Expiration Date: ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2 font-weight-medium",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ticketsFound[0]
                                      ? this.moment(
                                          _vm.ticketsFound[0].expiration_date
                                        ).format("MM/DD/YYYY hh:mm A")
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2",
                              attrs: { cols: "5" },
                            },
                            [_vm._v(" User: ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2 font-weight-medium",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ticketsFound[0]
                                      ? this.selectedViewTicket === "nearTicket"
                                        ? _vm.ticketsFound[0].caller
                                        : _vm.companyUserMap[
                                            _vm.ticketsFound[0].user_id
                                          ].name
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2",
                              attrs: { cols: "5" },
                            },
                            [_vm._v(" Phone Number: ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2 font-weight-medium",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ticketsFound[0]
                                      ? _vm.ticketsFound[0].phone
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2",
                              attrs: { cols: "5" },
                            },
                            [_vm._v(" Center: ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2 font-weight-medium",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ticketsFound[0]
                                      ? _vm.centerMap[
                                          _vm.ticketsFound[0].center_id
                                        ].center_name
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2",
                              attrs: { cols: "5" },
                            },
                            [_vm._v(" Street Address: ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2 font-weight-medium",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ticketsFound[0]
                                      ? this.selectedViewTicket === "nearTicket"
                                        ? _vm.ticketsFound[0].st_from_address +
                                          " " +
                                          _vm.ticketsFound[0].street
                                        : _vm.ticketsFound[0].street_address
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2",
                              attrs: { cols: "5" },
                            },
                            [_vm._v(" Place: ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2 font-weight-medium",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ticketsFound[0]
                                      ? _vm.ticketsFound[0].place
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2",
                              attrs: { cols: "5" },
                            },
                            [_vm._v(" County: ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2 font-weight-medium",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ticketsFound[0]
                                      ? _vm.ticketsFound[0].county
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2",
                              attrs: { cols: "5" },
                            },
                            [_vm._v(" State: ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-body-2 font-weight-medium",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ticketsFound[0]
                                      ? _vm.ticketsFound[0].state
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }