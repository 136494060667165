import api from "../api";
const getDefaultState = () => {
  return {
    subscriptionMap: {},
    subscriptions: [],
    contactusData: {},
    subscriptionProducts: {},
  };
};
export default {
  state: getDefaultState(),
  mutations: {
    resetState(state) {
      state = Object.assign(state, getDefaultState());
    },
    setSubscriptionMap(state, subscriptionMapData) {
      state.subscriptionMap = Object.assign({}, subscriptionMapData);
    },
    setSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    updateContactUsData(state, details) {
      state.contactusData = { ...state.contactusData, ...details };
    },
    setSubscriptionProduct(state, subscriptionProducts) {
      state.subscriptionProducts = subscriptionProducts;
    },
  },
  actions: {
    async getsubscriptions({ state }) {
      const resp = await api.subscription.getsubscription();
      return resp;
    },
    setContactUsData({ commit }, details) {
      commit("updateContactUsData", details);
    },
    async getSubscriptionProductsData({ state }) {
      const resp = await api.subscription.getsubscriptionWithProducts();
      return resp;
    },
    async getStripeSubscription({ state }, payload) {
      const resp = await api.subscription.getStripeSubscription(payload);
      return resp;
    },
    async retrieveInvoice({ commit }, payload) {
      const resp = await api.subscription.retrieveInvoice(payload);
      return resp;
    },
  },
};
