import api from "../api";
import { getAuthDetails } from "../utils/utils";
import rolesAndPermissionsDefaults from "@/static/rolesAndPermissionsDefaults.json";

const getDefaultState = () => {
  return {
    company: {},
    allCompanies: [],
    allowedPermissions: {},
  };
};
const getPermissions = (vueScope) => {
  const role = vueScope.$store.state.User.user.persona;
  const tier = vueScope.$store.state.Company.company.tier_count;
  if (role === "sysop") {
    return JSON.parse(
      JSON.stringify(rolesAndPermissionsDefaults.otherPlanPermssions)
    );
  } else if (parseInt(tier) === 0) {
    return JSON.parse(
      JSON.stringify(rolesAndPermissionsDefaults.freePlanPermissions)
    );
  } else if (parseInt(tier) === 1) {
    return JSON.parse(
      JSON.stringify(rolesAndPermissionsDefaults.starterPlanPermissions)
    );
  } else if (parseInt(tier) === 2) {
    return JSON.parse(
      JSON.stringify(rolesAndPermissionsDefaults.proPlanPermissions)
    );
  } else {
    return JSON.parse(
      JSON.stringify(rolesAndPermissionsDefaults.customPlanPermissions)
    );
  }
};
export default {
  state: getDefaultState(),

  mutations: {
    setCompanyData(state, companyData) {
      state.company = Object.assign({}, state.company, companyData);
    },
    resetState(state) {
      state = Object.assign(state, getDefaultState());
    },
    setAllCompanies(state, allCompanyData) {
      state.allCompanies = allCompanyData;
    },
    setAllowedPermissions(state, permissionMap) {
      state.allowedPermissions = permissionMap;
    },
  },

  actions: {
    async createCompany({ commit }, payload) {
      const authDetails = getAuthDetails();
      payload.token = authDetails.token;
      const resp = await api.company.createCompany(payload);
      return resp;
    },
    async getCompanyDataById({ state }, payload) {
      const payloadData = payload;
      const resp = await api.company.getCompanyDataById(payloadData);
      return resp;
    },
    async updateCompany({ commit }, payload) {
      const authDetails = getAuthDetails();
      payload.token = authDetails.token;
      const resp = await api.company.updateCompanyDetails(payload);
      return resp;
    },
    async updateSubscriptionCompany({ commit }, payload) {
      const resp = await api.company.updateCompany(payload);
      return resp;
    },
    async updateCompanySubscription({ commit }, payload) {
      const resp = await api.company.updateCompanySubscription(payload);
      return resp;
    },
    async sendQuote({ commit }, payload) {
      const resp = await api.company.sendQuote(payload);
      return resp;
    },
    async getCompany({ state }) {
      const resp = await api.company.getCompany();
      return resp;
    },
    async getPurchaseHistoryById({ state }, payload) {
      const resp = await api.company.getPurchaseHistoryById(payload);
      return resp;
    },
    async getPurchaseHistory({ state }) {
      const resp = await api.company.getPurchaseHistory();
      return resp;
    },
    async addPurchaseHistory({ state }, payload) {
      const resp = await api.company.UpdateSubscription(payload);
      return resp;
    },
  },
  getters: {
    // Convert company options to a treeView object
    rolesAndPermissionsTree: (state) => (vueScope) => {
      const options =
        (state.company.options &&
          JSON.parse(
            JSON.stringify(state.company.options.rolesAndPermissions)
          )) ||
        {};
      const companyRolesAndPermissions = getPermissions(vueScope);
      const rolesAndPermissionsObj = {};
      const roles = Object.keys(options) || [];
      roles.forEach((key) => {
        rolesAndPermissionsObj[key] = [];
        const groups = companyRolesAndPermissions[key]
          ? [...companyRolesAndPermissions[key]]
          : JSON.parse(JSON.stringify([...companyRolesAndPermissions.custom]));
        groups.forEach((group) => {
          let counter = 0;
          group.id = ++counter;
          let totalItems = 0;
          if (group.items && group.items.length) {
            const selectedIds = [];
            group.items.forEach((item) => {
              item.id = ++counter;
              item.key = item.name;
              item.name = vueScope.$t(item.name);
              if (item.permissions) {
                //totalItems = totalItems + item.permissions.length;
                item.children = item.permissions.map((permission) => {
                  totalItems++;
                  permission.id = ++counter;
                  permission.key = permission.name;
                  permission.name = vueScope.$t(permission.name);
                  if (options[key].includes(permission.key)) {
                    selectedIds.push(permission.id);
                  }
                  return permission;
                });
              }
            });
            group.activeNodes = selectedIds;
            group.totalItems = totalItems;
            if (totalItems) rolesAndPermissionsObj[key].push(group);
          } else if (group.permissions && group.permissions.length) {
            const selectedIds = [];
            group.items = group.permissions.map((permission) => {
              totalItems++;
              permission.id = ++counter;
              permission.key = permission.name;
              permission.name = vueScope.$t(permission.name);
              if (options[key].includes(permission.key)) {
                selectedIds.push(permission.id);
              }
              return permission;
            });
            group.activeNodes = selectedIds;
            group.totalItems = totalItems;
            if (totalItems) rolesAndPermissionsObj[key].push(group);
          }
        });
      });
      return rolesAndPermissionsObj;
    },
    rolesAndPermissionsMap: (state) => {
      const rolesAndPermissions =
        (state.company.options &&
          Object.assign({}, state.company.options.rolesAndPermissions)) ||
        {};
      const roles = Object.keys(rolesAndPermissions) || [];

      return roles.reduce((rolesAndPermissionsObj, role) => {
        const allowedPermissions = rolesAndPermissions[role].reduce(
          (permissions, group) => {
            if (group.items && group.items.length) {
              group.items.forEach((item) => {
                if (item.permissions) {
                  item.children = item.permissions.forEach((permission) => {
                    permission.key = permission.name;
                    if (permission.allowed) permissions.push(permission.name);
                  });
                }
              });
            } else if (group.permissions && group.permissions.length) {
              group.permissions.forEach((permission) => {
                if (permission.allowed) permissions.push(permission.name);
              });
            }
            return permissions;
          },
          []
        );
        return {
          ...rolesAndPermissionsObj,
          [role]: allowedPermissions,
        };
      }, {});
    },
  },
};
