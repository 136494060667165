import api from "../api";
import { getAuthDetails } from "../utils/utils";

const getDefaultState = () => {
  return {
    projectTask: [],
  };
};

export default {
  state: getDefaultState(),

  mutations: {
    setProjectTask(state, task) {
      state.projectTask = Object.assign([], state.projectTask, task);
    },
    resetState(state) {
      state = Object.assign(state, getDefaultState());
    },
    setAllProjectTask(state, allProjectTaskData) {
      state.allProjectTask = allProjectTaskData;
    },
  },

  actions: {
    async createProjectTask({ commit }, payload) {
      const resp = await api.projectTask.createProjectTask(payload);
      return resp;
    },
    async reorderTask({ commit }, payload) {
      const resp = await api.projectTask.reorderTask(payload);
      return resp;
    },
    async reodertaskrow({ commit }, payload) {
      const resp = await api.projectTask.reodertaskrow(payload);
      return resp;
    },
    async getProjectTaskById({ state }, payload) {
      const payloadData = payload;
      const resp = await api.projectTask.getProjectAllTasks(payloadData);
      return resp;
    },
    async updateProjectTask({ commit }, payload) {
      const authDetails = getAuthDetails();
      payload.token = authDetails.token;
      const resp = await api.projectTask.updateProjectTask(payload);
      return resp;
    },
    async updateProjectTickets({ commit }, payload) {
      const authDetails = getAuthDetails();
      payload.token = authDetails.token;
      const resp = await api.projectTask.updateProjectTickets(payload);
      return resp;
    },
    async getProjectAllTasks({ state }) {
      const resp = await api.projectTask.getProjectAllTasks();
      return resp;
    },
  },
  getters: {},
};
