var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { staticClass: "pr-0 pt-0 pb-0", attrs: { absolute: "", color: "white" } },
    [
      !_vm.isMini
        ? _c(
            "v-col",
            { staticClass: "ma-auto mb-0 pb-0", attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "mb-0 caption", attrs: { justify: "center" } },
                [_c("div", [_vm._v("Version " + _vm._s(_vm.appVersion))])]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("v-divider", { attrs: { vertical: "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }