import { bus } from "@/main";
import moment from "moment";
import { utils, writeFile } from "xlsx";
import ERRORS from "../../static/errors.json";
const contentTypes = {
  json: "application/json",
  form: "application/x-www-form-urlencoded",
};
export async function checkResponse(response, skipParsing) {
  if (response.ok) {
    return response.json();
  } else {
    const errorObj = await response.text();
    const error = skipParsing ? { error: errorObj } : JSON.parse(errorObj);
    if (
      error.error === ERRORS.AUTHORIZATION_REQUIRED ||
      error.error === ERRORS.JWT_EXPIRED ||
      error.error === ERRORS.INVALID_TOKEN
    ) {
      setAuthStatus(false);
    }
    return Object.assign(
      { status: "error", errorCode: response.status },
      skipParsing ? { error: errorObj } : JSON.parse(errorObj),
    );
  }
}
export function checkResponseOk(response) {
  if (!response.ok) {
    throw new Error(`Request failed with response code ${response.status}`);
  }
}
export function getAuthDetails() {
  return JSON.parse(localStorage.getItem("authDetails")) || {};
}
export function getNewtinDetails() {
  return JSON.parse(localStorage.getItem("newtinDetails")) || {};
}
export function getHeader(contentType, setAuth, authToken) {
  const headers = new Headers();
  if (contentType) {
    headers.set("Content-Type", contentTypes[contentType]);
  }

  if (setAuth) {
    const authDetails = getAuthDetails();
    const token = authToken || authDetails.token;
    headers.set("Authorization", token);
  }
  return headers;
}
export function setAuthDetails(data) {
  const authDetails = {
    email: data.email,
    userId: data.userId || data.id,
    token: data.token,
  };
  localStorage.setItem("authDetails", JSON.stringify(authDetails));
  if (data.changeAuthStatus === false) {
    return;
  }
  setAuthStatus(true);
}
export function setNewtinDetails(data) {
  const newtinDetails = getNewtinDetails();
  newtinDetails[data.center_name] = data.token;
  localStorage.setItem("newtinDetails", JSON.stringify(newtinDetails));
}
export function removeAuthDetails() {
  localStorage.clear();
  sessionStorage.clear();
}
export function setAuthFromParams(urlParams) {
  let authDetails = getAuthDetails();
  for (const [key, value] of urlParams) {
    if (key === "userId") {
      authDetails.userId = value;
    }
    if (key === "token") {
      authDetails.token = value;
    }
  }
  localStorage.setItem("authDetails", JSON.stringify(authDetails));
}

export function getKeys(object) {
  const keys = [];
  for (const key of object.keys()) {
    keys.push(key);
  }
  return keys;
}

export function getAuthStatus() {
  return JSON.parse(localStorage.getItem("isAuthorized"));
}

export function setAuthStatus(status) {
  bus.$emit("loginStateChange", status);
  localStorage.setItem("isAuthorized", status);
}

export function getSubscription() {
  return JSON.parse(localStorage.getItem("subscription"));
}

export function setSubscription(data) {
  localStorage.setItem("subscription", JSON.stringify(data));
}

export function exportDataToFile(jsonData, fileName, extension = "xlsx") {
  const workSheet = utils.json_to_sheet(jsonData);
  const workBook = utils.book_new();
  utils.book_append_sheet(workBook, workSheet, "Data");
  writeFile(workBook, `${fileName}.${extension}`);
}
export function formatTicketDateTime(dateString) {
  let date = moment(new Date(dateString));
  if (date.isValid()) {
    return moment(date).format("MM/DD/YYYY hh:mm A");
  }
  return dateString;
}
export function setPermissions(permissions) {
  localStorage.setItem("permissions", JSON.stringify(permissions));
}
