<template v-slot:activator="{ on, attrs }">
  <v-navigation-drawer
    ref="navDrawer"
    app
    :value="drawer"
    :mini-variant="isMini"
    :permanent="!isMobile"
    :style="{ overflow: 'visible' }"
    @input="onInput"
  >
    <div class="my-5">
      <v-img
        v-if="!isMini"
        width="132"
        height="26"
        class="mx-auto"
        src="@/assets/images/logo.png"
      ></v-img>
      <v-img
        v-if="isMini"
        class="ms-3 mt-6"
        height="25"
        width="25"
        src="@/assets/images/logoCropped.png"
      ></v-img>
    </div>

    <v-app-bar-nav-icon
      v-if="isMobile"
      class="white grey--text"
      role="button"
      aria-label="nav"
      absolute
      fab
      right
      :style="{
        top: '5%',
        transform: 'translate(100%, -50%)',
        borderRadius: '20%',
      }"
      @click="toggleDrawer()"
      ><v-icon> mdi-menu-open </v-icon>
    </v-app-bar-nav-icon>
    <OptionsList />
    <ThemeToggleBar :is-mini="isMini" />
  </v-navigation-drawer>
</template>

<script>
import OptionsList from "./OptionsList";
import ThemeToggleBar from "./ThemeToggleBar.vue";

export default {
  name: "NavDrawer",
  components: {
    OptionsList,
    ThemeToggleBar,
  },
  data: () => ({
    mini: false,
  }),
  computed: {
    drawer: {
      get() {
        return this.$store.state.App.drawer;
      },
      set(val) {
        this.$store.commit("SET_NAVDRAWER", val);
      },
    },
    open() {
      return this.$refs.navDrawer.isActive;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isMini() {
      return !this.isMobile && this.$store.state.App.drawer;
    },
  },
  methods: {
    onInput() {
      if (this.isMobile && this.drawer !== this.open) {
        this.$store.commit("SET_NAVDRAWER", !this.drawer);
      }
    },
    toggleDrawer() {
      this.$store.commit("SET_NAVDRAWER", !this.drawer);
    },
  },
};
</script>
<style scoped></style>
