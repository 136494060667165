class quickMap {
  constructor(options) {
    this.ol = options.ol;
    this.turf = options.turf;
    this.target = options.target ?? "map";
    this.grabFocus = options.grabFocus ?? true;
    this.compass = options.compass;
    this.compassDegrees = 0;
    this.enableRotation = options.enableRotation ?? true;
    this.enableCompass = options.enableCompass ?? true;
    this.compassOn = false;
    this.logo = options.logo;
    this.newtinHost = options.newtinHost;
    this.startLongLat = options.startLongLat ?? [0, 0];
    this.startZoom = options.startZoom ?? 0;
    this.maxZoom = options.maxZoom ?? 20;
    this.geolocationZoomLevel = options.geolocationZoomLevel ?? 16;
    this.states = this.getFeatureData(options.extraData.states);
    this.counties = this.getFeatureData(options.extraData.counties);
    this.county = options.county ?? undefined;
    this.restrictedExtent = options.restrictedExtent ?? [
      -124.848974, 24.396308, -66.885444, 49.384358,
    ];
    this.declutter = options.declutter ?? false;
    this.blink = options.blink ?? true;
    this.blinkInterval = options.blinkInterval ?? 500;
    this.autoFocus = options.autoFocus ?? true;
    this.autoFocusWheel = options.autoFocusWheel ?? true;
    this.editColor = options.editColor ?? [0, 0, 0];
    this.drawType = options.drawType ?? "Polygon";
    this.drawBuffer = options.drawBuffer ?? 0;
    this.drawText = options.drawText ?? "";
    this.drawCursor = options.drawCursor ?? "pointer";
    this.measureLineDash = options.measureLineDash ?? [5, 5];
    this.maxPolygonSizeSqMeters = options.maxPolygonSizeSqMeters ?? 999999999;
    this.minPolygonSizeSqMeters = options.minPolygonSizeSqMeters ?? 0;
    this.merge = options.merge ?? false;
    this.complex = options.complex ?? false;
    this.holes = options.holes ?? false;
    this.multi = options.multi ?? false;
    this.cut = false;
    this.msgboxTimeout = options.msgboxTimeout ?? 1500;
    this.mapHeightVh = options.mapHeightVh ?? undefined;
    this.mapWidthVh = options.mapWidthVh ?? undefined;
    this.padding = options.padding ?? [50, 50, 50, 50];
    this.autoHide = options.autoHide ?? false;
    this.autoHideDelay = options.autoHideDelay ?? 500;
    this.autoHideTimeout = null;
    this.hideControls = options.hideControls ?? false;
    this.additionalHelp = options.additionalHelp ?? "";
    this.measureStylePointFont =
      options.measureStylePointFont ?? "bold 18px Calibri,sans-serif";
    this.measureStyleLineFont =
      options.measureStyleLineFont ?? "bold 18px Calibri,sans-serif";
    this.measureStylePolygonFont =
      options.measureStylePolygonFont ?? "bold 18px Calibri,sans-serif";
    this.measureStyleFillWidth = options.measureStyleFillWidth ?? 3;
    this.measureDirection = options.measureDirection ?? true;
    this.measureLengthFeet = options.measureLengthFeet ?? true;
    this.measureLengthYards = options.measureLengthYards ?? true;
    this.measureLengthMiles = options.measureLengthMiles ?? true;
    this.measureLengthMeters = options.measureLengthMeters ?? true;
    this.measureLengthKilometers = options.measureLengthKilometers ?? true;
    this.measureAreaSqFeet = options.measureAreaSqFeet ?? true;
    this.measureAreaSqYards = options.measureAreaSqYards ?? true;
    this.measureAreaAcres = options.measureAreaAcres ?? true;
    this.measureAreaSqMiles = options.measureAreaSqMiles ?? true;
    this.measureAreaSqMeters = options.measureAreaSqMeters ?? true;
    this.measureAreaSqKilometers = options.measureAreaSqKilometers ?? true;
    this.handleDrawDelete = options.handleDrawDelete ?? true;
    this.handleAddDrawPoint = options.handleAddDrawPoint ?? true;
    this.handleAddDrawFinish = options.handleAddDrawFinish ?? true;
    this.handleAddLatLongPoint = options.handleAddLatLongPoint ?? true;

    this.defaultStyles = {
      Point: new this.ol.style.Style({
        text: new this.ol.style.Text({
          text: "",
          font: "bold 12px Calibri,sans-serif",
          stroke: new this.ol.style.Stroke({
            color: [255, 255, 255, 1],
            width: 3,
          }),
          fill: new this.ol.style.Fill({ color: [0, 0, 0, 1] }),
          offsetY: -15,
        }),
        image: new this.ol.style.Circle({
          stroke: new this.ol.style.Stroke({ color: [0, 0, 0, 1], width: 2 }),
          fill: new this.ol.style.Fill({ color: [255, 255, 255, 1] }),
          radius: 5,
        }),
      }),
      LineString: new this.ol.style.Style({
        text: new this.ol.style.Text({
          text: "",
          font: "bold 12px Calibri,sans-serif",
          stroke: new this.ol.style.Stroke({
            color: [255, 255, 255, 1],
            width: 3,
          }),
          fill: new this.ol.style.Fill({ color: [0, 0, 0, 1] }),
          offsetY: -15,
        }),
        stroke: new this.ol.style.Stroke({ color: [0, 0, 0, 1], width: 2 }),
      }),
      MultiLineString: new this.ol.style.Style({
        text: new this.ol.style.Text({
          text: "",
          font: "bold 12px Calibri,sans-serif",
          stroke: new this.ol.style.Stroke({
            color: [255, 255, 255, 1],
            width: 3,
          }),
          fill: new this.ol.style.Fill({ color: [0, 0, 0, 1] }),
          offsetY: -15,
        }),
        stroke: new this.ol.style.Stroke({ color: [0, 0, 0, 1], width: 2 }),
      }),
      Polygon: new this.ol.style.Style({
        text: new this.ol.style.Text({
          text: "",
          font: "bold 12px Calibri,sans-serif",
          stroke: new this.ol.style.Stroke({
            color: [255, 255, 255, 1],
            width: 3,
          }),
          fill: new this.ol.style.Fill({ color: [0, 0, 0, 1] }),
          offsetY: -15,
        }),
        stroke: new this.ol.style.Stroke({ color: [0, 0, 0, 1], width: 2 }),
        fill: new this.ol.style.Fill({ color: [0, 0, 0, 0.2] }),
      }),
      MultiPolygon: new this.ol.style.Style({
        text: new this.ol.style.Text({
          text: "",
          font: "bold 12px Calibri,sans-serif",
          stroke: new this.ol.style.Stroke({
            color: [255, 255, 255, 1],
            width: 3,
          }),
          fill: new this.ol.style.Fill({ color: [0, 0, 0, 1] }),
          offsetY: -15,
        }),
        stroke: new this.ol.style.Stroke({ color: [0, 0, 0, 1], width: 2 }),
        fill: new this.ol.style.Fill({ color: [0, 0, 0, 0.2] }),
      }),
    };

    if (this.mapHeightVh) {
      document.getElementById(this.target).style.height =
        this.mapHeightVh + "vh";
    }
    if (this.mapWidthVh) {
      document.getElementById(this.target).style.width = this.mapWidthVh + "vw";
    }

    this.layers = this.setupLayers();
    this.map = new this.ol.Map({
      target: this.target,
      layers: this.layers,
      controls: this.ol.control.defaults({ attribution: false }),
      view: new this.ol.View({
        center: this.ol.proj.fromLonLat(this.startLongLat),
        zoom: this.startZoom,
        maxZoom: this.maxZoom,
        enableRotation: this.enableRotation,
        extent: this.ol.proj.transformExtent(
          this.restrictedExtent,
          "EPSG:4326",
          "EPSG:3857",
        ),
        constrainOnlyCenter: true,
      }),
    });

    this.setupControls();
    this.buttonsGroupActive = true;
    this.btnToggleControls.title =
      options.btnToggleControlsTitle ?? "Toggle controls";
    this.btnToggleFullscreen.title =
      options.btnToggleFullscreenTitle ?? "Toggle fullscreen";
    this.btnZoomAll.title = options.btnZoomAllTitle ?? "Zoom to all";
    this.btnDraw.title = options.btnDrawTitle ?? "Draw";
    this.btnDrawPoint.title = options.btnDrawPointTitle ?? "Draw point";
    this.btnDrawLine.title = options.btnDrawLineTitle ?? "Draw line";
    this.btnDrawPolygon.title = options.btnDrawPolygonTitle ?? "Draw polygon";
    this.btnBlock.title = options.btnBlockTitle ?? "Block";
    this.btnEdit.title = options.btnEditTitle ?? "Edit";
    this.btnDelete.title = options.btnDeleteTitle ?? "Delete";
    this.btnMeasure.title = options.btnMeasureTitle ?? "Measure";
    this.btnBullseye.title =
      options.btnBullseyeTitle ?? "Bullseye (100ft & 1/4 mi)";
    this.btnIntersection.title =
      options.btnIntersectionTitle ?? "Intersections";
    this.btnFlag.title = options.btnFlagTitle ?? "Flag";
    this.btnIdentify.title = options.btnIndentifyTitle ?? "Identify";
    this.btnWeather.title = options.btnWeatherTitle ?? "Weather info";
    this.btnGeolocation.title = options.btnGeolocationTitle ?? "Geolocation";
    this.btnHelp.title = options.btnHelpTitle ?? "Help";

    if (this.grabFocus) {
      document.getElementById(this.target).tabIndex = -1;
    }

    this.map.on("singleclick", (evt) => this.onSingleClick(evt));
    this.map.on("moveend", (evt) => {
      this.dispatchEvent("qmolMoveEnd", { type: "movend" });
      this.newtinLastCenter = [];
      this.getNewtin();
    });

    this.map.on("click", (evt) => {
      this.dispatchEvent("qmolClick", {
        type: "keypress",
        event: evt.originalEvent,
      });
    });
    this.map.on("keypress", (evt) =>
      this.dispatchEvent("qmolKeypress", {
        type: "keypress",
        event: evt.originalEvent,
      }),
    );
    this.map.on("keydown", (evt) =>
      this.dispatchEvent("qmolKeydown", {
        type: "keypress",
        event: evt.originalEvent,
      }),
    );
    this.map.on("keyup", (evt) =>
      this.dispatchEvent("qmolKeyup", {
        type: "keypress",
        event: evt.originalEvent,
      }),
    );

    document
      .getElementsByClassName("ol-scale-line")[0]
      .addEventListener("click", (evt) => {
        this.dispatchEvent("qmolScaleLineClick", {
          type: "click",
          event: evt.originalEvent,
        });
        if (this.handleAddDrawPoint) {
          if (this.draw && this.drawType !== "Point") {
            this.onShowAddDrawPoint();
          }
        }
      });

    if (this.handleAddDrawPoint) {
      this.map.on("keydown", (evt) => {
        if (
          evt.originalEvent.key === "Insert" &&
          this.draw &&
          this.drawType !== "Point"
        ) {
          this.onShowAddDrawPoint();
        }
      });
    }

    if (this.handleDrawDelete) {
      this.map.on("keydown", (evt) => {
        if (
          evt.originalEvent.key === "Delete" &&
          this.draw &&
          this.drawType !== "Point"
        ) {
          if (this.draw) this.draw.removeLastPoint();
        }
      });
    }

    if (this.handleAddDrawFinish) {
      this.map.on("keydown", (evt) => {
        if (
          evt.originalEvent.key === "Enter" &&
          this.draw &&
          this.drawType !== "Point"
        ) {
          this.draw.finishDrawing();
        }
      });
    }

    if (this.map.getTargetElement().tabIndex === -1) {
      this.mouseEntered = false;
      this.map.getTargetElement().addEventListener("mouseenter", (evt) => {
        this.mouseEntered = true;
      });
      this.map.getTargetElement().addEventListener("mousemove", (evt) => {
        if (
          this.mouseEntered &&
          this.map.getTargetElement() !== document.activeElement
        ) {
          if (this.autoFocus) {
            this.map.getTargetElement().focus();
          }
        }
        this.mouseEntered = false;
      });
      this.map.getTargetElement().addEventListener("wheel", (evt) => {
        if (this.map.getTargetElement() !== document.activeElement) {
          if (this.autoFocusWheel) {
            this.map.getTargetElement().focus();
          }
        }
      });
    }

    document
      .getElementsByClassName("ol-latlong")[0]
      .addEventListener("click", (evt) => {
        this.dispatchEvent("qmolLatLongClick", {
          type: "click",
          event: evt.originalEvent,
        });
        if (this.handleAddLatLongPoint) {
          this.onShowAddLatLongPoint();
        }
      });

    document
      .getElementsByClassName("ol-zoom")[0]
      .addEventListener("mouseenter", (evt) => {
        clearTimeout(this.autoHideTimeout);
        if (
          this.autoHide &&
          this.hideControls &&
          document.getElementsByClassName("ol-hide").length > 0
        )
          this.toggleControls(false);
      });

    document
      .getElementsByClassName("ol-zoom")[0]
      .addEventListener("mouseleave", (evt) => {
        clearTimeout(this.autoHideTimeout);
        this.autoHideTimeout = setTimeout(() => {
          if (
            this.autoHide &&
            this.hideControls &&
            document.getElementsByClassName("ol-hide").length === 0
          )
            this.toggleControls(true);
        }, this.autoHideDelay);
      });

    setInterval(() => {
      this.blinkShow();
    }, this.blinkInterval);

    document.getElementsByClassName("ol-zoom-in")[0].tabIndex = -1;
    document.getElementsByClassName("ol-zoom-out")[0].tabIndex = -1;
    document.getElementsByClassName("ol-zoom-in")[0].className += " ol-canhide";
    document.getElementsByClassName("ol-zoom-out")[0].className +=
      " ol-canhide";

    if (this.enableCompass) {
      if (window.DeviceOrientationEvent) {
        if ("AbsoluteOrientationSensor" in window) {
          Promise.all([
            navigator.permissions.query({ name: "magnetometer" }),
          ]).then((results) => {
            if (results.every((result) => result.state === "granted")) {
              // eslint-disable-next-line no-undef
              this.sensor = new AbsoluteOrientationSensor({ frequency: 60 });
              this.sensor.start();
            }
          });
        }

        window.addEventListener("deviceorientation", (event) => {
          if (this.compassOn) {
            var compassdir;
            if (event.webkitCompassHeading) {
              let accuracy = event.webkitCompassAccuracy;
              if (accuracy === null || accuracy < 0 || accuracy > 50) return;
              compassdir = event.webkitCompassHeading;
            } else compassdir = event.alpha;
            if (compassdir) {
              this.compassDegrees = compassdir;
              const rad = (this.compassDegrees * Math.PI) / 180;
              document.getElementsByClassName("ol-compass")[1].style.transform =
                `rotate(${rad}rad)`;
              this.map.getView().setRotation(rad);
            }
          }
        });
      }
    }
  }

  set showLayerSelect(show) {
    this.selLayers.style.display = show ? "block" : "none";
  }
  set showCompass(show) {
    this.imgCompass.style.display = show ? "block" : "none";
  }
  set showToggleControls(show) {
    this.btnToggleControls.style.display = show ? "block" : "none";
  }
  set showToggleFullScreen(show) {
    this.btnToggleFullscreen.style.display = show ? "block" : "none";
  }
  set showZoomToAll(show) {
    this.btnZoomAll.style.display = show ? "block" : "none";
  }
  set showDraw(show) {
    this.btnDraw.style.display = show ? "block" : "none";
  }
  set showDrawPoint(show) {
    this.btnDrawPoint.style.display = show ? "block" : "none";
  }
  set showDrawLine(show) {
    this.btnDrawLine.style.display = show ? "block" : "none";
  }
  set showDrawPolygon(show) {
    this.btnDrawPolygon.style.display = show ? "block" : "none";
  }
  set showEdit(show) {
    this.btnEdit.style.display = show ? "block" : "none";
  }
  set showDelete(show) {
    this.btnDelete.style.display = show ? "block" : "none";
  }
  set showMeasure(show) {
    this.btnMeasure.style.display = show ? "block" : "none";
  }
  set showBullseye(show) {
    this.btnBullseye.style.display = show ? "block" : "none";
  }
  set showIntersection(show) {
    this.btnIntersection.style.display = show ? "block" : "none";
  }
  set showFlag(show) {
    this.btnFlag.style.display = show ? "block" : "none";
  }
  set showBlock(show) {
    this.btnBlock.style.display = show ? "block" : "none";
  }
  set showIdentify(show) {
    this.btnIdentify.style.display = show ? "block" : "none";
  }
  set showWeather(show) {
    this.btnWeather.style.display = show ? "block" : "none";
  }
  set showGeolocation(show) {
    this.btnGeolocation.style.display = show ? "block" : "none";
  }
  set showHelp(show) {
    this.btnHelp.style.display = show ? "block" : "none";
  }
  set showScaleBar(show) {
    this.scaleBar.style.display = show ? "block" : "none";
  }
  set showLongLat(show) {
    document.querySelector("#map").querySelector(".ol-latlong").style.display =
      show ? "block" : "none";
  }
  set showLogo(show) {
    this.imgLogo.style.display = show ? "block" : "none";
  }
  set setDrawType(type) {
    this.drawType = type;
    let activeDraw = null;
    if (activeDraw === null)
      activeDraw =
        this.btnDraw.className.indexOf("ol-toggled") > -1 ? this.btnDraw : null;
    if (activeDraw === null)
      activeDraw =
        this.btnDrawPoint.className.indexOf("ol-toggled") > -1
          ? this.btnDrawPoint
          : null;
    if (activeDraw === null)
      activeDraw =
        this.btnDrawLine.className.indexOf("ol-toggled") > -1
          ? this.btnDrawLine
          : null;
    if (activeDraw === null)
      activeDraw =
        this.btnDrawPolygon.className.indexOf("ol-toggled") > -1
          ? this.btnDrawPolygon
          : null;
    if (activeDraw !== null) {
      this.removeInteractions();
      this.toggleButtonsOn();
      this.dispatchEvent("qmolBtnDrawClick", { type: "off" });
      this.startDraw(activeDraw);
    }
  }
  set showMeasureDirection(show) {
    this.measureDirection = show;
    this.measure.changed();
    this.edit.changed();
  }
  set showMeasureLengthFeet(show) {
    this.measureLengthFeet = show;
    this.measure.changed();
    this.edit.changed();
  }
  set showMeasureLengthYards(show) {
    this.measureLengthYards = show;
    this.measure.changed();
    this.edit.changed();
  }
  set showMeasureLengthMiles(show) {
    this.measureLengthMiles = show;
    this.measure.changed();
    this.edit.changed();
  }
  set showMeasureLengthMeters(show) {
    this.measureLengthMeters = show;
    this.measure.changed();
    this.edit.changed();
  }
  set showMeasureLengthKilometers(show) {
    this.measureLengthKilometers = show;
    this.measure.changed();
    this.edit.changed();
  }
  set showMeasureAreaSqFeet(show) {
    this.measureAreaSqFeet = show;
    this.edit.changed();
    this.edit.changed();
  }
  set showMeasureAreaSqYards(show) {
    this.measureAreaSqYards = show;
    this.edit.changed();
    this.edit.changed();
  }
  set showMeasureAreaAcres(show) {
    this.measureAreaAcres = show;
    this.edit.changed();
    this.edit.changed();
  }
  set showMeasureAreaSqMiles(show) {
    this.measureAreaSqMiles = show;
    this.edit.changed();
    this.edit.changed();
  }
  set showMeasureAreaSqMeters(show) {
    this.measureAreaSqMeters = show;
    this.edit.changed();
    this.edit.changed();
  }
  set showMeasureAreaSqKilometers(show) {
    this.measureAreaSqKilometers = show;
    this.edit.changed();
    this.edit.changed();
  }

  toggleCut() {
    this.cut = !this.cut;
    document
      .getElementsByClassName("ol-btndrawpolygon")[0]
      .classList.toggle("ol-btndrawpolygon-cut");
  }

  setupLayers() {
    const layers = [];
    const gotNewtin = this.newtinHost && this.newtinHost.length > 0;

    if (gotNewtin > 0) {
      layers.push(
        new this.ol.layer.Image({
          visible: true,
          name: "Newtin Streets",
          isBase: true,
          isNewtin: true,
          isAerial: false,
          canDraw: true,
        }),
        new this.ol.layer.Image({
          visible: false,
          name: "Newtin Aerial",
          isBase: true,
          isNewtin: true,
          isAerial: true,
          canDraw: true,
        }),
      );
    }

    layers.push(
      new this.ol.layer.Tile({
        visible: !gotNewtin,
        name: "GeoLogix Streets",
        isBase: true,
        canDraw: true,
        source: new this.ol.source.TileWMS({
          url: "https://maplogix.newtin.com/geoserver/wms",
          crossOrigin: "anonymous",
          params: {
            LAYERS: "USA",
          },
        }),
      }),
    );
    layers.push(
      new this.ol.layer.Tile({
        visible: false,
        name: "GeoLogix Aerial",
        isBase: true,
        canDraw: true,
        source: new this.ol.source.TileWMS({
          url: "https://maplogix.newtin.com/geoserver/wms",
          crossOrigin: "anonymous",
          params: {
            LAYERS: "USAORTHO",
          },
        }),
      }),
    );
    layers.push(
      new this.ol.layer.Tile({
        visible: false,
        name: "OpenStreetMap",
        isBase: true,
        source: new this.ol.source.OSM(),
      }),
    );
    layers.push(
      new this.ol.layer.Tile({
        visible: false,
        name: "USGS Aerial",
        isBase: true,
        source: new this.ol.source.XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
          maxZoom: 18,
        }),
      }),
    );
    layers.push(
      new this.ol.layer.Tile({
        visible: false,
        name: "National Hybrid",
        isBase: true,
        source: new this.ol.source.XYZ({
          url: "https://basemap.nationalmap.gov/ArcGIS/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
          maxZoom: 16,
        }),
      }),
    );
    layers.push(
      new this.ol.layer.Tile({
        visible: false,
        name: "World Topo",
        isBase: true,
        source: new this.ol.source.XYZ({
          url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
          maxZoom: 18,
        }),
      }),
    );
    layers.push(
      new this.ol.layer.Tile({
        visible: false,
        name: "USGS Topo",
        isBase: true,
        source: new this.ol.source.XYZ({
          url: "https://caltopo.s3.amazonaws.com/topo/{z}/{x}/{y}.png",
          crossOrigin: "anonymous",
          maxZoom: 16,
        }),
      }),
    );
    layers.push(
      new this.ol.layer.Tile({
        visible: false,
        name: "CommunityTIGER",
        isBase: true,
        source: new this.ol.source.XYZ({
          url: "https://tigerweb.geo.census.gov/arcgis/rest/services/Basemaps/CommunityTIGER/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
          maxZoom: 15,
        }),
      }),
    );
    layers.push(
      new this.ol.layer.Tile({
        visible: false,
        name: "Stamen",
        isBase: true,
        source: new this.ol.source.XYZ({
          url: "https://stamen-tiles-{a-d}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png",
          crossOrigin: "anonymous",
          maxZoom: 16,
        }),
      }),
    );
    layers.push(
      new this.ol.layer.Tile({
        visible: false,
        name: "Radar",
        isBase: true,
        isTransparent: true,
        source: new this.ol.source.TileWMS({
          url: "https://idpgis.ncep.noaa.gov/arcgis/services/NWS_Observations/radar_base_reflectivity/MapServer/WMSServer",
          crossOrigin: "anonymous",
          params: {
            LAYERS: "1",
          },
        }),
        opacity: 0.6,
      }),
    );
    layers.push(
      new this.ol.layer.Tile({
        visible: false,
        name: "none",
        isBase: true,
      }),
    );

    this.edit = new this.ol.layer.VectorImage({
      name: "edit",
      source: new this.ol.source.Vector(),
      style: (feature, resolution) => this.getStyle(feature, resolution),
    });
    layers.push(this.edit);

    this.image = new this.ol.layer.Image({
      visible: true,
      name: "image",
      isBase: false,
    });
    layers.push(this.image);

    this.show = new this.ol.layer.VectorImage({
      name: "show",
      source: new this.ol.source.Vector(),
      declutter: this.declutter,
      style: (feature, resolution) => this.getStyle(feature, resolution),
    });
    layers.push(this.show);

    this.measure = new this.ol.layer.VectorImage({
      name: "measure",
      source: new this.ol.source.Vector(),
      style: (feature) => this.measureStyle(feature),
    });
    layers.push(this.measure);

    this.bullseye = new this.ol.layer.VectorImage({
      name: "bullseye",
      source: new this.ol.source.Vector(),
    });
    layers.push(this.bullseye);

    return layers;
  }

  setupControls() {
    this.selLayers = document.createElement("select");
    this.selLayers.title = "Switch map layers";
    this.selLayers.className = "ol-switcher";
    this.selLayers.tabIndex = -1;
    for (const layer of this.layers) {
      if (layer.get("isBase")) {
        const option = document.createElement("option");
        option.value = layer.getProperties().name;
        option.text = layer.getProperties().name;
        this.selLayers.appendChild(option);
      }
    }
    this.selLayers.onchange = () => this.onChangeSelLayers();
    this.map.addControl(
      new this.ol.control.Control({
        element: this.selLayers,
      }),
    );

    this.imgCompass = document.createElement("img");
    this.imgCompass.title = "Compass rose";
    this.imgCompass.className = "ol-compass";
    this.imgCompass.src = this.compass;
    if (this.enableRotation) {
      // this.imgCompass.ondblclick = () => this.onRotationReset();
      // this.imgCompass.onclick = () => this.onRotationIncrement();
    }
    if (this.enableCompass) {
      this.imgCompass.onclick = () => {
        this.onRotationReset();
        this.compassOn = !this.compassOn;
        if (this.compassOn) {
          this.imgCompass.style.opacity = 1;
          this.imgCompass.style.filter =
            "brightness(80%) sepia(2) hue-rotate(100deg) contrast(2.5)";
        } else {
          this.imgCompass.style.opacity = 0.8;
          this.imgCompass.style.filter = "none";
        }
      };
    }
    this.imgCompass.onerror = () => {
      this.imgCompass.style.display = "none";
    };
    this.map.addControl(
      new this.ol.control.Control({
        element: this.imgCompass,
      }),
    );

    this.map.getView().on("change:rotation", () => {
      const radians = this.map.getView().getRotation();
      this.compassDegrees = (radians * 180) / Math.PI;
      document.getElementsByClassName("ol-compass")[1].style.transform =
        `rotate(${radians}rad)`;
    });

    this.imgLogo = document.createElement("img");
    this.imgLogo.className = "ol-logo";
    this.imgLogo.src = this.logo;
    this.imgLogo.onerror = () => {
      this.imgLogo.style.display = "none";
    };
    this.map.addControl(
      new this.ol.control.Control({
        element: this.imgLogo,
      }),
    );

    this.map.addControl(
      new this.ol.control.ScaleLine({
        bar: false,
        units: "us",
        className: "ol-scale-line",
      }),
    );
    this.scaleBar = document
      .querySelector("#" + this.target)
      .querySelector(".ol-scale-line");
    this.scaleBar.title = "Scale in feet/miles";

    this.divLatLong = document.createElement("div");
    this.map.addControl(
      new this.ol.control.Control({
        element: this.divLatLong,
      }),
    );

    this.mousePositionControl = new this.ol.control.MousePosition({
      className: "ol-latlong",
      target: this.divLatlong,
      coordinateFormat: (coordinate) => {
        return this.ol.coordinate.format(coordinate, "{y}, {x}", 4);
      },
      projection: "EPSG:4326",
      undefinedHTML: "",
    });
    this.map.controls.extend([this.mousePositionControl]);
    const divOlLatLong = document
      .querySelector("#" + this.target)
      .querySelector(".ol-latlong");
    divOlLatLong.title = "Latitude/Longitude in decimal degrees";

    this.divPopup = document.createElement("div");
    this.divPopup.className = "ol-popup";
    this.map.addControl(
      new this.ol.control.Control({
        element: this.divPopup,
      }),
    );
    this.divPopupOverlay = new this.ol.Overlay({
      element: this.divPopup,
      closeBox: true,
    });
    this.map.addOverlay(this.divPopupOverlay);

    this.msgbox = document.createElement("div");
    this.msgbox.className = "ol-msgbox";
    this.map.addControl(
      new this.ol.control.Control({
        element: this.msgbox,
      }),
    );

    this.addPointMsgbox = document.createElement("div");
    this.addPointMsgbox.className = "ol-addpointmsgbox";
    this.addPointMsgbox.innerHTML =
      '<div><h3>Add new point from last point</h3><input type="text" class="ol-txtAddPointDistance" style="width:6em;"/><select class="ol-selAddPointDistanceUnits"><option value="feet">Feet</option><option value="yards">Yard(s)</option><option value="miles">Mile(s)</option><option value="meters">Meter(s)</option></select><select class="ol-selAddPointDirection"><option>North</option><option>South</option><option>East</option><option>West</option><option>North East</option><option>South East</option><option>North West</option><option>South West</option></select><br/><button class="ol-btnAddPointDistance">Add</button><button class="ol-btnExitPointDistance">Exit</button></div>';
    this.addPointMsgbox.style.display = "none";
    this.map.addControl(
      new this.ol.control.Control({
        element: this.addPointMsgbox,
      }),
    );
    document.getElementsByClassName("ol-btnAddPointDistance")[0].onclick = () =>
      this.onAddDrawPoint();
    document.getElementsByClassName("ol-btnExitPointDistance")[0].onclick =
      () => this.onExitDrawPoint();

    this.addLatLongPointMsgBox = document.createElement("div");
    this.addLatLongPointMsgBox.className = "ol-addLatLongPointMsgBox";
    this.addLatLongPointMsgBox.innerHTML =
      '<div><h3>Lat/Long point</h3><table class="dialog"><tr><td>Lat</td><td><input type="text" class="ol-txtAddLatLongPointLat" style="width:6em;"/></td></tr><tr><td>Long</td><td><input type="text" class="ol-txtAddLatLongPointLon" style="width:6em;"/></td></tr></table><span><center><button class="ol-btnZoomAddLatLongPoint">Zoom</button><button class="ol-btnAddLatLongPoint">Add</button><button class="ol-btnClearAddLatLongPoint">Clear</button><button class="ol-btnExitAddLatLongPoint">Exit</button></center></span></div>';
    this.addLatLongPointMsgBox.style.display = "none";
    this.map.addControl(
      new this.ol.control.Control({
        element: this.addLatLongPointMsgBox,
      }),
    );
    document.getElementsByClassName("ol-btnZoomAddLatLongPoint")[0].onclick =
      () => this.onAddLatLongPoint(false);
    document.getElementsByClassName("ol-btnAddLatLongPoint")[0].onclick = () =>
      this.onAddLatLongPoint(true);
    document.getElementsByClassName("ol-btnClearAddLatLongPoint")[0].onclick =
      () => this.onAddLatLongPoint();
    document.getElementsByClassName("ol-btnExitAddLatLongPoint")[0].onclick =
      () => this.onExitLatLongPoint();

    const divOlZoom = document
      .querySelector("#" + this.target)
      .querySelector(".ol-zoom");

    this.btnToggleControls = document.createElement("button");
    this.btnToggleControls.title = "Toggle controls";
    this.btnToggleControls.className = "ol-btntogglecontrols";
    this.btnToggleControls.onclick = () => {
      this.hideControls = !this.hideControls;
      this.toggleControls(this.hideControls);
    };
    this.btnToggleControls.type = "button";
    this.btnToggleControls.tabIndex = -1;
    divOlZoom.prepend(this.btnToggleControls);

    this.btnToggleFullscreen = document.createElement("button");
    this.btnToggleFullscreen.title = "Toggle Fullscreen";
    this.btnToggleFullscreen.className = "ol-btntogglefullscreen ol-canhide";
    this.btnToggleFullscreen.onclick = () => this.toggleFullscreen();
    this.btnToggleFullscreen.type = "button";
    this.btnToggleFullscreen.tabIndex = -1;
    divOlZoom.appendChild(this.btnToggleFullscreen);

    this.btnZoomAll = document.createElement("button");
    this.btnZoomAll.title = "Zoom to all";
    this.btnZoomAll.className = "ol-btnzoomall ol-canhide";
    this.btnZoomAll.onclick = () => this.zoomToAll();
    this.btnZoomAll.type = "button";
    this.btnZoomAll.tabIndex = -1;
    divOlZoom.appendChild(this.btnZoomAll);

    this.btnDraw = document.createElement("button");
    this.btnDraw.title = "Draw";
    this.btnDraw.className = "ol-toggle-button-group ol-btndraw ol-canhide";
    this.btnDraw.onclick = () => this.startDraw(this.btnDraw);
    this.btnDraw.type = "button";
    this.btnDraw.tabIndex = -1;
    divOlZoom.appendChild(this.btnDraw);

    this.btnDrawPoint = document.createElement("button");
    this.btnDrawPoint.title = "Draw point";
    this.btnDrawPoint.className =
      "ol-toggle-button-group ol-btndrawpoint ol-canhide";
    this.btnDrawPoint.onclick = () => {
      this.drawType = "Point";
      this.startDraw(this.btnDrawPoint);
    };
    this.btnDrawPoint.type = "button";
    this.btnDrawPoint.tabIndex = -1;
    divOlZoom.appendChild(this.btnDrawPoint);

    this.btnDrawLine = document.createElement("button");
    this.btnDrawLine.title = "Draw line";
    this.btnDrawLine.className =
      "ol-toggle-button-group ol-btndrawline ol-canhide";
    this.btnDrawLine.onclick = () => {
      this.drawType = "LineString";
      this.startDraw(this.btnDrawLine);
    };
    this.btnDrawLine.type = "button";
    this.btnDrawLine.tabIndex = -1;
    divOlZoom.appendChild(this.btnDrawLine);

    this.btnDrawPolygon = document.createElement("button");
    this.btnDrawPolygon.title = "Draw polygon";
    this.btnDrawPolygon.className =
      "ol-toggle-button-group ol-btndrawpolygon ol-canhide";
    this.btnDrawPolygon.onclick = () => {
      this.drawType = "Polygon";
      this.startDraw(this.btnDrawPolygon);
    };
    this.btnDrawPolygon.type = "button";
    this.btnDrawPolygon.tabIndex = -1;
    divOlZoom.appendChild(this.btnDrawPolygon);

    this.btnBlock = document.createElement("button");
    this.btnBlock.title = "Block";
    this.btnBlock.className = "ol-toggle-button-group ol-btnblock ol-canhide";
    this.btnBlock.onclick = () => this.startBlock();
    this.btnBlock.type = "button";
    this.btnBlock.tabIndex = -1;
    divOlZoom.appendChild(this.btnBlock);

    this.btnEdit = document.createElement("button");
    this.btnEdit.title = "Edit";
    this.btnEdit.className = "ol-toggle-button-group ol-btnedit ol-canhide";
    this.btnEdit.onclick = () => this.startEdit();
    this.btnEdit.type = "button";
    this.btnEdit.tabIndex = -1;
    divOlZoom.appendChild(this.btnEdit);

    this.btnDelete = document.createElement("button");
    this.btnDelete.title = "Delete";
    this.btnDelete.className = "ol-toggle-button-group ol-btndelete ol-canhide";
    this.btnDelete.onclick = () => this.startDelete();
    this.btnDelete.type = "button";
    this.btnDelete.tabIndex = -1;
    divOlZoom.appendChild(this.btnDelete);

    this.btnMeasure = document.createElement("button");
    this.btnMeasure.title = "Measure";
    this.btnMeasure.className =
      "ol-toggle-button-group ol-btnmeasure ol-canhide";
    this.btnMeasure.onclick = () => {
      this.drawType = "LineString";
      this.startMeasure();
    };
    this.btnMeasure.type = "button";
    this.btnMeasure.tabIndex = -1;
    divOlZoom.appendChild(this.btnMeasure);

    this.btnBullseye = document.createElement("button");
    this.btnBullseye.title = "Bullseye (100ft & 1/4 mi)";
    this.btnBullseye.className =
      "ol-toggle-button-group ol-btnbullseye ol-canhide";
    this.btnBullseye.onclick = () => this.startBullseye();
    this.btnBullseye.type = "button";
    this.btnBullseye.tabIndex = -1;
    divOlZoom.appendChild(this.btnBullseye);

    this.btnIntersection = document.createElement("button");
    this.btnIntersection.title = "Intersections";
    this.btnIntersection.className =
      "ol-toggle-button-group ol-btnintersection ol-canhide";
    this.btnIntersection.onclick = () => this.startIntersection();
    this.btnIntersection.type = "button";
    this.btnIntersection.tabIndex = -1;
    divOlZoom.appendChild(this.btnIntersection);

    this.btnFlag = document.createElement("button");
    this.btnFlag.title = "Flag";
    this.btnFlag.className = "ol-toggle-button-group ol-btnflag ol-canhide";
    this.btnFlag.onclick = () => this.startFlag();
    this.btnFlag.type = "button";
    this.btnFlag.tabIndex = -1;
    divOlZoom.appendChild(this.btnFlag);

    this.btnIdentify = document.createElement("button");
    this.btnIdentify.title = "Identify";
    this.btnIdentify.className =
      "ol-toggle-button-group ol-btnidentify ol-canhide";
    this.btnIdentify.onclick = () => this.startIdentify();
    this.btnIdentify.type = "button";
    this.btnIdentify.tabIndex = -1;
    divOlZoom.appendChild(this.btnIdentify);

    this.btnWeather = document.createElement("button");
    this.btnWeather.title = "Weather Info";
    this.btnWeather.className =
      "ol-toggle-button-group ol-btnweather ol-canhide";
    this.btnWeather.onclick = () => this.startWeather();
    this.btnWeather.type = "button";
    this.btnWeather.tabIndex = -1;
    divOlZoom.appendChild(this.btnWeather);

    this.btnGeolocation = document.createElement("button");
    this.btnGeolocation.title = "GeoLocation";
    this.btnGeolocation.className =
      "ol-toggle-button-group ol-btngeolocation ol-canhide";
    this.btnGeolocation.onclick = () => {
      this.zoomToGeolocation();
    };
    this.btnGeolocation.type = "button";
    this.btnGeolocation.tabIndex = -1;
    divOlZoom.appendChild(this.btnGeolocation);

    this.btnHelp = document.createElement("button");
    this.btnHelp.title = "Help";
    this.btnHelp.className = "ol-toggle-button-group ol-btnhelp ol-canhide";
    this.btnHelp.onclick = () => {
      this.startHelp();
    };
    this.btnHelp.type = "button";
    this.btnHelp.tabIndex = -1;
    divOlZoom.appendChild(this.btnHelp);
  }

  toggleControls(hide) {
    const controls = document.getElementsByClassName("ol-canhide");
    for (const element of controls) {
      if (hide) {
        element.classList.add("ol-hide");
      } else {
        element.classList.remove("ol-hide");
      }
    }
  }

  async toggleFullscreen() {
    const isInFullScreen =
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);
    const docElm = document.getElementById(this.target);
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        await docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        await docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        await docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        await docElm.msRequestFullscreen();
      }
      this.dispatchEvent("qmolBtnToggleFullscreenClick", { type: "on" });
      setTimeout(() => {
        this.newtinLastCenter = [];
        this.getNewtin();
      }, 100);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.dispatchEvent("qmolBtnToggleFullscreenClick", { type: "off" });
    }
  }

  onChangeSelLayers() {
    this.removeInteractions();
    if (!this.buttonsGroupActive) {
      this.toggleButtonsOn();
      this.map.getTargetElement().style.cursor = "default";
    }

    const layerName = this.selLayers.value;
    let transparent = false;
    this.map.getLayers().forEach((layer) => {
      if (layer.get("name") === layerName) {
        transparent = layer.get("isTransparent");
      }
    });
    this.map.getLayers().forEach((layer) => {
      if (layer.get("name") === layerName) {
        layer.setVisible(true);
      } else {
        if (!transparent) {
          if (layer.get("isBase")) {
            layer.setVisible(false);
          }
        }
      }
    });
    this.dispatchEvent("qmolSelLayersChange", {
      type: "select",
      layer: this.selLayers.value,
    });
    this.newtinLastCenter = [];
    this.getNewtin();
  }

  getNewtin(county, center, zoom) {
    if (this.skipNewtin) {
      this.skipNewtin = false;
      return;
    }
    let active;
    for (const layer of this.layers) {
      if (layer.get("visible")) {
        active = layer;
        break;
      }
    }
    if (!active.get("isNewtin")) {
      if (county && center && zoom) {
        this.skipNewtin = true;
        const extent = [
          center[0] -
            this.convertMetersToDegreesLongitude(zoom, center[1]) * 0.5,
          center[1] - this.convertMetersToDegreesLatitude(zoom) * 0.5,
          center[0] +
            this.convertMetersToDegreesLongitude(zoom, center[1]) * 0.5,
          center[1] + this.convertMetersToDegreesLatitude(zoom) * 0.5,
        ];
        this.map
          .getView()
          .fit(
            this.ol.proj.transformExtent(extent, "EPSG:4326", "EPSG:3857"),
            this.map.getSize(),
          );
      }
      return;
    }

    const extent = this.ol.proj.transformExtent(
      this.map.getView().calculateExtent(this.map.getSize()),
      "EPSG:3857",
      "EPSG:4326",
    );
    zoom =
      zoom ??
      parseInt(
        this.distInMeters(extent[0], extent[3], extent[0], extent[1]) * 1.025,
      );

    const recenterMap = center ?? false;
    center =
      center ??
      this.ol.proj.transform(
        this.map.getView().getCenter(),
        "EPSG:3857",
        "EPSG:4326",
      );

    const canvas = document.getElementById(this.target);
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;

    if (
      this.newtinLastCenter &&
      this.newtinLastCenter[0] === center[0] &&
      this.newtinLastCenter[1] === center[1]
    ) {
      return;
    } else {
      this.newtinLastCenter = center;
    }

    // If this.county is supplied it should look like {STATE: 'CA', COUNTY: 'San Diego'}
    county = county ?? this.county ?? this.getCountyForLongLat(center);
    if (!county) return;

    fetch(this.newtinHost + "/mapping", {
      method: "POST",
      mode: "cors",
      body: `qmcmd;zoom;${width};${height};${county.STATE};${county.COUNTY.toUpperCase()};${center[1]};${center[0]};${zoom};${zoom < 5000 ? (active.get("isAerial") ? "mrSidH" : "") : ""}`,
    })
      .then((response) => response.text())
      .then((data) => {
        const parts = data.split(";");
        const url = this.newtinHost + parts[1];
        const coords = parts[2].split(",");
        const extent = [
          parseFloat(coords[2]),
          parseFloat(coords[1]),
          parseFloat(coords[3]),
          parseFloat(coords[0]),
        ];

        active.setSource(
          new this.ol.source.ImageStatic({
            url: url,
            imageExtent: extent,
            projection: "EPSG:4326",
            crossOrigin: "anonymous",
          }),
        );
        if (recenterMap) {
          this.skipNewtin = true;
          this.map
            .getView()
            .fit(
              this.ol.proj.transformExtent(extent, "EPSG:4326", "EPSG:3857"),
              { padding: [-10, -10, -10, -10] },
            );
        }
      })
      .catch((error) => console.error(error));
  }

  convertMetersToDegreesLongitude(meters, latitude) {
    return meters / (Math.cos((latitude * Math.PI) / 180) * 111319);
  }
  convertMetersToDegreesLatitude(meters) {
    return meters * 0.000008998;
  }

  distInMeters(x1, y1, x2, y2) {
    return this.distInMiles(x1, y1, x2, y2) * 1609.344;
  }

  distInMiles(x1, y1, x2, y2) {
    var dx, dy;
    x1 = Math.abs(x1);
    y1 = Math.abs(y1);
    x2 = Math.abs(x2);
    y2 = Math.abs(y2);
    dx =
      Math.abs(x1 - x2) * Math.cos((((y1 + y2) / 2) * Math.PI) / 180) * 69.172;
    dy = Math.abs(y1 - y2) * (68.703 + (((y1 + y2) / 2) * 0.695) / 90);
    return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
  }

  getCountyForLongLat(coord) {
    coord = this.ol.proj.fromLonLat(coord);
    let STATE = "";
    for (const state of this.states) {
      if (state.getGeometry().intersectsCoordinate(coord)) {
        STATE = state.getProperties().STATE;
        break;
      }
    }
    for (const county of this.counties) {
      if (STATE === county.getProperties().STATE) {
        if (county.getGeometry().intersectsCoordinate(coord)) {
          return county.getProperties();
        }
      }
    }
    return;
  }

  getFeatureData(data) {
    return new this.ol.format.GeoJSON().readFeatures(data, {
      featureProjection: "EPSG:3857",
    });
  }

  zoomToState(st) {
    for (const state of this.states) {
      if (state.get("STATE") === st) {
        this.map.getView().fit(state.getGeometry(), {
          padding: this.padding,
        });
        break;
      }
    }
  }

  zoomToCounty(st, cnty) {
    for (const county of this.counties) {
      if (
        county.get("STATE") === st &&
        county.get("COUNTY").toUpperCase() === cnty.toUpperCase()
      ) {
        this.map.getView().fit(county.getGeometry(), {
          padding: this.padding,
        });
        break;
      }
    }
  }

  zoomToFeatures(featureCollection) {
    if (featureCollection.length === 0) return;

    const features = new this.ol.format.GeoJSON().readFeatures(
      featureCollection,
      {
        featureProjection: "EPSG:3857",
      },
    );

    let extent;
    for (const feature of features) {
      if (extent === undefined) {
        extent = feature.getGeometry().getExtent();
      } else {
        extent = this.ol.extent.extend(
          extent,
          feature.getGeometry().getExtent(),
        );
      }
    }
    this.map.getView().fit(extent, { padding: this.padding });

    if (this.map.getView().getZoom() > this.maxzoom) {
      this.map.getView().setZoom(this.maxzoom);
    }
    this.getNewtin();
  }

  zoomToLongLat(longLat, zoomLevel) {
    this.map.getView().setCenter(this.ol.proj.fromLonLat(longLat));
    if (zoomLevel) this.map.getView().setZoom(zoomLevel);
  }

  zoomToAll() {
    this.dispatchEvent("qmolBtnZoomToAllClick", { type: "click" });
    let extent = this.ol.extent.createEmpty();
    this.ol.extent.extend(extent, this.show.getSource().getExtent());
    this.ol.extent.extend(extent, this.edit.getSource().getExtent());
    if (this.img) {
      this.ol.extent.extend(extent, this.img.getSource().getExtent());
    }
    if (extent[0] === Infinity) return;
    this.map.getView().fit(extent, { padding: this.padding });
    this.getNewtin();
    if (this.map.getView().getZoom() > this.maxzoom) {
      this.map.getView().setZoom(this.maxzoom);
    }
  }

  getStyle(feature, resolution) {
    if (this.blink && feature.get("hidden")) {
      return;
    }
    const style = feature.get("style");
    if (!style) {
      const geometryType = feature.getGeometry().getType();
      if (feature.get("text")) {
        this.defaultStyles[geometryType].setText(feature.get("text"));
      }
      return this.defaultStyles[geometryType];
    }
    if (typeof style === "function") {
      return style(this.map.getView().getZoomForResolution(resolution));
    }
    return style;
  }

  addToShow(features) {
    if (features.features.length === 0) return;
    this.show.getSource().addFeatures(
      new this.ol.format.GeoJSON().readFeatures(features, {
        featureProjection: "EPSG:3857",
      }),
    );
  }

  getFromShow(property, value) {
    let features = [];
    for (const feature of this.show.getSource().getFeatures()) {
      if (!property || feature.get(property) === value) {
        features.push(feature);
      }
    }
    return new this.ol.format.GeoJSON().writeFeatures(features, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });
  }

  removeFromShow(property, value) {
    for (const feature of this.show.getSource().getFeatures()) {
      if (feature.get(property) === value) {
        this.show.getSource().removeFeature(feature);
      }
    }
  }

  zoomToShow() {
    if (this.show.getSource().getFeatures().length === 0) return;
    this.map.getView().fit(this.show.getSource().getExtent(), {
      padding: this.padding,
    });
    this.getNewtin();
    if (this.map.getView().getZoom() > this.maxzoom) {
      this.map.getView().setZoom(this.maxzoom);
    }
  }

  clearShow() {
    this.show.getSource().clear();
  }

  blinkShow() {
    if (!this.blink) return;
    var extent = this.map.getView().calculateExtent(this.map.getSize());
    const features = this.show.getSource().getFeaturesInExtent(extent);
    for (const feature of features) {
      if (feature.get("blink")) {
        feature.setProperties({ hidden: !feature.get("hidden") });
      }
    }
  }

  toggleButtonsOn() {
    this.buttonsGroupActive = true;
    const buttons = document
      .querySelector("#" + this.target)
      .querySelectorAll(".ol-toggle-button-group");
    for (const button of buttons) {
      button.classList.remove("ol-disabled");
      button.className = button.className.replace(/ ol-toggled/g, "");
      button.isDisabled = false;
    }

    this.msgbox.style.display = "none";
    this.divPopup.style.display = "none";
  }

  toggleButtonsOff(exceptButton) {
    this.buttonsGroupActive = false;
    const buttons = document
      .querySelector("#" + this.target)
      .querySelectorAll(".ol-toggle-button-group");
    for (const button of buttons) {
      if (button !== exceptButton) {
        button.classList.add("ol-disabled");
        button.isDisabled = true;
      }
    }
    if (exceptButton)
      exceptButton.className = exceptButton.className + " ol-toggled";
  }

  startMeasure() {
    this.removeInteractions();
    if (!this.buttonsGroupActive) {
      let toggled = this.btnMeasure.classList.contains("ol-toggled");
      this.toggleButtonsOn();
      this.dispatchEvent("qmolBtnMeasureClick", { type: "off" });
      this.map.getTargetElement().style.cursor = "default";
      if (toggled) {
        return;
      }
    }
    this.toggleButtonsOff(this.btnMeasure);
    this.dispatchEvent("qmolBtnMeasureClick", { type: "on" });
    this.map.getTargetElement().style.cursor = this.drawCursor;

    this.draw = new this.ol.interaction.Draw({
      source: this.measure.getSource(),
      type: "LineString",
      style: (feature) => this.measureStyle(feature),
    });

    this.modify = new this.ol.interaction.Modify({
      source: this.measure.getSource(),
    });

    this.map.addInteraction(this.draw);
    this.map.addInteraction(this.modify);
  }

  addToMeasure(features) {
    if (features.features.length === 0) return;
    this.measure.getSource().addFeatures(
      new this.ol.format.GeoJSON().readFeatures(features, {
        featureProjection: "EPSG:3857",
      }),
    );
  }

  getFromMeasure(property, value) {
    let features = [];
    for (const feature of this.measure.getSource().getFeatures()) {
      if (!property || feature.get(property) === value) {
        features.push(feature);
      }
    }
    return new this.ol.format.GeoJSON().writeFeatures(features, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });
  }

  clearMeasure() {
    this.measure.getSource().clear();
  }

  canDrawOnLayer() {
    let canDraw = false;
    this.map.getLayers().forEach((layer) => {
      if (layer.get("name") === this.selLayers.value) {
        canDraw = layer.get("canDraw");
      }
    });
    return canDraw;
  }

  drawableLayers() {
    let layers = "<ul>";
    this.map.getLayers().forEach((layer) => {
      if (layer.get("canDraw")) {
        layers += `<li>${layer.get("name")}</li>`;
      }
    });
    return layers;
  }

  startDraw(button) {
    if (!this.canDrawOnLayer()) {
      this.showMsgbox(
        `<h2><p>Cannot Draw on this layer!</p><p>Try one of these layers:</p>${this.drawableLayers()}</h2>`,
        3000,
      );
      return;
    }

    this.removeInteractions();
    if (!this.buttonsGroupActive) {
      let toggled = button.classList.contains("ol-toggled");
      this.toggleButtonsOn();
      this.dispatchEvent("qmolBtnDrawClick", { type: "off" });
      this.map.getTargetElement().style.cursor = "default";
      if (toggled) {
        return;
      }
    }
    this.toggleButtonsOff(button);
    this.dispatchEvent("qmolBtnDrawClick", { type: "on" });
    this.map.getTargetElement().style.cursor = this.drawCursor;

    this.draw = new this.ol.interaction.Draw({
      source: this.edit.getSource(),
      type: this.drawType,
      style: (feature) => this.measureStyle(feature),
    });

    this.snap = new this.ol.interaction.Snap({
      source: this.edit.getSource(),
    });

    this.map.addInteraction(this.draw);
    this.map.addInteraction(this.snap);
    this.draw.on("drawstart", (feature) => this.onDrawStart(feature));
    this.draw.on("drawend", (feature) => this.onEndDraw(feature));
  }
  onDrawStart() {
    this.editCountBeforeDraw = this.edit.getSource().getFeatures().length;
    const features = this.edit.getSource().getFeatures();
    this.editFeatures = [];
    for (const feat of features) {
      this.editFeatures.push(feat.clone());
    }
  }
  onEndDraw(feature) {
    if (
      (this.drawType === "LineString" &&
        feature.feature.getGeometry().getCoordinates().length < 2) ||
      (this.drawType === "Polygon" &&
        feature.feature.getGeometry().getCoordinates()[0].length < 4)
    ) {
      setTimeout(() => {
        this.edit
          .getSource()
          .removeFeature(
            this.edit.getSource().getFeatures()[
              this.edit.getSource().getFeatures().length - 1
            ],
          );
      }, 0);
      return;
    }

    if (
      this.cut &&
      this.edit.getSource().getFeatures().length > 0 &&
      feature.feature.getGeometry().getType().indexOf("Polygon") !== -1
    ) {
      if (!this.substractFeature(feature.feature)) {
        setTimeout(() => {
          this.edit
            .getSource()
            .removeFeature(
              this.edit.getSource().getFeatures()[
                this.edit.getSource().getFeatures().length - 1
              ],
            );
          this.showMsgbox("<h2><p><center>Invalid cut</center></p></h2>");
        }, 0);
        return;
      }
      setTimeout(() => {
        this.edit
          .getSource()
          .removeFeature(
            this.edit.getSource().getFeatures()[
              this.edit.getSource().getFeatures().length - 1
            ],
          );
        this.edit
          .getSource()
          .addFeature(this.edit.getSource().getFeatures()[0].clone());
        const drawBuffer = this.drawBuffer;
        this.drawBuffer = 0;

        this.addToFeatures(this.edit.getSource().getFeatures());
        this.drawBuffer = drawBuffer;

        this.dispatchEvent("qmolDraw", {
          features: new this.ol.format.GeoJSON().writeFeaturesObject(
            [feature.feature],
            {
              dataProjection: "EPSG:4326",
              featureProjection: "EPSG:3857",
            },
          ),
          type: "cut",
        });
      }, 0);
      return;
    }

    setTimeout(() => {
      const geometry = feature.feature.getGeometry();
      this.addToFeatures([feature.feature]);
      this.dispatchEvent("qmolDraw", {
        features: new this.ol.format.GeoJSON().writeFeaturesObject(
          [feature.feature],
          {
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
          },
        ),
        geometry: geometry.clone().transform("EPSG:3857", "EPSG:4326"),
        type: "draw",
      });
    }, 0);
  }

  addToFeatures(features) {
    let hasKinks;
    let hasHoles;
    let isMulti;
    let tooLarge;
    let tooSmall;
    let removeFeatures = [];
    let addFeatures = this.editFeatures ? this.editFeatures : [];

    for (let feature of features) {
      if (
        !this.buttonsGroupActive &&
        (!this.btnDraw.isDisabled ||
          !this.btnDrawPoint.isDisabled ||
          !this.btnDrawLine.isDisabled ||
          !this.btnDrawPolygon.isDisabled) &&
        this.drawBuffer > 0
      ) {
        const buffered = this.bufferFeature(feature);
        feature.setGeometry(buffered.getGeometry());
      }

      if (feature.get("style")) {
        feature.setStyle(feature.get("style"));
      } else {
        feature.setStyle(
          new this.ol.style.Style({
            text: new this.ol.style.Text({
              text: this.drawText,
              font: "bold 12px Calibri,sans-serif",
              stroke: new this.ol.style.Stroke({
                color: [255, 255, 255, 1],
                width: 3,
              }),
              fill: new this.ol.style.Fill({
                color: this.editColor.concat(1),
              }),
              offsetY: -15,
            }),
            stroke: new this.ol.style.Stroke({
              color: this.editColor.concat(1),
              width: 2,
            }),
            fill: new this.ol.style.Fill({
              color: this.editColor.concat(0.2),
            }),
            image: new this.ol.style.Circle({
              stroke: new this.ol.style.Stroke({
                color: this.editColor.concat(1),
                width: 2,
              }),
              fill: new this.ol.style.Fill({
                color: this.editColor.concat(1),
              }),
              radius: 5,
            }),
          }),
        );
      }

      if (this.merge) {
        this.originalFeature = this.edit.getSource().getFeatures()[0].clone();
        this.mergeEdits();
        const newFeature = this.edit.getSource().getFeatures()[0];
        if (
          !this.complex &&
          (this.hasKinks(newFeature) ||
            (!this.btnEdit.isDisabled && this.hasCoincidentPoints(newFeature)))
        ) {
          hasKinks = true;
          this.clearAndAddFeatures(this.edit.getSource(), addFeatures);
        }
        if (!this.holes && this.ringCount(newFeature) > 1) {
          hasHoles = true;
          this.clearAndAddFeatures(this.edit.getSource(), addFeatures);
        }
        if (
          !this.multi &&
          newFeature.getGeometry().getType().indexOf("Multi") !== -1
        ) {
          isMulti = true;
          this.clearAndAddFeatures(this.edit.getSource(), addFeatures);
        }
        if (
          this.ol.sphere.getArea(newFeature.getGeometry()) >
          this.maxPolygonSizeSqMeters
        ) {
          tooLarge = true;
          this.clearAndAddFeatures(this.edit.getSource(), addFeatures);
        }
        if (
          this.ol.sphere.getArea(newFeature.getGeometry()) <
          this.minPolygonSizeSqMeters
        ) {
          tooSmall = true;
          this.clearAndAddFeatures(this.edit.getSource(), addFeatures);
        }
        feature = this.edit.getSource().getFeatures()[0];
      }

      const kinks =
        this.hasKinks(feature) ||
        (!this.btnEdit.isDisabled && this.hasCoincidentPoints(feature));
      const rings = this.ringCount(feature);
      const multis = feature.getGeometry().getType().indexOf("Multi") !== -1;

      if (!this.complex && kinks) hasKinks = true;
      if (!this.holes && rings > 1) hasHoles = true;
      if (!this.multi && multis) isMulti = true;
      removeFeatures.push(feature);

      if (feature) {
        feature.setProperties({
          fid: feature.getProperties().fid
            ? feature.getProperties().fid
            : crypto.randomUUID(),
          fips: this.getFipsForFeature(feature),
          kinks: kinks,
          rings: rings,
        });
      }
    }

    if (hasKinks || hasHoles) {
      this.removeAndAddToEdit(removeFeatures, addFeatures);
      this.showMsgbox(
        `<h2><center>No polygons with ${hasKinks ? "kinks" : "holes"}!</center></h2>`,
      );
      return;
    }
    if (isMulti) {
      this.removeAndAddToEdit(removeFeatures, addFeatures);
      this.showMsgbox(`<h2><center>No multi-part polygons!</center></h2>`);
      return;
    }
    if (tooLarge || tooSmall) {
      this.removeAndAddToEdit(removeFeatures, addFeatures);
      this.showMsgbox(
        `<h2><center>Polygon is too ${tooLarge ? "large" : "small"}!</center></h2>`,
      );
      return;
    }
  }

  removeAndAddToEdit(removeFeatures, addFeatures) {
    if (removeFeatures) {
      for (const feature of removeFeatures) {
        this.edit.getSource().removeFeature(feature);
      }
    }
    if (addFeatures) {
      for (const feature of addFeatures) {
        try {
          this.edit.getSource().addFeature(feature);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  clearAndAddFeatures(source, features) {
    source.clear();
    if (features) {
      for (const feature of features) {
        try {
          source.addFeature(feature);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  showMsgbox(msg, timeout = this.msgboxTimeout) {
    this.msgbox.innerHTML = msg;
    this.msgbox.style.display = "block";
    setTimeout(() => {
      this.msgbox.style.display = "none";
    }, timeout);
  }

  mergeEdits() {
    const merged = this.mergeFeatures(this.edit.getSource());
    if (!merged) {
      this.msgbox.innerHTML = `<h2><center>No more than one polygon!</center></h2>`;
      this.msgbox.style.display = "block";

      setTimeout(() => {
        this.msgbox.style.display = "none";
      }, 1500);
      return;
    }
  }

  bufferFeature(feature, bufferSize, bufferSteps = 8) {
    if (bufferSize === 0) return feature;

    const geojson = new this.ol.format.GeoJSON().writeFeatureObject(feature, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });

    const buffered = this.turf.buffer(
      geojson,
      !bufferSize ? this.drawBuffer : bufferSize,
      {
        units: "feet",
        steps: bufferSteps,
      },
    );

    return new this.ol.format.GeoJSON().readFeature(buffered, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });
  }

  hasKinks(feature) {
    if (feature && feature.getGeometry().getType() !== "Point") {
      return this.turf.kinks(
        new this.ol.format.GeoJSON().writeFeatureObject(feature, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        }),
      ).features.length > 0
        ? true
        : false;
    }
    return false;
  }

  hasCoincidentPoints(feature) {
    if (feature && feature.getGeometry().getType() !== "Point") {
      var shape = new this.ol.format.GeoJSON().writeFeatureObject(feature, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });
      var lines = this.turf.lineSegment(shape);
      var points = [];
      for (var line of lines.features) {
        for (var coordinate of line.geometry.coordinates) {
          points.push(this.turf.point(coordinate));
        }
      }
      for (line of lines.features) {
        var linePointStart = this.turf.point(line.geometry.coordinates[0]);
        var linePointEnd = this.turf.point(
          line.geometry.coordinates[line.geometry.coordinates.length - 1],
        );
        for (var point of points) {
          if (
            this.turf.nearestPointOnLine(line, point, { units: "meters" })
              .properties.dist <= 0.1 &&
            !this.turf.booleanEqual(linePointStart, point) &&
            !this.turf.booleanEqual(linePointEnd, point)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  ringCount(feature) {
    return feature && feature.getGeometry().getType() === "Polygon"
      ? feature.getGeometry().getLinearRingCount()
      : 0;
  }

  startEdit() {
    if (!this.canDrawOnLayer()) {
      this.showMsgbox(
        `<h2><p>Cannot Edit on this layer!</p><p>Try one of these layers:</p>${this.drawableLayers()}</h2>`,
        3000,
      );
      return;
    }

    this.removeInteractions();
    if (!this.buttonsGroupActive) {
      let toggled = this.btnEdit.classList.contains("ol-toggled");
      this.toggleButtonsOn();
      this.dispatchEvent("qmolBtnEditClick", { type: "off" });
      this.map.getTargetElement().style.cursor = "default";
      if (toggled) {
        return;
      }
    }
    this.toggleButtonsOff(this.btnEdit);
    this.dispatchEvent("qmolBtnEditClick", { type: "on" });
    this.map.getTargetElement().style.cursor = this.drawCursor;

    this.select = new this.ol.interaction.Select({
      layers: [this.edit],
      style: (feature) => this.measureStyle(feature),
    });

    this.modify = new this.ol.interaction.Modify({
      source: this.edit.getSource(),
    });

    this.snap = new this.ol.interaction.Snap({
      source: this.edit.getSource(),
    });

    this.map.addInteraction(this.select);
    this.map.addInteraction(this.modify);
    this.map.addInteraction(this.snap);
    this.modify.on("modifystart", (feature) => this.onModifyStart(feature));
    this.modify.on("modifyend", (feature) => this.onModifyEnd(feature));
  }
  onModifyStart(feature) {
    this.editCountBeforeDraw = 0;
    const features = feature.features.getArray();
    this.editFeatures = [];
    for (const feat of features) {
      this.editFeatures.push(feat.clone());
    }
  }
  onModifyEnd(feature) {
    const features = feature.features.getArray();
    this.addToFeatures(features);
    this.dispatchEvent("qmolModify", {
      features: new this.ol.format.GeoJSON().writeFeaturesObject(features, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }),
      type: "modify",
    });
  }

  startDelete() {
    this.removeInteractions();
    if (!this.buttonsGroupActive) {
      let toggled = this.btnDelete.classList.contains("ol-toggled");
      this.toggleButtonsOn();
      this.dispatchEvent("qmolBtnDeleteClick", { type: "off" });
      this.map.getTargetElement().style.cursor = "default";
      if (toggled) {
        return;
      }
    }
    this.toggleButtonsOff(this.btnDelete);
    this.dispatchEvent("qmolBtnDeleteClick", { type: "on" });
    this.map.getTargetElement().style.cursor = this.drawCursor;

    this.select = new this.ol.interaction.Select({
      layers: [this.edit],
      style: (feature) => this.measureStyle(feature),
    });

    this.map.addInteraction(this.select);
    this.select.on("select", (selected) => this.onEndDelete(selected));
  }
  onEndDelete(selected) {
    let features = [];
    for (const feature of selected.selected) {
      features.push(feature.clone());
      this.edit.getSource().removeFeature(feature);
    }
    this.dispatchEvent("qmolDelete", {
      features: new this.ol.format.GeoJSON().writeFeaturesObject(features, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }),
      type: "delete",
    });
  }

  startIdentify() {
    this.removeInteractions();
    if (!this.buttonsGroupActive) {
      let toggled = this.btnIdentify.classList.contains("ol-toggled");
      this.toggleButtonsOn();
      this.dispatchEvent("qmolBtnIdentifyClick", { type: "off" });
      this.map.getTargetElement().style.cursor = "default";
      if (toggled) {
        return;
      }
    }
    this.toggleButtonsOff(this.btnIdentify);
    this.dispatchEvent("qmolBtnIdentifyClick", { type: "on" });
    this.map.getTargetElement().style.cursor = this.drawCursor;
  }

  startIntersection() {
    this.removeInteractions();
    if (!this.buttonsGroupActive) {
      let toggled = this.btnIntersection.classList.contains("ol-toggled");
      this.toggleButtonsOn();
      this.dispatchEvent("qmolBtnIntersectionClick", { type: "off" });
      this.map.getTargetElement().style.cursor = "default";
      if (toggled) {
        return;
      }
    }
    this.toggleButtonsOff(this.btnIntersection);
    this.dispatchEvent("qmolBtnIntersectionClick", { type: "on" });
    this.map.getTargetElement().style.cursor = this.drawCursor;
  }

  startFlag() {
    this.removeInteractions();
    if (!this.buttonsGroupActive) {
      let toggled = this.btnFlag.classList.contains("ol-toggled");
      this.toggleButtonsOn();
      this.dispatchEvent("qmolBtnFlagClick", { type: "off" });
      this.map.getTargetElement().style.cursor = "default";
      if (toggled) {
        return;
      }
    }
    this.toggleButtonsOff(this.btnFlag);
    this.dispatchEvent("qmolBtnFlagClick", { type: "on" });
    this.map.getTargetElement().style.cursor = this.drawCursor;
  }

  startBlock() {
    if (!this.canDrawOnLayer()) {
      this.showMsgbox(
        `<h2><p>Cannot Block on this layer!</p><p>Try one of these layers:</p>${this.drawableLayers()}</h2>`,
        3000,
      );
      return;
    }

    this.removeInteractions();
    if (!this.buttonsGroupActive) {
      let toggled = this.btnBlock.classList.contains("ol-toggled");
      this.toggleButtonsOn();
      this.dispatchEvent("qmolBtnBlockClick", { type: "off" });
      this.map.getTargetElement().style.cursor = "default";
      if (toggled) {
        return;
      }
    }
    this.toggleButtonsOff(this.btnBlock);
    this.dispatchEvent("qmolBtnBlockClick", { type: "on" });
    this.map.getTargetElement().style.cursor = this.drawCursor;
  }

  startWeather() {
    this.removeInteractions();
    if (!this.buttonsGroupActive) {
      let toggled = this.btnWeather.classList.contains("ol-toggled");
      this.toggleButtonsOn();
      this.dispatchEvent("qmolBtnWeatherClick", { type: "off" });
      this.map.getTargetElement().style.cursor = "default";
      if (toggled) {
        return;
      }
    }
    this.toggleButtonsOff(this.btnWeather);
    this.dispatchEvent("qmolBtnWeatherClick", { type: "on" });
    this.map.getTargetElement().style.cursor = this.drawCursor;
  }

  startBullseye() {
    this.removeInteractions();
    if (!this.buttonsGroupActive) {
      let toggled = this.btnBullseye.classList.contains("ol-toggled");
      this.toggleButtonsOn();
      this.dispatchEvent("qmolBtnBullseyeClick", { type: "off" });
      this.map.getTargetElement().style.cursor = "default";
      if (toggled) {
        return;
      }
    }
    this.toggleButtonsOff(this.btnBullseye);
    this.dispatchEvent("qmolBtnBullseyeClick", { type: "on" });
    this.map.getTargetElement().style.cursor = this.drawCursor;
  }

  getFromBullseye(property, value) {
    let features = [];
    for (const feature of this.bullseye.getSource().getFeatures()) {
      if (!property || feature.get(property) === value) {
        features.push(feature);
      }
    }
    return new this.ol.format.GeoJSON().writeFeatures(features, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });
  }

  clearBullseye() {
    this.bullseye.getSource().clear();
  }

  onSingleClick(evt) {
    if (
      !this.buttonsGroupActive &&
      (!this.btnDraw.isDisabled ||
        !this.btnDrawPoint.isDisabled ||
        !this.btnDrawLine.isDisabled ||
        !this.btnDrawPolygon.isDisabled ||
        !this.btnEdit.isDisabled ||
        !this.btnDelete.isDisabled ||
        !this.btnMeasure.isDisabled)
    ) {
      return;
    }

    const coordinate = this.ol.proj.toLonLat(evt.coordinate);
    const latlong = document.getElementsByClassName("ol-latlong")[0];
    latlong.innerHTML =
      (Math.round(coordinate[1] * 100000) / 100000).toFixed(4) +
      ", " +
      (Math.round(coordinate[0] * 100000) / 100000).toFixed(4);

    if (!this.buttonsGroupActive && !this.btnWeather.isDisabled) {
      this.getWeather(evt.coordinate, coordinate);
      return;
    }
    if (!this.buttonsGroupActive && !this.btnBullseye.isDisabled) {
      this.drawBullseye(evt.coordinate);
      return;
    }

    let clickFor = "click";
    if (!this.buttonsGroupActive && !this.btnIdentify.isDisabled)
      clickFor = "identify";
    if (!this.buttonsGroupActive && !this.btnIntersection.isDisabled)
      clickFor = "intersection";
    if (!this.buttonsGroupActive && !this.btnFlag.isDisabled) clickFor = "flag";
    if (!this.buttonsGroupActive && !this.btnBlock.isDisabled)
      clickFor = "block";

    const features = [];
    this.map.forEachFeatureAtPixel(
      evt.pixel,
      function (feature, layer) {
        if (layer) {
          feature.setProperties({ layer: layer.get("name") });
        }
        features.push(feature);
      },
      {
        hitTolerance: 10,
      },
    );

    const stateCounty = this.getCountyForLongLat([
      coordinate[0],
      coordinate[1],
    ]);

    this.dispatchEvent("qmolSingleClick", {
      stateCounty: stateCounty,
      coordinate: coordinate,
      extent: this.ol.proj.transformExtent(
        this.map.getView().calculateExtent(this.map.getSize()),
        "EPSG:3857",
        "EPSG:4326",
      ),
      features: new this.ol.format.GeoJSON().writeFeaturesObject(features, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }),
      type: clickFor,
    });
  }

  dispatchEvent(eventName, detail) {
    document.dispatchEvent(new CustomEvent(eventName, { detail }));
  }

  removeInteractions() {
    this.map.removeInteraction(this.draw);
    this.draw = undefined;
    this.map.removeInteraction(this.modify);
    this.modify = undefined;
    this.map.removeInteraction(this.snap);
    this.snap = undefined;
    this.map.removeInteraction(this.select);
    this.select = undefined;
  }

  measureStyle(feature) {
    let styles = [
      new this.ol.style.Style({
        fill: new this.ol.style.Fill({
          color: "rgba(255,255,255,0.5)",
        }),
        stroke: new this.ol.style.Stroke({
          color: "white",
          width: 6,
        }),
      }),
      new this.ol.style.Style({
        stroke: new this.ol.style.Stroke({
          color: "black",
          lineDash: this.measureLineDash,
          width: 3,
        }),
      }),
      new this.ol.style.Style({
        image: new this.ol.style.Circle({
          radius: 5,
          fill: new this.ol.style.Fill({
            color: "white",
          }),
          stroke: new this.ol.style.Stroke({
            color: "black",
            width: 2,
          }),
        }),
      }),
    ];

    const geometry = feature.getGeometry();
    if (geometry.getType() === "Point") return styles;

    if (geometry.getType() === "LineString") {
      let line = geometry;
      this.drawMeasureSegments(line, styles);
    } else if (geometry.getType() === "MultiLineString") {
      for (let i = 0; i < geometry.getCoordinates().length; i++) {
        let line = new this.ol.geom.LineString(geometry.getCoordinates()[i]);
        this.drawMeasureSegments(line, styles);
      }
    } else if (geometry.getType() === "Polygon") {
      let line = new this.ol.geom.LineString(geometry.getCoordinates()[0]);
      this.drawMeasureSegments(line, styles);
    }

    if (
      geometry.getType() === "LineString" ||
      geometry.getType() === "MultiLineString"
    ) {
      const total = new this.ol.style.Style({
        text: new this.ol.style.Text({
          text: "",
          font: this.measureStyleLineFont,
          stroke: new this.ol.style.Stroke({
            color: "white",
            width: this.measureStyleFillWidth,
          }),
          fill: new this.ol.style.Fill({
            color: "red",
          }),
          offsetY: -15,
        }),
        image: new this.ol.style.Circle({
          radius: 5,
          stroke: new this.ol.style.Stroke({
            color: "black",
            width: 2,
          }),
          fill: new this.ol.style.Fill({
            color: "white",
          }),
        }),
      });
      total.setGeometry(new this.ol.geom.Point(geometry.getLastCoordinate()));
      total
        .getText()
        .setText(
          `${this.getDirection(geometry)}${this.formatLength(geometry)}`,
        );
      styles.push(total);
    } else if (
      geometry.getType() === "Polygon" &&
      geometry.getCoordinates()[0].length > 3
    ) {
      const total = new this.ol.style.Style({
        text: new this.ol.style.Text({
          text: "",
          font: this.measureStylePolygonFont,
          stroke: new this.ol.style.Stroke({
            color: "white",
            width: this.measureStyleFillWidth,
          }),
          fill: new this.ol.style.Fill({
            color: "blue",
          }),
          offsetY: -15,
        }),
      });
      total.setGeometry(geometry.getInteriorPoint());
      total.getText().setText(this.formatArea(geometry));
      styles.push(total);
    }

    return styles;
  }

  drawMeasureSegments(line, styles) {
    let segmentStyles = [];
    let count = 0;

    line.forEachSegment((a, b) => {
      const segment = new this.ol.geom.LineString([a, b]);
      let label = `${this.getDirection(segment)} ${this.formatLength(segment)}`;
      if (segmentStyles.length - 1 < count) {
        segmentStyles.push(
          new this.ol.style.Style({
            text: new this.ol.style.Text({
              text: "",
              font: this.measureStylePointFont,
              stroke: new this.ol.style.Stroke({
                color: [255, 255, 255, 1],
                width: this.measureStyleFillWidth,
              }),
              fill: new this.ol.style.Fill({
                color: [0, 0, 0, 1],
              }),
              offsetY: 0,
            }),
          }),
        );
      }
      const segmentPoint = new this.ol.geom.Point(segment.getCoordinateAt(0.5));
      segmentStyles[count].setGeometry(segmentPoint);
      segmentStyles[count].getText().setText(label);
      styles.push(segmentStyles[count]);
      count++;
      segmentStyles.push(
        new this.ol.style.Style({
          image: new this.ol.style.Circle({
            radius: 5,
            stroke: new this.ol.style.Stroke({
              color: "black",
              width: 2,
            }),
            fill: new this.ol.style.Fill({
              color: "white",
            }),
          }),
        }),
      );
      segmentStyles[count].setGeometry(
        new this.ol.geom.Point(segment.getFirstCoordinate()),
      );
      styles.push(segmentStyles[count]);
      count++;
    });
  }

  getDirection(geometry) {
    if (!this.measureDirection) return "";

    if (geometry.getType() === "LineString") {
      const coords = geometry.getCoordinates();
      if (coords.length > 1) {
        const point1 = this.ol.proj.toLonLat(coords[coords.length - 2]);
        const point2 = this.ol.proj.toLonLat(coords[coords.length - 1]);
        var dx = point2[0] - point1[0];
        var dy = point2[1] - point1[1];
        var angle = Math.atan2(dy, dx);
        var degrees = (angle * 180) / Math.PI;
        if (degrees >= 0 && degrees < 22.5) {
          return "E ";
        }
        if (degrees >= 22.5 && degrees < 67.5) {
          return "NE ";
        }
        if (degrees >= 67.5 && degrees < 112.5) {
          return "N ";
        }
        if (degrees >= 112.5 && degrees < 157.5) {
          return "NW ";
        }
        if (degrees >= 157.5 && degrees <= 180) {
          return "W ";
        }
        if (degrees < 0 && degrees > -22.5) {
          return "E ";
        }
        if (degrees <= -22.5 && degrees > -67.5) {
          return "SE ";
        }
        if (degrees <= -67.5 && degrees > -112.5) {
          return "S ";
        }
        if (degrees <= -112.5 && degrees > -157.5) {
          return "SW ";
        }
        if (degrees <= -157.5 && degrees >= -180) {
          return "W ";
        }
      }
    }

    return "Total ";
  }

  formatLength(line) {
    const length = this.ol.sphere.getLength(line) * 3.28084;
    const feet = Math.round(length).toLocaleString("en-US") + " " + "ft";
    let yards = Math.round(length / 3).toLocaleString("en-US") + " " + "yd";
    if (yards === "0 yd") yards = "<1 yd";
    let miles =
      (Math.round((length / 5280) * 100) / 100).toLocaleString("en-US") +
      " " +
      "mi";
    if (miles === "0 mi") miles = "<0.01 mi";
    let meters =
      Math.round(length / 3.28084).toLocaleString("en-US") + " " + "m";
    if (meters === "0 m") meters = "<1 m";
    let kilometers =
      (Math.round((length / 3280.84) * 100) / 100).toLocaleString("en-US") +
      " " +
      "km";
    if (kilometers === "0 km") kilometers = "<0.01 km";
    return `${this.measureLengthFeet ? feet + "\n" : ""}${this.measureLengthYards ? yards + "\n" : ""}${this.measureLengthMiles ? miles + "\n" : ""}${this.measureLengthMeters ? meters + "\n" : ""}${this.measureLengthKilometers ? kilometers : ""}`;
  }

  formatArea = function (polygon) {
    const area = this.ol.sphere.getArea(polygon) * 10.7639;
    const feet = Math.round(area).toLocaleString("en-US") + " ft\xB2";
    let yards = Math.round(area / 9).toLocaleString("en-US") + " yd\xB2";
    if (yards === "0 yd\xB2") yards = "<1 yd\xB2";
    let acres =
      (Math.round((area / 43560) * 100) / 100).toLocaleString("en-US") + " ac";
    if (acres === "0 ac") acres = "<0.01 ac";
    let miles =
      (Math.round((area / 27878400) * 100) / 100).toLocaleString("en-US") +
      " mi\xB2";
    if (miles === "0 mi\xB2") miles = "<0.01 mi\xB2";
    let meters = Math.round(area / 10.7639).toLocaleString("en-US") + " m\xB2";
    if (meters === "0 m\xB2") meters = "<1 m\xB2";
    let kilometers =
      (Math.round((area / 10763915.05) * 100) / 100).toLocaleString("en-US") +
      " km\xB2";
    if (kilometers === "0 km\xB2") kilometers = "<0.01 km\xB2";
    return `${this.measureAreaSqFeet ? feet + "\n" : ""}${this.measureAreaSqYards ? yards + "\n" : ""}${this.measureAreaAcres ? acres + "\n" : ""}${this.measureAreaSqMiles ? miles + "\n" : ""}${this.measureAreaSqMeters ? meters + "\n" : ""}${this.measureAreaSqKilometers ? kilometers : ""}`;
  };

  getFipsForFeature(feature) {
    let fips = [];
    let featureExtent;
    if (feature.getGeometry().getType() !== "Point") {
      featureExtent = new this.ol.geom.Polygon(
        feature.getGeometry().getCoordinates(),
      ).getExtent();
    } else {
      const coords = feature.getGeometry().getCoordinates();
      featureExtent = [
        coords[0] - 0.000001,
        coords[1] - 0.000001,
        coords[0] + 0.000001,
        coords[1] + 0.000001,
      ];
    }
    for (const county of this.counties) {
      const countyPolygon = new this.ol.geom.Polygon(
        county.getGeometry().getCoordinates(),
      );
      if (countyPolygon.intersectsExtent(featureExtent)) {
        fips.push(county.getProperties().STCO);
      }
    }
    return fips;
  }

  addToEdit(features) {
    if (features.features.length === 0) return;
    this.edit.getSource().addFeatures(
      new this.ol.format.GeoJSON().readFeatures(features, {
        featureProjection: "EPSG:3857",
      }),
    );
  }

  getFromEdit(property, value) {
    let features = [];
    for (const feature of this.edit.getSource().getFeatures()) {
      if (!property || feature.get(property) === value) {
        features.push(feature);
      }
    }
    return new this.ol.format.GeoJSON().writeFeatures(features, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });
  }

  removeFromEdit(property, value) {
    for (const feature of this.edit.getSource().getFeatures()) {
      if (feature.get(property) === value) {
        this.edit.getSource().removeFeature(feature);
      }
    }
  }

  zoomToEdit() {
    if (this.edit.getSource().getFeatures().length === 0) return;
    this.map.getView().fit(this.edit.getSource().getExtent(), {
      padding: this.padding,
    });
    this.getNewtin();
    if (this.map.getView().getZoom() > this.maxzoom) {
      this.map.getView().setZoom(this.maxzoom);
    }
  }

  clearEdit() {
    this.edit.getSource().clear();
  }

  async getWeather(evtCoordinate, coordinate) {
    /* prettier-ignore */
    let retry = 0;
    const response = await fetch(
      `https://api.weather.gov/points/${(Math.round(coordinate[1] * 100000) / 100000).toFixed(4)},${(Math.round(coordinate[0] * 100000) / 100000).toFixed(4)}`,
    );
    const grid = await response.json();
    function getTempColor(period) {
      //const color = period.isDaytime ? '#FF0000' : '#0000FF';
      //return `<span style="color:${color}">${period.temperature}&deg;</span>`;
      return `${period.temperature}&deg;`;
    }
    if (grid.properties) {
      do {
        const response = await fetch(`${grid.properties.forecast}`);
        const forecast = await response.json();
        if (forecast && forecast.properties) {
          this.divPopup.innerHTML = `
                                <div>
                                    <table cellspacing="0" cellpadding="0" class="weather-table">
                                        <tr><td>${forecast.properties.periods[0].name} ${getTempColor(forecast.properties.periods[0])}</td><td>${forecast.properties.periods[1].name} ${getTempColor(forecast.properties.periods[1])}</td><td>${forecast.properties.periods[2].name} ${getTempColor(forecast.properties.periods[2])}</td></tr>
                                        <tr><td><img src="${forecast.properties.periods[0].icon}"></td><td><img src="${forecast.properties.periods[1].icon}"></td><td><img src="${forecast.properties.periods[2].icon}"></td></tr>
                                        <tr><td>${forecast.properties.periods[0].shortForecast}</td><td>${forecast.properties.periods[1].shortForecast}</td><td>${forecast.properties.periods[2].shortForecast}</td></tr>
                                    </table>
                                </div>
                            `;
          this.divPopupOverlay.setPosition(evtCoordinate);
          this.divPopup.style.display = "block";
          return;
        }
        await new Promise((r) => setTimeout(r, 100));
      } while (retry++ < 5);
    }
  }

  drawBullseye(coordinate) {
    const point = new this.ol.geom.Point(coordinate);

    let feature = new this.ol.Feature({
      geometry: point,
    });

    this.drawBullseyeRing(feature, 3);
    for (var i = 100; i <= 1000; i += 100) {
      this.drawBullseyeRing(feature, i);
    }
    this.drawBullseyeRing(feature, 1320);
  }
  drawBullseyeRing(feature, bufferSize) {
    let ring = this.bufferFeature(feature, bufferSize, 16);
    let color;
    if (bufferSize === 500 || bufferSize === 1000) {
      color = [255, 0, 0, 1];
    } else {
      color = [0, 0, 0, 1];
    }

    ring.setStyle(
      new this.ol.style.Style({
        stroke: new this.ol.style.Stroke({
          color: color,
          width: 2,
        }),
      }),
    );
    this.bullseye.getSource().addFeature(ring);
  }

  mergeFeatures(source) {
    for (const feature of source.getFeatures()) {
      if (feature.getGeometry().getType() !== "Polygon") {
        this.bufferFeature(feature, this.drawBuffer);
      }
    }

    let merged = true;
    if (source.getFeatures().length > 1) {
      const format = new this.ol.format.GeoJSON();
      for (var i = 0; i < source.getFeatures().length; i++) {
        const first = source.getFeatures()[i];
        var firstClone = source.getFeatures()[i].clone();
        for (var j = source.getFeatures().length - 1; j >= 1; j--) {
          const second = source.getFeatures()[j];
          var secondClone = source.getFeatures()[j].clone();
          const firstGeoJson = format.writeFeatureObject(firstClone);
          const secondGeoJson = format.writeFeatureObject(secondClone);
          if (this.turf.booleanIntersects(firstGeoJson, secondGeoJson)) {
            var union = this.turf.union(firstGeoJson, secondGeoJson);
            union = format.readFeature(union);
            first.setGeometry(union.getGeometry());
            source.removeFeature(second);
          } else {
            merged = false;
          }
        }
      }
    }
    return merged;
  }

  substractFeature(subtractor) {
    try {
      for (var i = 0; i < this.edit.getSource().getFeatures().length; i++) {
        const feature = this.edit.getSource().getFeatures()[i];
        if (feature !== subtractor) {
          const format = new this.ol.format.GeoJSON();
          const featureGeoJson = format.writeFeatureObject(feature);
          const subtractorGeoJson = format.writeFeatureObject(subtractor);
          if (this.turf.booleanIntersects(featureGeoJson, subtractorGeoJson)) {
            var difference = this.turf.difference(
              featureGeoJson,
              subtractorGeoJson,
            );
            difference = format.readFeature(difference);
            feature.setGeometry(difference.getGeometry());
          }
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  addToImage(
    imageUrl,
    extentLonMinLatMinLonMaxLatMax,
    projection = "EPSG:4326",
  ) {
    this.image.setExtent(
      this.ol.proj.transformExtent(
        extentLonMinLatMinLonMaxLatMax,
        "EPSG:4326",
        "EPSG:3857",
      ),
    );
    this.image.setSource(
      new this.ol.source.ImageStatic({
        url: imageUrl,
        imageExtent: extentLonMinLatMinLonMaxLatMax,
        projection: projection,
        crossOrigin: "anonymous",
      }),
    );
  }

  zoomToImage() {
    this.map.getView().fit(this.image.getExtent());
  }

  clearImage() {
    this.image.setSource(null);
  }

  getMapExtent() {
    return this.ol.proj.transformExtent(
      this.map.getView().calculateExtent(this.map.getSize()),
      "EPSG:3857",
      "EPSG:4326",
    );
  }

  getMapExtentObj() {
    const extent = this.ol.proj.transformExtent(
      this.map.getView().calculateExtent(this.map.getSize()),
      "EPSG:3857",
      "EPSG:4326",
    );
    return {
      left: extent[0],
      bottom: extent[1],
      right: extent[2],
      top: extent[3],
    };
  }

  getMapZoom() {
    return this.map.getView().getZoom();
  }

  showCounty(st, cnty) {
    this.removeFromShow("group", "counties");
    for (const county of this.counties) {
      if (
        county.get("STATE") === st &&
        (!cnty || county.get("COUNTY").toUpperCase() === cnty.toUpperCase())
      ) {
        const feature = county.clone();
        feature.setProperties({
          group: "counties",
        });
        feature.setStyle(
          new this.ol.style.Style({
            text: new this.ol.style.Text({
              text: feature.get("COUNTY"),
              font: "bold 14px Calibri,sans-serif",
              stroke: new this.ol.style.Stroke({ color: "white", width: 3 }),
              fill: new this.ol.style.Fill({ color: "black" }),
            }),
            stroke: new this.ol.style.Stroke({ color: "gray", width: 2 }),
          }),
        );
        this.show.getSource().addFeature(feature);
      }
    }
  }

  onRotationReset() {
    this.compassDegrees = 0;
    document.getElementsByClassName("ol-compass")[1].style.transform =
      `rotate(0rad)`;
    this.map.getView().setRotation(0);
  }

  onRotationIncrement() {
    this.compassDegrees += 15;
    const rad = (this.compassDegrees * Math.PI) / 180;
    document.getElementsByClassName("ol-compass")[1].style.transform =
      `rotate(${rad}rad)`;
    this.map.getView().setRotation(rad);
  }

  startHelp() {
    this.removeInteractions();
    if (!this.buttonsGroupActive) {
      let toggled = this.btnHelp.classList.contains("ol-toggled");
      this.toggleButtonsOn();
      this.dispatchEvent("qmolBtnHelpClick", { type: "off" });
      if (toggled) {
        return;
      }
    }
    this.toggleButtonsOff(this.btnHelp);
    this.dispatchEvent("qmolBtnHelpClick", { type: "on" });
    this.showHelpMsgBox();
  }

  showHelpMsgBox() {
    this.showMsgbox(
      `<h3>Mapping Shortcuts</h3><ul><li>Double-Click: Zoom in</li><li>Shift-Drag: Zoom in to selection</li><li>Shift-Double-Click: Zoom out</li>${this.handleDrawDelete ? "<li>Delete: Remove last point while drawing</li>" : ""}${this.handleAddDrawPoint ? "<li>Click scale or Insert: add point by parameters while drawing</li>" : ""}${this.handleAddDrawFinish ? "<li>Enter: finish drawing</li>" : ""}<li>Alt-Click: Remove point while editing</li>${this.enableRotation ? "<li>Alt-Shift-Drag: Rotate map</li>" : ""}${this.handleAddLatLongPoint ? "<li>Click Lat/long: Zoom/Add Lat/long point</li>" : ""}${this.enableCompass ? "<li>Click Compass: Toggle compass on/off</li>" : ""}${this.additionalHelp}</ul>`,
      360000,
    );
  }

  onShowAddDrawPoint() {
    if (!this.draw) return;
    this.addPointMsgbox.style.display = "block";
    document.getElementsByClassName("ol-txtAddPointDistance")[0].disabled =
      false;
    document.getElementsByClassName("ol-selAddPointDistanceUnits")[0].disabled =
      false;
    document.getElementsByClassName("ol-selAddPointDirection")[0].disabled =
      false;
    document.getElementsByClassName("ol-txtAddPointDistance")[0].focus();
  }

  onAddDrawPoint() {
    this.map.getTargetElement().focus();
    this.addPointMsgbox.style.display = "none";

    if (!this.draw) return;
    let point;
    if (this.draw.Bv) {
      point = this.draw.Bv;
    } else {
      point = this.draw.finishCoordinate_;
    }
    if (!point) return;
    point = this.ol.proj.transform(point, "EPSG:3857", "EPSG:4326");

    if (
      document
        .getElementsByClassName("ol-txtAddPointDistance")[0]
        .value.trim() === ""
    )
      return;
    const distance = parseFloat(
      document.getElementsByClassName("ol-txtAddPointDistance")[0].value,
    );
    if (isNaN(distance)) return;

    const units = document.getElementsByClassName(
      "ol-selAddPointDistanceUnits",
    )[0].value;
    const direction = document.getElementsByClassName(
      "ol-selAddPointDirection",
    )[0].value;

    let moveAmount;
    switch (units) {
      case "feet":
        moveAmount = distance / 5280;
        break;
      case "yards":
        moveAmount = distance / 1760;
        break;
      case "miles":
        moveAmount = distance;
        break;
      case "meters":
        moveAmount = distance / 1609.34;
        break;
    }

    switch (direction) {
      case "North":
        point[1] += moveAmount / (68.703 + (point[1] * 0.695) / 90);
        break;
      case "South":
        point[1] -= moveAmount / (68.703 + (point[1] * 0.695) / 90);
        break;
      case "East":
        point[0] +=
          moveAmount / (Math.cos((point[1] * Math.PI) / 180) * 69.172);
        break;
      case "West":
        point[0] -=
          moveAmount / (Math.cos((point[1] * Math.PI) / 180) * 69.172);
        break;
      case "North East":
        point[1] +=
          (moveAmount * 0.707107) / (68.703 + (point[1] * 0.695) / 90);
        point[0] +=
          (moveAmount * 0.707107) /
          (Math.cos((point[1] * Math.PI) / 180) * 69.172);
        break;
      case "South East":
        point[1] -=
          (moveAmount * 0.707107) / (68.703 + (point[1] * 0.695) / 90);
        point[0] +=
          (moveAmount * 0.707107) /
          (Math.cos((point[1] * Math.PI) / 180) * 69.172);
        break;
      case "North West":
        point[1] +=
          (moveAmount * 0.707107) / (68.703 + (point[1] * 0.695) / 90);
        point[0] -=
          (moveAmount * 0.707107) /
          (Math.cos((point[1] * Math.PI) / 180) * 69.172);
        break;
      case "South West":
        point[1] -=
          (moveAmount * 0.707107) / (68.703 + (point[1] * 0.695) / 90);
        point[0] -=
          (moveAmount * 0.707107) /
          (Math.cos((point[1] * Math.PI) / 180) * 69.172);
        break;
    }
    const coord = this.ol.proj.transform(point, "EPSG:4326", "EPSG:3857");
    if (isNaN(coord[0]) || isNaN(coord[1])) {
      this.showMsgbox("<h2><center>Invalid coordinate</center></h2>");
      return;
    }
    this.draw.appendCoordinates([coord]);
  }

  onExitDrawPoint() {
    this.map.getTargetElement().focus();
    this.addPointMsgbox.style.display = "none";
  }

  onShowAddLatLongPoint() {
    this.addLatLongPointMsgBox.style.display = "block";
    document.getElementsByClassName("ol-txtAddLatLongPointLat")[0].disabled =
      false;
    document.getElementsByClassName("ol-txtAddLatLongPointLon")[0].disabled =
      false;
    document.getElementsByClassName("ol-txtAddLatLongPointLat")[0].focus();
  }

  onAddLatLongPoint(add) {
    this.map.getTargetElement().focus();
    this.addLatLongPointMsgBox.style.display = "none";

    // if add is undefined then clear all lat long points
    if (add === undefined) {
      this.removeFromShow("name", "ol-addlatlong");
      return;
    }

    if (
      document
        .getElementsByClassName("ol-txtAddLatLongPointLat")[0]
        .value.trim() === ""
    )
      return;
    if (
      document
        .getElementsByClassName("ol-txtAddLatLongPointLon")[0]
        .value.trim() === ""
    )
      return;

    const lat = parseFloat(
      document.getElementsByClassName("ol-txtAddLatLongPointLat")[0].value,
    );
    const lon = parseFloat(
      document.getElementsByClassName("ol-txtAddLatLongPointLon")[0].value,
    );
    if (isNaN(lat) || isNaN(lon)) return;
    if (lat < -90 || lat > 90 || lon < -180 || lon > 180) return;

    const coord = this.ol.proj.transform([lon, lat], "EPSG:4326", "EPSG:3857");
    this.map.getView().setCenter(coord);
    this.map.getView().setZoom(this.geolocationZoomLevel);

    if (add) {
      const point = new this.ol.geom.Point(coord);
      let label = `${lat.toFixed(4)}, ${lon.toFixed(4)}`;
      let feature = new this.ol.Feature({
        name: "ol-addlatlong",
        style: new this.ol.style.Style({
          text: new this.ol.style.Text({
            text: label,
            overflow: true,
            font: "bold 12px Calibri,sans-serif",
            stroke: new this.ol.style.Stroke({
              color: [255, 255, 255, 1],
              width: 5,
            }),
            fill: new this.ol.style.Fill({ color: [0, 0, 0, 1] }),
            offsetY: -15,
          }),
          image: new this.ol.style.Circle({
            stroke: new this.ol.style.Stroke({ color: [0, 0, 0, 1], width: 2 }),
            fill: new this.ol.style.Fill({ color: [255, 255, 0, 1] }),
            radius: 7,
            declutterMode: true,
          }),
        }),
        geometry: point,
      });
      this.show.getSource().addFeature(feature);
    }
  }

  onExitLatLongPoint() {
    this.map.getTargetElement().focus();
    this.addLatLongPointMsgBox.style.display = "none";
  }

  async zoomToGeolocation(zoomLevel = this.geolocationZoomLevel) {
    try {
      const position = await this.getGeolocation();
      this.map
        .getView()
        .setCenter(
          this.ol.proj.fromLonLat([
            position.coords.longitude,
            position.coords.latitude,
          ]),
        );
      if (zoomLevel !== -1) this.map.getView().setZoom(zoomLevel);
      this.dispatchEvent("qmolBtnGeolocationClick", {
        type: "click",
        position,
      });
      return position;
    } catch (error) {
      this.showMsgbox(`<h2><center>${error.message}</center></h2>`);
    }
  }

  async getGeolocation() {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.dispatchEvent("qmolGeolocation", {
              type: "geolocation",
              position,
            });
            resolve(position);
          },
          (error) => {
            reject(error);
          },
        );
      });
    } else {
      this.showMsgbox(
        `<h2><center>Geolocation is not supported by this browser</center></h2>`,
      );
    }
  }
}

if (typeof module === "object") {
  module.exports = {
    quickMap,
  };
}
