<template>
  <v-footer class="pr-0 pt-0 pb-0" absolute color="white">
    <v-col v-if="!isMini" class="ma-auto mb-0 pb-0" cols="12">
      <!-- TODO Hide Dark Mode Toggle switch on nav bar in mdAndUp-->
      <!-- <v-row justify="center" no-gutters class="pa-2 flex-grow-0 text-center">
        <v-col cols="12">
          <v-btn-toggle v-model="mode" color="white">
            <v-btn
              value="light"
              :color="mode == 'light' ? 'primary' : null"
              class="text-none"
              small
              bottom
            >
              <v-icon :color="mode == 'light' ? 'white' : 'grey'" size="15"
                >mdi-white-balance-sunny</v-icon
              >
              Light
            </v-btn>
            <v-btn
              value="dark"
              small
              class="text-none"
              :color="mode == 'dark' ? 'primary' : null"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                :fill="mode == 'dark' ? 'white' : 'grey'"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.0275 4.1325C6.8925 4.6125 6.825 5.115 6.825 5.625C6.825 8.685 9.315 11.175 12.375 11.175C12.885 11.175 13.3875 11.1075 13.8675 10.9725C13.0875 12.8925 11.1975 14.25 9 14.25C6.105 14.25 3.75 11.895 3.75 9C3.75 6.8025 5.1075 4.9125 7.0275 4.1325ZM9 2.25C5.2725 2.25 2.25 5.2725 2.25 9C2.25 12.7275 5.2725 15.75 9 15.75C12.7275 15.75 15.75 12.7275 15.75 9C15.75 8.655 15.72 8.31 15.675 7.98C14.94 9.0075 13.74 9.675 12.375 9.675C10.14 9.675 8.325 7.86 8.325 5.625C8.325 4.2675 8.9925 3.06 10.02 2.325C9.69 2.28 9.345 2.25 9 2.25Z"
                  fill="mode == 'dark' ? 'white' : 'grey'"
                />
              </svg>

              Dark
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row> -->
      <v-row justify="center" class="mb-0 caption">
        <div>Version {{ appVersion }}</div></v-row
      >
    </v-col>
    <!-- TODO Hide Dark Mode Toggle switch on nav bar in mobile-->
    <!-- <v-row v-show="isMini" class="mb-8 ml-0 pl-0">
      <v-btn
        justify="center"
        value="light"
        color="primary"
        max-width="35px"
        min-width="20px"
        max-height="30px"
        small
        aria-label="theme"
      >
        <v-img
          v-if="mode == 'light'"
          width="20%"
          height="20%"
          src="./../../assets/images/light_mode.svg"
        ></v-img>
        <svg
          v-if="mode == 'dark'"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          :fill="mode == 'dark' ? 'white' : 'grey'"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.0275 4.1325C6.8925 4.6125 6.825 5.115 6.825 5.625C6.825 8.685 9.315 11.175 12.375 11.175C12.885 11.175 13.3875 11.1075 13.8675 10.9725C13.0875 12.8925 11.1975 14.25 9 14.25C6.105 14.25 3.75 11.895 3.75 9C3.75 6.8025 5.1075 4.9125 7.0275 4.1325ZM9 2.25C5.2725 2.25 2.25 5.2725 2.25 9C2.25 12.7275 5.2725 15.75 9 15.75C12.7275 15.75 15.75 12.7275 15.75 9C15.75 8.655 15.72 8.31 15.675 7.98C14.94 9.0075 13.74 9.675 12.375 9.675C10.14 9.675 8.325 7.86 8.325 5.625C8.325 4.2675 8.9925 3.06 10.02 2.325C9.69 2.28 9.345 2.25 9 2.25Z"
            :fill="mode == 'dark' ? 'white' : 'grey'"
          />
        </svg>
      </v-btn>
    </v-row> -->
    <v-divider vertical></v-divider>
  </v-footer>
</template>
<script>
export default {
  name: "ThemeToggleBar",
  props: {
    isMini: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      mode: "light",
    };
  },
  computed: {
    appVersion() {
      return this.$store.state.App.app.appVersion;
    },
  },
};
</script>
