<template>
  <div>
    <v-divider></v-divider>
    <v-row class="ml-1 mr-2 my-2">
      <v-col
        v-if="!$vuetify.breakpoint.mdAndUp"
        cols="12"
        lg="3"
        xl="3"
        md="3"
        sm="12"
        class="py-0 mb-6 px-1"
      >
        <v-text-field
          v-model="searchTicket"
          prepend-inner-icon="mdi-magnify"
          :label="'Search by ticket number'"
          single-line
          hide-details
          flat
          solo
          dense
          background-color="grey lighten-4"
          class="rounded-lg"
          @change="searchTicketNumber"
          @keypress.enter="searchTicketNumber"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4" sm="4" lg="1" xl="1" md="1" class="py-0 px-1">
        <v-select
          v-model="selectedState"
          :items="states"
          item-value="state"
          item-text="state"
          :menu-props="{ bottom: true, offsetY: true }"
          outlined
          required
          :hide-details="$vuetify.breakpoint.mdAndUp ? true : false"
          dense
          :label="$t('state')"
          @change="onSelectState"
        >
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-content>
                <v-list-item-title
                  class="text-left"
                  v-text="item.state"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col cols="8" sm="8" lg="2" xl="2" md="2" class="py-0 px-1">
        <v-select
          v-model="selectedViewTicket"
          :items="viewTickets"
          item-value="code"
          item-text="label"
          :menu-props="{ bottom: true, offsetY: true }"
          outlined
          required
          :hide-details="$vuetify.breakpoint.mdAndUp ? true : false"
          dense
          :label="$t('viewTickets')"
          @change="onSelectState"
        >
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-content>
                <v-list-item-title
                  class="text-left"
                  v-text="item.label"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="12"
        lg="3"
        xl="3"
        md="3"
        sm="12"
        class="py-0 mb-2 px-1"
      >
        <v-text-field
          v-model="searchTicket"
          prepend-inner-icon="mdi-magnify"
          :label="'Search by ticket number'"
          single-line
          hide-details
          flat
          solo
          dense
          background-color="grey lighten-4"
          class="rounded-lg"
          @change="searchTicketNumber"
          @keypress.enter="searchTicketNumber"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="7" lg="7" md="7" sm="12">
        <div
          id="map"
          ref="map-root"
          style="height: 75vh; border: 1px solid #ccc; background: white"
          :style="{ height: this.$vuetify.breakpoint.xs ? '65vh' : '75vh' }"
          class="mb-2"
        />
      </v-col>
      <v-col cols="12" xl="5" lg="5" md="5" sm="12">
        <v-data-table
          id="main-tickets-table"
          fixed-header
          :headers="headers"
          :height="`calc(100vh - 270px)`"
          disable-sort
          :items="ticketMapTickets"
          item-key="ticket_id"
          mobile-breakpoint="950"
          color="primary"
          checkbox-color="primary"
          :items-per-page="!$vuetify.breakpoint.mdAndUp ? -1 : 15"
          :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : null"
          :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
          :loading-text="$t('loading')"
        >
          <template v-slot:[`item.start_date`]="{ item }">
            <div class="nowrap">
              {{
                item.start_date || item.started
                  ? moment(
                      selectedViewTicket === "nearTicket"
                        ? item.started
                        : item.start_date,
                    ).format("MM/DD/YYYY hh:mm A")
                  : item.start_date
              }}
            </div>
          </template>

          <template v-slot:[`item.center_ticket_id`]="{ item }">
            <div class="nowrap">
              <v-btn
                elevation="0"
                block
                color="white"
                class="primary--text"
                @click="selectTicketNumber(item)"
              >
                {{
                  selectedViewTicket === "nearTicket"
                    ? item.ticket
                    : item.center_ticket_id
                }}-{{ item.revision }}</v-btn
              >
            </div>
          </template>
          <template v-slot:[`item.street_address`]="{ item }">
            {{
              selectedViewTicket === "nearTicket"
                ? item.st_from_address + " " + item.street
                : item.street_address
            }}
          </template>

          <template v-if="!$vuetify.breakpoint.mdAndUp" v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.scheduled_ticket_id"
                class="pr-2 pb-4"
              >
                <v-card height="150" elevation="0">
                  <v-row>
                    <v-col cols="9" class="ml-1">
                      <span class="text-subtitle-1 font-weight-bold">
                        <a
                          class="primary--text"
                          @click="selectTicketNumber(item)"
                        >
                          {{
                            selectedViewTicket === "nearTicket"
                              ? item.ticket
                              : item.center_ticket_id
                          }}-{{ item.revision }}</a
                        >
                      </span>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>

                  <v-row class="ml-1">
                    <v-col
                      cols="4"
                      class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                      >{{ $t("startDate") }}:</v-col
                    >
                    <v-col
                      class="pa-0 text-body-2 text--darken-2 font-weight-medium"
                    >
                      {{
                        item.start_date || item.started
                          ? moment(
                              selectedViewTicket === "nearTicket"
                                ? item.started
                                : item.start_date,
                            ).format("MM/DD/YYYY hh:mm A")
                          : item.start_date
                      }}</v-col
                    >
                  </v-row>
                  <v-row class="ml-1"
                    ><v-col
                      cols="4"
                      class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                      >{{ $t("priority") }}:</v-col
                    ><v-col
                      class="pa-0 text-body-2 text--darken-2 font-weight-medium"
                    >
                      {{ item.priority }}</v-col
                    ></v-row
                  >
                  <v-row class="ml-1"
                    ><v-col
                      cols="4"
                      class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                      >{{ $t("type") }}:</v-col
                    ><v-col
                      class="pa-0 text-body-2 text--darken-2 font-weight-medium"
                    >
                      {{
                        item.ticket_type ? item.ticket_type : item.type
                      }}</v-col
                    ></v-row
                  >
                  <v-row class="ml-1"
                    ><v-col
                      cols="4"
                      class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                      >{{ $t("address") }}:</v-col
                    ><v-col
                      class="pa-0 text-body-2 text--darken-2 font-weight-medium"
                    >
                      {{
                        selectedViewTicket === "nearTicket"
                          ? item.st_from_address + " " + item.street
                          : item.street_address
                      }}, {{ item.place }}, {{ item.county }},
                      {{ item.state }}</v-col
                    ></v-row
                  >
                </v-card>
                <v-divider></v-divider>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <div v-if="$vuetify.breakpoint.mdAndUp" id="popup" class="ol-popup-view">
      <v-row class="ma-0" no-gutters>
        <a
          v-if="selectedViewTicket !== 'nearTicket'"
          class="col-10"
          @click="$emit('onTicketDetails', ticketsFound[0])"
          style="text-decoration: none; color: #1976d2"
        >
          {{
            ticketsFound[0]
              ? this.selectedViewTicket === "nearTicket"
                ? ticketsFound[0].ticket
                : ticketsFound[0].center_ticket_id
              : ""
          }}-{{ ticketsFound[0] ? ticketsFound[0].revision : "" }}
        </a>
        <a v-else class="col-10" style="text-decoration: none; color: #1976d2">
          {{
            ticketsFound[0]
              ? this.selectedViewTicket === "nearTicket"
                ? ticketsFound[0].ticket
                : ticketsFound[0].center_ticket_id
              : ""
          }}-{{ ticketsFound[0] ? ticketsFound[0].revision : "" }}
        </a>
        <a id="popup-closer">
          <v-btn class="col-2 ml-3" aria-label="cross" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </a>
      </v-row>
      <div id="popup-content"></div>
      <v-row no-gutters>
        <v-col cols="5" class="text-body-2"> Start Date: </v-col>
        <v-col cols="7" class="text-body-2 font-weight-medium">
          {{
            ticketsFound[0]
              ? moment(ticketsFound[0].start_date).format("MM/DD/YYYY hh:mm A")
              : ""
          }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" class="text-body-2"> Priority: </v-col>
        <v-col cols="7" class="text-body-2 font-weight-medium">
          {{ ticketsFound[0] ? ticketsFound[0].priority : "" }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" class="text-body-2"> Type: </v-col>
        <v-col cols="7" class="text-body-2 font-weight-medium">
          {{ ticketsFound[0] ? ticketsFound[0].type : "" }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" class="text-body-2"> Expiration Date: </v-col>
        <v-col cols="7" class="text-body-2 font-weight-medium">
          {{
            ticketsFound[0]
              ? moment(ticketsFound[0].expiration_date).format(
                  "MM/DD/YYYY hh:mm A",
                )
              : ""
          }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" class="text-body-2"> User: </v-col>
        <v-col cols="7" class="text-body-2 font-weight-medium">
          {{
            ticketsFound[0]
              ? this.selectedViewTicket === "nearTicket"
                ? ticketsFound[0].caller
                : companyUserMap[ticketsFound[0].user_id].name
              : ""
          }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" class="text-body-2"> Phone Number: </v-col>
        <v-col cols="7" class="text-body-2 font-weight-medium">
          {{ ticketsFound[0] ? ticketsFound[0].phone : "" }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" class="text-body-2"> Center: </v-col>
        <v-col cols="7" class="text-body-2 font-weight-medium">
          {{
            ticketsFound[0]
              ? centerMap[ticketsFound[0].center_id].center_name
              : ""
          }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" class="text-body-2"> Street Address: </v-col>
        <v-col cols="7" class="text-body-2 font-weight-medium">
          {{
            ticketsFound[0]
              ? this.selectedViewTicket === "nearTicket"
                ? ticketsFound[0].st_from_address + " " + ticketsFound[0].street
                : ticketsFound[0].street_address
              : ""
          }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" class="text-body-2"> Place: </v-col>
        <v-col cols="7" class="text-body-2 font-weight-medium">
          {{ ticketsFound[0] ? ticketsFound[0].place : "" }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" class="text-body-2"> County: </v-col>
        <v-col cols="7" class="text-body-2 font-weight-medium">
          {{ ticketsFound[0] ? ticketsFound[0].county : "" }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" class="text-body-2"> State: </v-col>
        <v-col cols="7" class="text-body-2 font-weight-medium">
          {{ ticketsFound[0] ? ticketsFound[0].state : "" }}
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-else
      v-model="dialogOverlay"
      hide-overlay
      content-class="bottom-dialog-hey mx-0 mb-0"
      full-width
      transition="dialog-bottom-transition"
      width="100%"
      class="mx-0"
      max-height="200"
      persistent
    >
      <v-card width="100%" height="100%" class="px-4 pt-4">
        <div>
          <v-row class="ma-0" no-gutters>
            <v-col cols="9" class="pl-0">
              <a
                class="col-10 pl-0"
                @click="$emit('onTicketDetails', ticketsFound[0])"
                style="text-decoration: none; color: #1976d2"
              >
                {{
                  ticketsFound[0]
                    ? this.selectedViewTicket === "nearTicket"
                      ? ticketsFound[0].ticket
                      : ticketsFound[0].center_ticket_id
                    : ""
                }}-{{ ticketsFound[0] ? ticketsFound[0].revision : "" }}
              </a>
            </v-col>
            <v-btn
              class="col-2 ml-6"
              aria-label="cross"
              @click="closeDialog"
              left
              icon
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>

          <v-row no-gutters>
            <v-col cols="5" class="text-body-2"> Start Date: </v-col>
            <v-col cols="7" class="text-body-2 font-weight-medium">
              {{
                ticketsFound[0]
                  ? this.moment(ticketsFound[0].start_date).format(
                      "MM/DD/YYYY hh:mm A",
                    )
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="text-body-2"> Priority: </v-col>
            <v-col cols="7" class="text-body-2 font-weight-medium">
              {{ ticketsFound[0] ? ticketsFound[0].priority : "" }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="text-body-2"> Type: </v-col>
            <v-col cols="7" class="text-body-2 font-weight-medium">
              {{ ticketsFound[0] ? ticketsFound[0].type : "" }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="text-body-2"> Expiration Date: </v-col>
            <v-col cols="7" class="text-body-2 font-weight-medium">
              {{
                ticketsFound[0]
                  ? this.moment(ticketsFound[0].expiration_date).format(
                      "MM/DD/YYYY hh:mm A",
                    )
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="text-body-2"> User: </v-col>
            <v-col cols="7" class="text-body-2 font-weight-medium">
              {{
                ticketsFound[0]
                  ? this.selectedViewTicket === "nearTicket"
                    ? ticketsFound[0].caller
                    : companyUserMap[ticketsFound[0].user_id].name
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="text-body-2"> Phone Number: </v-col>
            <v-col cols="7" class="text-body-2 font-weight-medium">
              {{ ticketsFound[0] ? ticketsFound[0].phone : "" }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="text-body-2"> Center: </v-col>
            <v-col cols="7" class="text-body-2 font-weight-medium">
              {{
                ticketsFound[0]
                  ? centerMap[ticketsFound[0].center_id].center_name
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="text-body-2"> Street Address: </v-col>
            <v-col cols="7" class="text-body-2 font-weight-medium">
              {{
                ticketsFound[0]
                  ? this.selectedViewTicket === "nearTicket"
                    ? ticketsFound[0].st_from_address +
                      " " +
                      ticketsFound[0].street
                    : ticketsFound[0].street_address
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="text-body-2"> Place: </v-col>
            <v-col cols="7" class="text-body-2 font-weight-medium">
              {{ ticketsFound[0] ? ticketsFound[0].place : "" }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="text-body-2"> County: </v-col>
            <v-col cols="7" class="text-body-2 font-weight-medium">
              {{ ticketsFound[0] ? ticketsFound[0].county : "" }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="text-body-2"> State: </v-col>
            <v-col cols="7" class="text-body-2 font-weight-medium">
              {{ ticketsFound[0] ? ticketsFound[0].state : "" }}
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Style, Stroke, Fill, Text, Circle, RegularShape } from "ol/style";

import "ol/ol.css";
import { Map, View, Overlay, Feature, PluggableMap } from "ol";
import * as coordinate from "ol/coordinate";
import * as control from "ol/control";
import * as events from "ol/events";
import * as extent from "ol/extent";
import * as format from "ol/format";
import * as geom from "ol/geom";
import * as interaction from "ol/interaction";
import * as layer from "ol/layer";
import * as proj from "ol/proj";
import * as render from "ol/render";
import * as reproj from "ol/reproj";
import * as source from "ol/source";
import * as sphere from "ol/sphere";
import * as style from "ol/style";
import * as tilegrid from "ol/tilegrid";
import * as webgl from "ol/webgl";
import * as turf from "@turf/turf";
import moment from "moment";
import "@/components/Map/QMOL.css";
import Compass from "@/assets/compass-rose.png";
import Logo from "@/components/Map/QMOL_Logo.png";
import QMOL_Data from "@/components/Map/QMOL_Data.js";
import { quickMap } from "@/components/Map/QMOL.js";
import api from "../../../store/api";
import * as wellknown from "wellknown";
export default {
  data() {
    return {
      qm: null,
      features: {},
      text: "maps",
      dialog: false,
      ticketsFound: [],
      ticketMapTickets: [],
      divPopupOverlay: "",
      dialogOverlay: false,
      stateToCenterMap: {},
      states: [],
      selectedState: "",
      viewTickets: [
        { label: "View My Ticket", code: "myTicket" },
        { label: "View Company Ticket", code: "companyTicket" },
        { label: "View Near Tickets", code: "nearTicket" },
      ],
      headers: [
        {
          text: this.$t("ticket"),
          align: "start",
          value: "center_ticket_id",
        },
        { text: this.$t("startDate"), value: "start_date" },
        { text: this.$t("address"), value: "street_address", width: "200" },
        { text: this.$t("place"), value: "place", width: "120" },
        { text: this.$t("county"), value: "county", width: "100" },
      ],
      selectedViewTicket: "myTicket",
      searchTicket: "",
      selectedCounty: "",
      selectedPlace: "",
    };
  },
  computed: {
    centerMap() {
      return this.$store.getters.centerMap;
    },
    companyUserMap() {
      return this.$store.state.User.companyUserMap;
    },
    newtinAuthorizeCentersList() {
      let centersList = [];
      if (
        Object.keys(this.$store.state.Company.company).length > 0 &&
        this.$store.state.Company.company.centers &&
        this.$store.state.Company.company.centers.length > 0
      ) {
        centersList = this.$store.state.Company.company.centers.map((item) => {
          return {
            centerName: this.$store.state.User.centerMap[item.center_id].abbrev,
            state: this.$store.state.User.centerMap[item.center_id].state,
            center_id: item.center_id,
            excavator_type: item.excavator_type,
            default: item.default,
          };
        });
      }
      return centersList;
    },
  },
  watch: {
    async newtinAuthorizeCentersList(val) {
      if (this.newtinAuthorizeCentersList) {
        await this.getStates();
      }
    },
  },
  async mounted() {
    this.qm = new quickMap({
      ol: {
        View,
        Map,
        Overlay,
        Feature,
        PluggableMap,
        control,
        coordinate,
        events,
        extent,
        format,
        geom,
        interaction,
        layer,
        proj,
        render,
        reproj,
        source,
        sphere,
        style,
        tilegrid,
        webgl,
      },
      turf: turf,
      target: "map",
      compass: Compass,
      logo: Logo,
      extraData: QMOL_Data.QMOL_Data,
    });

    this.qm.showDraw = false;
    this.qm.showEdit = false;
    this.qm.showDelete = false;
    this.qm.showWeather = false;
    this.qm.showMeasure = false;
    this.qm.showBullseye = false;
    this.qm.showIdentify = false;
    this.qm.showClear = false;
    this.qm.showHelp = false;
    this.qm.showIntersection = false;
    this.qm.showToggleControls = true;
    this.qm.showBlock = false;
    this.qm.showFlag = false;
    this.qm.showDraw = false;
    this.qm.showEdit = false;
    this.qm.showDrawPoint = false;
    this.qm.showDrawLine = false;
    this.qm.showDrawPolygon = false;
    this.qm.showDelete = false;
    this.qm.showWeather = false;
    this.qm.showMeasure = false;
    this.qm.showBullseye = false;
    this.qm.showIdentify = false;
    this.qm.showClear = false;
    this.qm.showMeasureDirection = true;
    this.qm.showMeasureLengthFeet = true;
    this.qm.showMeasureLengthYards = false;
    this.qm.showMeasureLengthMiles = true;
    this.qm.showMeasureLengthMeters = false;
    this.qm.showMeasureLengthKilometers = false;
    this.qm.showMeasureAreaSqFeet = true;
    this.qm.showMeasureAreaSqYards = false;
    this.qm.showMeasureAreaAcres = true;
    this.qm.showMeasureAreaSqMiles = false;
    this.qm.showMeasureAreaSqMeters = false;
    this.qm.showMeasureAreaSqKilometers = false;
    this.divPopupOverlay = new this.qm.ol.Overlay({
      element: document.getElementById("popup"),
      closeBox: true,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });

    this.qm.map.addOverlay(this.divPopupOverlay);
    this.divPopupOverlay.setPosition(undefined);
    // document.addEventListener("qmolSingleClick", (e) => {
    //   this.singleClick(e);
    // });
  },
  async created() {
    this.moment = moment;
    if (this.newtinAuthorizeCentersList) {
      await this.getStates();
      this.updateMap();
    }
  },
  methods: {
    onSelectState() {
      this.updateMap();
    },
    async getStates() {
      let centersList = this.newtinAuthorizeCentersList;
      centersList.forEach(async (item) => {
        if (!item) return;
        if (item.default) this.selectedState = item.state;
        const statesStatus = { state: item.state };
        if (statesStatus) {
          this.stateToCenterMap[statesStatus.state] = item.centerName;
          this.states.push(statesStatus);
        }
      });
    },
    closeDialog() {
      this.dialogOverlay = false;
      this.overlay.setPosition(undefined);
    },
    async updateMap() {
      this.qm.clearShow();
      const tickets = {
        type: "FeatureCollection",
        features: [],
      };
      this.ticketMapTickets = [];
      const position = await this.qm.getGeolocation();
      let response = [];
      if (this.selectedViewTicket === "myTicket") {
        response = await api.ticket.getMyTickets({
          state: this.selectedState,
        });
      } else if (this.selectedViewTicket === "companyTicket") {
        response = await api.ticket.getCompanyTickets({
          state: this.selectedState,
        });
      } else if (this.selectedViewTicket === "nearTicket") {
        this.selectedState = this.qm.getCountyForLongLat([
          position.coords.longitude,
          position.coords.latitude,
        ]).STATE;
        const today = new Date();
        const resp = await this.$store.dispatch("getCenterTickets", {
          center: this.stateToCenterMap[this.selectedState],
          point: [position.coords.longitude, position.coords.latitude],
          start: new Date(new Date().setDate(today.getDate() - 21)),
        });
        response = resp?.data;
      }
      if (this.selectedViewTicket !== "nearTicket") {
        response = response.filter((element) => {
          return element.receipt.extent_left;
        });
      }

      for (let ticket of response) {
        if (ticket.state !== this.selectedState) {
          continue;
        }

        let addresStatus = [];

        if (this.selectedViewTicket === "nearTicket") {
          const nearCenter = this.newtinAuthorizeCentersList.filter((item) => {
            return (
              item.centerName === this.stateToCenterMap[this.selectedState]
            );
          });
          ticket.center_id = nearCenter[0]?.center_id;

          addresStatus = [ticket.longitude, ticket.latitude];
          if (
            !ticket.longitude ||
            !ticket.latitude ||
            ticket.longitude === " " ||
            ticket.latitude === " "
          ) {
            addresStatus = [
              ticket.extent_left,
              (ticket.extent_bottom + ticket.extent_top) / 2,
            ];
          }
        } else {
          if (ticket.options) {
            addresStatus = ticket.options?.requestObject?.data?.shape?.geometry
              ?.coordinates[0][0]
              ? ticket.options?.requestObject?.data?.shape?.geometry
                  ?.coordinates[0][0]
              : ticket.options?.requestObject?.shape?.geometry
                  ?.coordinates[0][0];
            ticket.phone = ticket.options?.requestObject?.data?.shape?.geometry
              ?.coordinates[0][0]
              ? ticket.options?.requestObject?.data?.phone
              : ticket.options?.requestObject?.phone;
          } else if (ticket.receipt) {
            addresStatus = [
              ticket.receipt.extent_left,
              (ticket.receipt.extent_bottom + ticket.receipt.extent_top) / 2,
            ];
          } else {
            continue;
          }
        }
        if (!addresStatus) continue;
        const fillColor = this.hexToRGBA(
          this.priorityIconColor(ticket.priority),
          0.8,
        );
        const fillColor2 = this.hexToRGBA(
          this.priorityIconColor(ticket.priority),
          0.1,
        );
        let image, image2;
        switch (this.priorityShape(ticket.priority)) {
          case "circle":
            image = new Circle({
              stroke: new Stroke({ color: "black", width: 2 }),
              fill: new Fill({ color: fillColor }),
              radius: 8,
            });
            break;
          case "square":
            image = new RegularShape({
              stroke: new Stroke({ color: "black", width: 2 }),
              fill: new Fill({ color: fillColor }),
              points: 4,
              radius: 10,
              angle: Math.PI / 4,
            });
            break;
          case "triangle":
            image = new RegularShape({
              stroke: new Stroke({ color: "black", width: 2 }),
              fill: new Fill({ color: fillColor2 }),
              points: 3,
              radius: 10,
            });
            image2 = new RegularShape({
              stroke: new Stroke({ color: "black", width: 2 }),
              fill: new Fill({ color: fillColor }),
              points: 3,
              radius: 10,
            });
            break;
        }
        tickets.features.push({
          type: "Feature",
          properties: {
            style: function (zoom) {
              return zoom < 0
                ? undefined
                : new Style({
                    text: new Text({
                      text:
                        zoom < 12
                          ? ""
                          : zoom < 15
                            ? !ticket.center_ticket_id
                              ? ticket.ticket
                              : ticket.center_ticket_id
                            : !ticket.center_ticket_id
                              ? `${ticket.ticket}-${ticket.revision}\n${ticket.type}`
                              : `${ticket.center_ticket_id}-${ticket.revision}\n${ticket.type}`,
                      font: "bold 12px Calibri,sans-serif",
                      stroke: new Stroke({ color: "white", width: 3 }),
                      fill: new Fill({ color: "black" }),
                      offsetY: zoom < 15 ? -15 : -25,
                    }),
                    image: image,
                  });
            },
            ticket: ticket,
          },
          geometry: {
            type: "Point",
            coordinates: [addresStatus[0], addresStatus[1]],
          },
        });
        if (ticket.priority === "EMER") {
          tickets.features.push({
            type: "Feature",
            properties: {
              style: function (zoom) {
                return zoom < 0
                  ? undefined
                  : new Style({
                      image: image2,
                    });
              },
              blink: true,
            },
            geometry: {
              type: "Point",
              coordinates: [addresStatus[0], addresStatus[1]],
            },
          });
        }
      }
      this.ticketMapTickets = response;
      if (this.selectedViewTicket === "nearTicket") {
        this.ticketMapTickets.sort(function (a, b) {
          return new Date(b.started) - new Date(a.started);
        });
      } else {
        this.ticketMapTickets.sort(function (a, b) {
          return new Date(b.start_date) - new Date(a.start_date);
        });
      }

      setTimeout(() => {
        this.qm.clearShow();
        this.qm.addToShow(tickets);
        if (this.selectedViewTicket === "nearTicket") {
          this.qm.map
            .getView()
            .setCenter(
              this.qm.ol.proj.transform(
                [position.coords.longitude, position.coords.latitude],
                "EPSG:4326",
                "EPSG:3857",
              ),
            );
          this.qm.map.getView().setZoom(18);
        } else if (this.selectedState && this.ticketMapTickets.length === 0) {
          this.qm.zoomToState(this.selectedState);
        } else {
          if (this.selectedViewTicket === "nearTicket") {
            this.qm.map
              .getView()
              .setCenter(
                this.qm.ol.proj.transform(
                  [position.coords.longitude, position.coords.latitude],
                  "EPSG:4326",
                  "EPSG:3857",
                ),
              );
            this.qm.map.getView().setZoom(18);
          } else {
            this.qm.zoomToShow();
          }
        }
      }, 0);
    },
    hexToRGBA(hex, alpha) {
      return `${hex}${Math.floor(alpha * 255)
        .toString(16)
        .padStart(2, 0)}`;
    },

    singleClick(data) {
      if (data.detail.features.length === 0) return;
      this.ticketsFound = [];
      for (const feature of data.detail.features.features) {
        if (!feature.properties.blink) {
          this.ticketsFound.push(feature.properties.ticket);
        }
      }
      if (this.ticketsFound.length === 0) {
        this.clearPopup();
        this.dialogOverlay = false;
        return;
      }
      if (!this.$vuetify.breakpoint.mdAndUp) {
        this.dialogOverlay = true;
        return;
      }
      this.qm.map.getView().setZoom(18);
      this.qm.map
        .getView()
        .setCenter(
          this.qm.ol.proj.transform(
            data.detail.coordinate,
            "EPSG:4326",
            "EPSG:3857",
          ),
        );
      this.divPopupOverlay.setPosition(
        this.qm.ol.proj.fromLonLat(data.detail.coordinate),
        this.qm.map.getView().getProjection(),
      );

      const closer = document.getElementById("popup-closer");

      if (closer)
        closer.addEventListener("click", (e) => {
          this.clearPopup();
        });
    },
    searchTicketNumber() {
      let data = {};
      if (this.searchTicket) {
        data = this.ticketMapTickets.filter((item) => {
          if (this.selectedViewTicket === "nearTicket") {
            return item.ticket + "-" + item.revision === this.searchTicket;
          } else {
            return (
              item.center_ticket_id + "-" + item.revision === this.searchTicket
            );
          }
        });
      }

      this.ticketsFound = [];
      this.dialogOverlay = false;
      this.clearPopup();
      if (data.length === 0) {
        return;
      }
      const ticket = data[0];
      this.ticketsFound.push(ticket);
      this.dialogOverlay = true;
      let location = [];
      if (this.selectedViewTicket === "nearTicket") {
        const nearCenter = this.newtinAuthorizeCentersList.filter((item) => {
          return item.centerName === this.stateToCenterMap[this.selectedState];
        });
        ticket.center_id = nearCenter[0]?.center_id;
        location = [ticket.longitude, ticket.latitude];
        if (
          !ticket.longitude ||
          !ticket.latitude ||
          ticket.longitude === " " ||
          ticket.latitude === " "
        ) {
          (location = ticket.extent_left),
            (ticket.extent_bottom + ticket.extent_top) / 2;
        }
      } else {
        if (ticket.options)
          location = ticket.options?.requestObject?.data?.shape?.geometry
            ?.coordinates[0][0]
            ? ticket.options?.requestObject?.data?.shape?.geometry
                ?.coordinates[0][0]
            : ticket.options?.requestObject?.shape?.geometry?.coordinates[0][0];
        else {
          location = [ticket.longitude, ticket.latitude];
          if (
            !ticket.longitude ||
            !ticket.latitude ||
            ticket.longitude === " " ||
            ticket.latitude === " "
          ) {
            location = [
              ticket.receipt.extent_left,
              (ticket.receipt.extent_bottom + ticket.receipt.extent_top) / 2,
            ];
          }
        }
      }
      this.ticketsFound = [];

      this.ticketsFound.push(ticket);
      this.dialogOverlay = true;
      if (this.ticketsFound.length === 0) {
        this.clearPopup();
        this.dialogOverlay = false;
      }
      this.divPopupOverlay.setPosition(
        this.qm.ol.proj.fromLonLat(location),
        this.qm.map.getView().getProjection(),
      );
      this.qm.map.getView().setZoom(18);
      this.qm.map
        .getView()
        .setCenter(
          this.qm.ol.proj.transform(location, "EPSG:4326", "EPSG:3857"),
        );
      const closer = document.getElementById("popup-closer");
      closer.addEventListener("click", (e) => {
        this.clearPopup();
      });
    },
    selectTicketNumber(selectedTicket) {
      if (!this.$vuetify.breakpoint.mdAndUp) {
        window.scrollTo(0, 0);
      }
      let data = selectedTicket;
      if (data.length === 0) return;
      const ticket = data;
      let location = [];
      if (this.selectedViewTicket === "nearTicket") {
        const nearCenter = this.newtinAuthorizeCentersList.filter((item) => {
          return item.centerName === this.stateToCenterMap[this.selectedState];
        });
        ticket.center_id = nearCenter[0]?.center_id;
        location = [ticket.longitude, ticket.latitude];
        if (
          !ticket.longitude ||
          !ticket.latitude ||
          ticket.longitude === " " ||
          ticket.latitude === " "
        ) {
          location = [
            ticket.extent_left,
            (ticket.extent_bottom + ticket.extent_top) / 2,
          ];
        }
      } else {
        if (ticket.options)
          location = ticket.options?.requestObject?.data?.shape?.geometry
            ?.coordinates[0][0]
            ? ticket.options?.requestObject?.data?.shape?.geometry
                ?.coordinates[0][0]
            : ticket.options?.requestObject?.shape?.geometry?.coordinates[0][0];
        else {
          location = [ticket.longitude, ticket.latitude];
          if (
            !ticket.longitude ||
            !ticket.latitude ||
            ticket.longitude === " " ||
            ticket.latitude === " "
          ) {
            location = [
              ticket.receipt.extent_left,
              (ticket.receipt.extent_bottom + ticket.receipt.extent_top) / 2,
            ];
          }
        }
      }

      this.ticketsFound = [];
      this.ticketsFound.push(ticket);
      this.dialogOverlay = true;
      if (this.ticketsFound.length === 0) {
        this.clearPopup();
        this.dialogOverlay = false;
      }
      this.divPopupOverlay.setPosition(
        this.qm.ol.proj.fromLonLat(location),
        this.qm.map.getView().getProjection(),
      );
      this.qm.map.getView().setZoom(18);
      this.qm.map
        .getView()
        .setCenter(
          this.qm.ol.proj.transform(location, "EPSG:4326", "EPSG:3857"),
        );
      const closer = document.getElementById("popup-closer");
      closer.addEventListener("click", (e) => {
        this.clearPopup();
      });
    },
    clearPopup() {
      this.divPopupOverlay.setPosition(undefined);
    },
    priorityIconColor(priority) {
      switch (priority) {
        case "NORM":
        case "ROUT":
          return "#90EE90";
        case "RUSH":
        case "SHRT":
        case "INSF":
          return "#FFA500";
        case "EMER":
          return "#FF0000";
        case "NONC":
          return "#ADD8E6";
        default:
          return "#FFFFFF";
      }
    },
    priorityShape(priority) {
      switch (priority) {
        case "NORM":
        case "ROUT":
          return "circle";
        case "RUSH":
        case "SHRT":
        case "INSF":
        case "NONC":
          return "square";
        case "EMER":
          return "triangle";
        default:
          return "circle";
      }
    },
    priorityIcon(priority) {
      switch (priority) {
        case "NORM":
        case "ROUT":
          // gray circle exclamation
          return "error";
        case "RUSH":
        case "SHRT":
        case "INSF":
          // yellow circle exclamation
          return "error";
        case "EMER":
          // red bell exclamation
          return "notification_important";
        case "NONC":
          // light blue circle exclamation
          return "error";
        default:
          return "";
      }
    },
    showticketsFound(event) {
      if (event.detail.length > 0) {
        this.ticketsFound = event.detail;
        this.dialog = true;
      }
    },
  },
};
</script>

<style lang="scss">
.bottom-dialog-hey {
  height: 35%;
  margin-bottom: 0;
  align-self: flex-end;
}
.map {
  width: 100%;
  height: 400px;
}
.ol-popup-view {
  position: absolute;
  background-color: white !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  padding-right: 2px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 320px;
  height: 330px;
}
.ol-popup-view:after,
.ol-popup-view:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup-view:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup-view:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
</style>
