import { checkResponse, getHeader } from "../../utils/utils";

export default {
  async getsubscription() {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/subscription`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async getsubscriptionById(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/user/${body.userId}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async getStripeSubscription(payload) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/subscription/stripe-subscription/${payload.company_id}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async retrieveInvoice(body) {
    let bodyFormData = new URLSearchParams();
    for (let obj in body) {
      if (typeof body[obj] === "object" && ["newItems"].includes(obj))
        bodyFormData.append(obj, JSON.stringify(body[obj]));
      else bodyFormData.append(obj, body[obj]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/subscription/stripe-upcoming-invoice`,
      {
        method: "POST",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async getsubscriptionWithProducts() {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/subscription/products`,
      {
        method: "GET",
        headers: getHeader("form"),
      }
    );
    return await checkResponse(response);
  },
};
