import Vue from "vue";
import Vuex from "vuex";
import App from "./vuex-modules/app";
import User from "./vuex-modules/user";
import Company from "./vuex-modules/company";
import Subscription from "./vuex-modules/subscription";
import NewtinAuth from "./vuex-modules/newtin";
import Ticket from "./vuex-modules/ticket";
import Approval from "./vuex-modules/approval";
import ScheduleTicket from "./vuex-modules/scheduleTicket";
import Notification from "./vuex-modules/notification";
import Project from "./vuex-modules/project";
import ProjectTask from "./vuex-modules/projectTask";
import TaskHistory from "./vuex-modules/history";
import Attachments from "./vuex-modules/attachments";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    App: App,
    User: User,
    Company: Company,
    Subscription: Subscription,
    NewtinAuth: NewtinAuth,
    Ticket: Ticket,
    Approval: Approval,
    ScheduleTicket: ScheduleTicket,
    Notification: Notification,
    Project,
    ProjectTask,
    TaskHistory,
    Attachments,
  },
});
