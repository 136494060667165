<template v-slot:activator="{ on, attrs }">
  <v-list nav dense light aria-label="Navigation bar List" role="list">
    <v-list-item-group aria-label="Navigation bar items group " role="group">
      <div v-for="(option, index) in options" :key="index">
        <v-list-item
          v-if="!('subOptions' in option)"
          class="text-subtitle-2 grey--text text--darken-1"
          active-class="grey lighten-3 grey--text text--darken-4"
          role="listitem"
          :aria-label="option.label"
          @click="navigateToRoute(option.route)"
        >
          <v-list-item-icon class="mr-3">
            <v-icon>{{ option.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ $t(option.label) }}
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-if="'subOptions' in option"
          active-class="grey lighten-3 grey--text text--darken-4"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-3">
              <v-icon>{{ option.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-2 grey--text text--darken-1"
              ><span>{{ $t(option.label) }}</span></v-list-item-title
            >
          </template>
          <v-list-item
            v-for="(subOption, i) in option.subOptions"
            :key="i"
            class="pl-1 grey--text text--darken-1"
            active-class="grey lighten-3 grey--text text--darken-4"
            @click="navigateToRoute(subOption.route)"
          >
            <v-list-item-icon class="ms-2 mr-2">
              <v-icon size="12">mdi-checkbox-blank-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-2">
              {{ $t(subOption.label) }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  computed: {
    options() {
      const company = this.$store.state.Company.company;
      const rolesAndPermissions =
        company.options && company.options.rolesAndPermissions;
      return this.$store.getters.menuItems(rolesAndPermissions);
    },
  },
  methods: {
    navigateToRoute(route) {
      if (this.$route.path !== route) {
        this.$router.push(route).catch();
      }
    },
  },
};
</script>
