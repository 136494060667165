import { render, staticRenderFns } from "./NavDrawer.vue?vue&type=template&id=246037dc&scoped=true&v-slot%3Aactivator=%7B%20on%2C%20attrs%20%7D&"
import script from "./NavDrawer.vue?vue&type=script&lang=js&"
export * from "./NavDrawer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246037dc",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VAppBarNavIcon,VIcon,VImg,VNavigationDrawer})


/* hot reload */
if (module.hot) {
  var api = require("N:\\diglogix\\diglogix-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('246037dc')) {
      api.createRecord('246037dc', component.options)
    } else {
      api.reload('246037dc', component.options)
    }
    module.hot.accept("./NavDrawer.vue?vue&type=template&id=246037dc&scoped=true&v-slot%3Aactivator=%7B%20on%2C%20attrs%20%7D&", function () {
      api.rerender('246037dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/NavDrawer.vue"
export default component.exports