import { checkResponse, getHeader } from "../../utils/utils";

export default {
  async getProjectDataById(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/project/${body.project_id}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async updateProjectDetails(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body) {
      if (body[key] && typeof body[key] === "object")
        bodyFormData.append(key, JSON.stringify(body[key]));
      else bodyFormData.append(key, body[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/project/${body.project_id}`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async createProject(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body) {
      if (body[key] && typeof body[key] === "object")
        bodyFormData.append(key, JSON.stringify(body[key]));
      else bodyFormData.append(key, body[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/project`,
      {
        method: "POST",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async getCompanyProject() {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/project/companyproject`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async getHistoryByTaskId(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/taskhistory/${body.task_id}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
};
