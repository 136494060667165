import accessOptions from "@/static/grants.json";
import api from "../api";
import { getAuthDetails, setAuthDetails } from "../utils/utils";

const getDefaultState = () => {
  return {
    scheduleTicketsData: [],
  };
};
export default {
  state: getDefaultState(),

  mutations: {
    setScheduleTicket(state, scheduleTicketsData) {
      state.scheduleTicketsData = Object.assign([], scheduleTicketsData);
    },
  },
  actions: {
    async scheduleTicket({ commit }, payload) {
      const resp = await api.ticket.scheduleTicket(payload);
      return resp;
    },
    async updateScheduleTicket({ commit }, payload) {
      const resp = await api.ticket.updateScheduleTicket(payload);
      return resp;
    },
    async getScheduleTicketData({ state }) {
      const resp = await api.ticket.getScheduleTicketData();
      return resp;
    },
    async deleteScheduleTicket({ state }, payload) {
      const resp = await api.ticket.deleteScheduleTicket(payload);
      return resp;
    },
  },
  getters: {},
};
