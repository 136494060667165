export async function secondaryFunctionObject(state, body) {
  if (state.ticketData.state === "NC") {
    return await createObjectForNC(state, body);
  } else if (state.ticketData.state === "IL") {
    return await createObjectForJulie(state, body);
  } else if (state.ticketData.state === "OH") {
    return await createObjectForOHIO(state, body);
  } else if (state.ticketData.state === "CO") {
    return await createObjectForCO(state, body);
  }
}
function createObjectForCO(state, obj) {
  let body = Object.assign({}, obj);
  const ticketData = state.ticketData;
  let data = {
    state: ticketData.state,
    county: ticketData.county,
    place: ticketData.place,
    st_from_address: body.data.st_from_address,
    st_to_address: body.data.st_to_address,
    street: ticketData.street,
    cross1: ticketData.streetLocation.cross1,
    location: body.data.location,
    name: body.data.name,
    caller: body.data.name,
    caller_phone: body.data.caller_phone,
    caller_type: body.data.caller_type,
    phone: body.data.phone,
    phone_ext: "",
    contact: body.data.contact,
    contact_phone: body.data.contact_phone,
    address1: body.data.address1,
    city: body.data.city,
    cstate: body.data.cstate,
    zip: body.data.zip,
    email: body.data.email,
    latitude: body.data.latitude,
    longitude: body.data.longitude,
    boring: body.data.boring,
    blasting: body.data.blasting,
    legal_notice: body.data.legal_notice,
    done_for: body.data.done_for,
    emergency: false,
    shape: body.data.shape,
    work_date: body.data.work_date,
    work_type: body.data.work_type,
  };
  if (state.isEdit == "UpdateTicket") {
    const nextDateStr = new Date(
      new Date(body.data.work_date).getTime() + 86400000
    ).toISOString();
    data.work_end = nextDateStr;
  }
  body.data = Object.assign({}, data);
  body.ticketNumber = state.centerTicketId;
  if (state.isEdit == "UpdateTicket")
    body.url = `${body.url}/tickets/${body.ticketNumber}/revisions/update`;

  return body;
}
async function createObjectForNC(state, obj) {
  let body = Object.assign({}, obj);
  const ticketData = state.ticketData;
  let data = {
    state: ticketData.state,
    county: ticketData.county,
    place: ticketData.place,
    subdivision: body.data.subdivision,
    lots: body.data.lots,
    //posted: "",
    st_from_address: body.data.st_from_address,
    st_to_address: body.data.st_to_address,
    street: ticketData.street,
    cross1: ticketData.streetLocation.cross1,
    cross2: ticketData.streetLocation.cross2,
    location: body.data.location,
    name: body.data.name,
    caller: body.data.name,
    caller_type: body.data.caller_type,
    phone: body.data.phone,
    //phone_ext: "",
    cell: body.data.phone,
    contact: body.data.contact,
    contact_phone: body.data.contact_phone,
    //fax: "",
    address1: body.data.address1,
    city: body.data.city,
    cstate: body.data.cstate,
    zip: body.data.zip,
    email: body.data.email,
    //subcontractor: "",
    //subcontractor_phone: "",
    latitude: body.data.latitude,
    longitude: body.data.longitude,
    boring: body.data.boring,
    blasting: body.data.blasting,
    rr: body.data.rr,
    premarks: body.data.premarks,
    distance_requirement: "UNKNOWN",
    done_for: body.data.done_for,
    comments: body.data.comments,
    remarks: body.data.remarks,
    duration: body.data.duration,
    emergency: false,
    //membersToAdd: "",
    //membersToRemove: "",
    shape: body.data.shape,
  };
  if (state.isEdit == "UpdateTicket") {
    data.updateRequirementAcknowledgment = "YES";
    data.work_date = body.data.work_date;
    data.work_type = body.data.work_type;
  }

  body.data = Object.assign({}, data);
  body.ticketNumber = state.centerTicketId;
  if (state.isEdit == "UpdateTicket")
    body.url = `${body.url}/tickets/${body.ticketNumber}/revisions/update`;
  else if (state.isEdit == "DestroyedMarks")
    body.url = `${body.url}/tickets/${body.ticketNumber}/revisions/destroyed-marks`;
  return body;
}
function createObjectForJulie(state, obj) {
  let body = Object.assign({}, obj);
  const ticketData = state.ticketData;
  let data = {
    state: ticketData.state,
    county: ticketData.county,
    place: ticketData.place,
    subdivision: body.data.subdivision,
    //lots: body.data.lots,
    //posted: "",
    st_from_address: body.data.st_from_address,
    st_to_address: body.data.st_to_address,
    street: ticketData.street,
    cross1: ticketData.streetLocation.cross1,
    //cross2: ticketData.streetLocation.cross2,
    location: body.data.location,
    name: body.data.name,
    caller: body.data.name,
    caller_type: body.data.caller_type,
    phone: body.data.phone,
    //phone_ext: "",
    //cell: body.data.phone,
    contact: body.data.contact,
    contact_phone: body.data.contact_phone,
    //fax: "",
    address1: body.data.address1,
    city: body.data.city,
    cstate: body.data.cstate,
    zip: body.data.zip,
    email: body.data.email,
    //subcontractor: "",
    //subcontractor_phone: "",
    latitude: body.data.latitude,
    longitude: body.data.longitude,
    boring: body.data.boring,
    blasting: body.data.blasting,
    rr: body.data.rr,
    extent: body.data.extent,
    survey1: body.data.survey1,
    depth_7ft: body.data.depth_7ft,
    premarks: body.data.premarks,
    distance_requirement: body.data.distance_requirement,
    done_for: body.data.done_for,
    comments: body.data.comments,
    remarks: body.data.remarks,
    duration: body.data.duration,
    emergency: false,
    //membersToAdd: "",
    //membersToRemove: "",
    shape: body.data.shape,
    work_date: body.data.work_date,
    send_email: true,
    work_type: body.data.work_type,
  };
  body.data = Object.assign({}, data);
  body.ticketNumber = state.centerTicketId;
  if (state.isEdit == "RetransmitTicket")
    body.url = `${body.url}/tickets/${body.ticketNumber}/revisions/retransmit`;

  return body;
}
async function createObjectForOHIO(state, obj) {
  let body = Object.assign({}, obj);
  const excavationDetails = state.excavationDetails;
  const ticketData = state.ticketData;
  let data = {
    contact: body.data.contact,
    contact_phone: body.data.contact_phone,
    state: ticketData.state,
    county: ticketData.county,
    place: ticketData.place,
    st_from_address: body.data.st_from_address,
    st_to_address: body.data.st_to_address,
    street: ticketData.street,
    cross1: ticketData.streetLocation.cross1,
    cross2: ticketData.streetLocation.cross2,
    subdivision: body.data.subdivision,
    location: body.data.location,
    comments: body.data.comments,
    shape: body.data.shape,
  };
  if (state.isEdit == "UpdateTicket") {
    data.reason = excavationDetails.reason;
    (data.remarks = excavationDetails.remarks),
      (data.additionalComments = "YES");
    (data.membersToAdd = excavationDetails.membersToAdd),
      (data.work_date = body.data.work_date);
  }
  if (state.isEdit === "Digin") {
    data.digin_catv = excavationDetails.digin_catv;
    data.digin_elec = excavationDetails.digin_elec;
    data.digin_fiber = excavationDetails.digin_fiber;
    data.digin_gas = excavationDetails.digin_gas;
    data.digin_blowing = excavationDetails.digin_blowing;
    data.digin_phone = excavationDetails.digin_phone;
    data.digin_sewer = excavationDetails.digin_sewer;
    data.digin_water = excavationDetails.digin_water;
    data.digin_spill = excavationDetails.digin_spill;
    data.digin_other = excavationDetails.digin_other;
    data.membersToAdd = excavationDetails.membersToAdd;
  }

  body.data = Object.assign({}, data);
  body.ticketNumber = state.centerTicketId;
  if (state.isEdit == "UpdateTicket")
    body.url = `${body.url}/tickets/${body.ticketNumber}/revisions/update`;
  else if (state.isEdit == "Digin")
    body.url = `${body.url}/tickets/${body.ticketNumber}/revisions/digin`;
  return body;
}
