import api from "../api";
import { getAuthDetails } from "../utils/utils";
import { secondaryFunctionObject } from "../../js/secondaryFunctionHelper";
const getDefaultState = () => {
  return {
    geoJson: {},
    excavationDetails: {},
    ticketData: {},
    currentStep: 1,
    scheduleDetails: {},
    isEdit: "",
    tickets: [],
  };
};
export default {
  state: getDefaultState(),

  mutations: {
    setMapData(state, geoJson) {
      state.geoJson = geoJson;
    },
    setIsEdit(state, isEdit) {
      state.isEdit = isEdit;
    },
    setCurrentStep(state, currentStep) {
      state.currentStep = currentStep;
    },
    setExcavationDetails(state, excavationDetails) {
      state.excavationDetails = excavationDetails;
    },
    setTicketData(state, ticketData) {
      state.ticketData = ticketData;
    },
    setScheduleDetails(state, scheduleDetails) {
      state.scheduleDetails = Object.assign([], scheduleDetails);
    },
    resetState(state) {
      state = Object.assign(state, getDefaultState());
    },
    resetTicketState(state) {
      state = Object.assign(state, getDefaultState());
    },
    setTickets(state, tickets) {
      state.tickets = Object.assign({}, tickets);
    },
    setCenterTicketId(state, centerTicketId) {
      state.centerTicketId = centerTicketId;
    },
    setTicketId(state, ticketId) {
      state.ticketId = ticketId;
    },
    setMembersToAdd(state, membersToAddArray) {
      state.membersToAdd = membersToAddArray;
    },
  },

  actions: {
    async createTicket({ state }, body) {
      const resp = await api.ticket.createTicket(body);
      return resp;
    },
    async updateExistingTicket({ state }, body) {
      const excavationDetails = state.excavationDetails;
      const ticketData = state.ticketData;
      if (state.isEdit == "RetransmitTicket") {
        let data = {};
        data.excavationDetails = excavationDetails;
        data.geoJson = state.geoJson;
        data.ticketData = ticketData;
        data.isEdit = state.isEdit;
        body = { ...body, ...data };
        body.ticket_id = state.ticketId;
        body.ticket = state.centerTicketId;
        ticketResponse = await api.ticket.revisionRetransmitTicket(body);
        return ticketResponse;
      } else if (state.isEdit == "UpdateTicket") {
        let data = {};
        data.excavationDetails = excavationDetails;
        data.geoJson = state.geoJson;
        data.ticketData = ticketData;
        data.isEdit = state.isEdit;
        body = { ...body, ...data };
        body.ticket_id = state.ticketId;
        body.ticket = state.centerTicketId;
        if (ticketData.state == "UT") {
          body.apiData.data.remarks =
            (body.apiData.data.remarks ? body.apiData.data.remarks : "") +
            (excavationDetails.NewtinRemark
              ? excavationDetails.NewtinRemark
              : "") +
            excavationDetails.utahRemark;
          body.apiData.data.comments =
            (body.apiData.data.comments ? body.apiData.data.comments : "") +
            (excavationDetails.NewtinComment
              ? excavationDetails.NewtinComment
              : "") +
            excavationDetails.utahComments;
        }
        ticketResponse = await api.ticket.revisionUpdateTicket(body);
        return ticketResponse;
      } else if (state.isEdit === "Digin") {
        let data = {};
        data.excavationDetails = excavationDetails;
        data.geoJson = state.geoJson;
        data.ticketData = ticketData;
        data.isEdit = state.isEdit;
        body = { ...body, ...data };
        body.ticket_id = state.ticketId;
        body.ticket = state.centerTicketId;
        ticketResponse = await api.ticket.revisionDiginTicket(body);
        return ticketResponse;
      } else if (state.isEdit == "DestroyedMarks") {
        let data = {};
        data.excavationDetails = excavationDetails;
        data.geoJson = state.geoJson;
        data.ticketData = ticketData;
        data.isEdit = state.isEdit;
        body = { ...body, ...data };
        body.ticket_id = state.ticketId;
        body.ticket = state.centerTicketId;
        ticketResponse = await api.ticket.revisionDestroyedMarksTicket(body);
        return ticketResponse;
      }
      let reqObj = await secondaryFunctionObject(state, body);
      if (!reqObj) {
        return;
      }
      let resp = await api.ticket.submitRevisionUpdateTicket(reqObj);
      if (resp.status === "error") {
        return resp;
      }
      const revisionTicketData = await api.newtin.getTicketsInfo({
        url: body.url,
        token: body.token,
        ticket: resp.ticket,
      });
      let payload = new Object();
      payload.ticketId = state.ticketId;
      payload.data = { ...resp };
      payload.data.center_ticket_id = revisionTicketData.ticket;
      payload.data.priority = revisionTicketData.priority;
      payload.data.type = revisionTicketData.type;
      payload.data.expiration_date = revisionTicketData.expires;
      payload.data.center_id = reqObj.center_id;
      payload.data.street_address = reqObj.data.street;
      payload.data.state = reqObj.data.state;
      payload.data.place = reqObj.data.place;
      payload.data.county = reqObj.data.county;
      payload.data.user_id = body.user_id;
      payload.data.company_id = body.company_id;
      let options = {};
      options.requestObject = reqObj.data;
      options.excavationDetails = excavationDetails;
      options.geoJson = state.geoJson;
      options.ticketData = ticketData;
      payload.data.options = JSON.stringify(options);
      payload.data.start_date = revisionTicketData.work_date;
      payload.data.receipt = JSON.stringify(revisionTicketData);
      if (body.project_id) payload.data.project_id = body.project_id;
      //payload.token = body.token;
      let ticketResponse;
      if (state.isEdit == "UpdateTicket") {
        ticketResponse = await api.ticket.revisionUpdateTicket(payload);
      } else if (state.isEdit == "DestroyedMarks") {
        ticketResponse = await api.ticket.revisionDestroyedMarksTicket(payload);
      } else if (state.isEdit === "Digin") {
        payload.ticket_id = state.ticketId;
        ticketResponse = await api.ticket.revisionDiginTicket(payload);
      }
      return ticketResponse;
    },
    async suspendTicket({ state }, body) {
      const resp = await api.ticket.submitSuspend(body);
      return resp;
    },
    async getMyTicket({ state }, body) {
      const resp = await api.ticket.getMyTickets(body);
      return resp;
    },
    async getTicketById({ state }, body) {
      const resp = await api.ticket.getTicketById(body);
      return resp;
    },
    async updateTicket({ commit }, payload) {
      const resp = await api.ticket.updateTicket(payload);
      return resp;
    },
    async revisionDeepNoticeTicket({ state }, payload) {
      const resp = await api.ticket.revisionDeepNoticeTicket(payload);
      return resp;
    },
    async revisionUpdateTicket({ state }, payload) {
      const resp = await api.ticket.revisionUpdateTicket(payload);
      return resp;
    },
    async revisionRetransmitTicket({ state }, payload) {
      const resp = await api.ticket.revisionRetransmitTicket(payload);
      return resp;
    },
    async revisionRepeatTicket({ state }, payload) {
      const resp = await api.ticket.revisionRepeatTicket(payload);
      return resp;
    },
    async revisionRemarkTicket({ state }, payload) {
      const resp = await api.ticket.revisionRemarkTicket(payload);
      return resp;
    },
    async revisionCorrectionTicket({ state }, payload) {
      const resp = await api.ticket.revisionCorrectionTicket(payload);
      return resp;
    },

    async revisionAddMembersicket({ state }, payload) {
      const resp = await api.ticket.revisionAddMembersicket(payload);
      return resp;
    },
    async revisionHourNoticeTicket({ state }, payload) {
      const resp = await api.ticket.revisionHourNoticeTicket(payload);
      return resp;
    },
    async revisionExtendTicket({ state }, payload) {
      const resp = await api.ticket.revisionExtendTicket(payload);
      return resp;
    },
    async revisionRelocateRefreshTicket({ state }, payload) {
      const resp = await api.ticket.revisionRelocateRefreshTicket(payload);
      return resp;
    },
    async revisionSecondNoticeTicket({ state }, payload) {
      const resp = await api.ticket.revisionSecondNoticeTicket(payload);
      return resp;
    },
    async revisionNoResponseTicket({ state }, payload) {
      const resp = await api.ticket.revisionNoResponseTicket(payload);
      return resp;
    },
    async revisionUtilityNotFoundTicket({ state }, payload) {
      const resp = await api.ticket.revisionUtilityNotFoundTicket(payload);
      return resp;
    },
    async revisionCancelTicket({ state }, payload) {
      const resp = await api.ticket.revisionCancelTicket(payload);
      return resp;
    },
    async revisionCommentTicket({ state }, payload) {
      const resp = await api.ticket.revisionCommentTicket(payload);
      return resp;
    },
    async legalDatesData({ state }, payload) {
      const resp = await api.ticket.getLegalDate(payload);
      return resp;
    },
  },
  getters: {},
};
