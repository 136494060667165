<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    :max-width="isCompanyInformation ? '80%' : '990px'"
    height="auto"
    class="pa-0"
  >
    <v-card
      :color="$vuetify.breakpoint.mdAndUp ? 'grey lighten-4' : 'white'"
      elevation="0"
    >
      <v-card-title
        class="py-0 px-0 grey--text text--darken-1 font-weight-medium subtitle-1"
      >
        <v-row v-if="$vuetify.breakpoint.smAndDown" no-gutters>
          <v-btn
            v-if="step === 2"
            fab
            text
            absolute
            plain
            left
            class="mt-2 ml-n6"
            @click="step = 1"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >

          <v-col cols="12" class="d-flex flex-column">
            <img
              width="160"
              class="pt-6 py-lg-9 py-md-9 mx-auto pt-sm-9 py-xs-5"
              src="@/assets/images/logo.png"
              alt="Dig Logix Logo"
            />
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.mdAndUp" justify="center" no-gutters>
          <v-col cols="11" class="d-flex flex-column">
            <img
              width="160"
              class="pt-10 py-lg-9 py-md-9 mx-auto pt-sm-9 py-xs-5"
              src="@/assets/images/logo.png"
              alt="Dig Logix Logo"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-card
        class="ma-auto mb-16 pt-6"
        height="auto"
        :width="$vuetify.breakpoint.mdAndUp ? '80%' : '100%'"
        elevation="0"
      >
        <v-row class="px-4">
          <v-btn
            v-if="step === 2 && $vuetify.breakpoint.mdAndUp"
            fab
            text
            absolute
            plain
            class="ml-5 mt-2"
            @click="step = 1"
            ><v-icon left>mdi-arrow-left</v-icon></v-btn
          >
          <v-col class="pa-0">
            <v-row
              class="justify-center grey--text text--darken-1 font-weight-medium subtitle-1 my-6"
            >
              {{ $t("completeYourAccountText") }}</v-row
            ></v-col
          >
        </v-row>
        <CompanySubscription
          v-if="step === 1"
          :subscriptions="subscriptions"
          :default-selected-user="defaultSelectedUser"
          @updateStep="updateStep"
        />
        <CompanyInformation
          v-if="step === 2"
          :subscriptions="subscriptions"
          :selected-subscription="selectedSubscription"
          @submitCompanyDetails="saveCompanyDetails"
        />
      </v-card>

      <v-row class="mt-16">
        <CompanyFooter :show-full-width="true" />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { setAuthDetails } from "@/store/utils/utils";
import UserData from "../../../mixins/UserData.vue";
export default {
  name: "AccountSetupMain",
  components: {
    CompanySubscription: () => import("./companySubscription.vue"),
    CompanyFooter: () => import("../../molecules/CompanyFooter.vue"),
    CompanyInformation: () => import("./CompanyInformation.vue"),
  },
  mixins: [UserData],
  props: {},
  data() {
    return {
      step: 1,
      dialog: true,
      selectedCenter: null,
      selectedExcavatorType: null,
      isCompanyInformation: false,
      subscriptions: [],
      defaultSelectedUser: null,
      selectedSubscription: null,
    };
  },
  computed: {
    isHomeOwner() {
      return this.selectedExcavatorType === "Homeowner" ? true : false;
    },
  },
  async created() {
    const subscriptionResp = await this.$store.dispatch("getsubscriptions");
    if (subscriptionResp) {
      this.subscriptions = await this.mobileScreenOptions(subscriptionResp);
      this.defaultSelectedUser = await this.getDefaultSelectedUser();
    }
  },
  methods: {
    mobileScreenOptions(subscriptionResp) {
      if (Array.isArray(subscriptionResp)) {
        subscriptionResp.forEach((subscription) => {
          let extraFeatureList = [];
          let nonExtraFeatureList = [];
          if (subscription.options && !subscription.is_new) {
            subscription.options.forEach((option) => {
              if (option.extraFeature) extraFeatureList.push(option);
              else nonExtraFeatureList.push(option);
            });
          }
          subscription["extraFeatureList"] = extraFeatureList;
          subscription["nonExtraFeatureList"] = nonExtraFeatureList;
        });
        return subscriptionResp;
      }
    },
    getDefaultSelectedUser() {
      if (this.subscriptions) {
        return this.subscriptions.filter((element) => {
          if (
            element.name === "Pro" &&
            element.users === "2" &&
            !element.is_new
          ) {
            return true;
          }
        })[0];
      }
    },
    updateStep(payload) {
      this.selectedSubscription = payload;
      this.step = 2;
    },
    handleBackButton() {
      this.step = 1;
    },
    async saveCompanyDetails(payload) {
      let companyData = { ...payload };
      companyData.options = { settings: { mfa: true }, eula: companyData.eula };
      companyData.centers = JSON.parse(companyData.centers);
      this.$store.commit("setCompanyData", companyData);
      const CompanyStatus = await this.$store.dispatch(
        "createCompany",
        this.$store.state.Company.company,
      );
      if (CompanyStatus.status !== "error") {
        // This is temporary, this has to be removed after the refresh token is implemented
        CompanyStatus[0].role = ["admin"];
        this.$store.commit("setUserData", CompanyStatus[0]);
        setAuthDetails({
          userId: this.$store.state.User.user.user_id,
          token: CompanyStatus[0].token,
        });
        if (
          companyData.subscription_id === "00000000-0000-0000-0000-000000000000"
        ) {
          this.$router.push("/createTicket").catch();
        }
        this.dialog = false;
      }
    },
  },
};
</script>
