import user from "./user/api";
import auth from "./auth/api";
import company from "./company/api";
import subscription from "./subscription/api";
import newtin from "./newtin/api";
import ticket from "./ticket/api";
import approval from "./approval/approval";
import notification from "./notification/notification";
import project from "./project/api";
import projectTask from "./projectTask/api";
import attachments from "./attachments/api";

export default {
  auth,
  user,
  company,
  subscription,
  newtin,
  ticket,
  approval,
  notification,
  project,
  projectTask,
  attachments,
};
