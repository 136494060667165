import { version } from "../../../package.json";

export default {
  state: {
    drawer: false,
    app: {
      name: "DiG LOGiX",
      appVersion: version,
      theme: "light",
      lastRoute: null,
    },
  },
  mutations: {
    SET_NAVDRAWER(state) {
      state.drawer = !state.drawer;
    },
    SET_LAST_ROUTE(state, val) {
      state.lastRoute = val;
    },
  },
  actions: {
    async toggleNavdrawer({ commit }) {
      commit("SET_NAVDRAWER");
    },
  },
  getters: {
    navdrawerState(state) {
      return state.drawer;
    },
  },
};
