import api from "../api";
import { getAuthDetails } from "../utils/utils";
import rolesAndPermissionsDefaults from "@/static/rolesAndPermissionsDefaults.json";

const getDefaultState = () => {
  return {
    attachments: {},
  };
};

export default {
  state: getDefaultState(),

  mutations: {
    resetState(state) {
      state = Object.assign(state, getDefaultState());
    },
    setTicketAttachmentData(state, attachmentData) {
      state.attachments[attachmentData.referenceId] = attachmentData.files;
    },
  },

  actions: {
    async getAuthTokenForAttachments() {
      const resp = await api.attachments.getAuthTokenForAttachments();
      return resp;
    },
    async uploadImages({ state }, payload) {
      const resp = await api.attachments.uploadImages(payload);
      return resp;
    },
    async getAttachmentsByReferenceId({ state }, payload) {
      const resp = await api.attachments.getAttachmentsByReferenceId(payload);
      return resp;
    },
    async deleteAttachment({ state }, payload) {
      const resp = await api.attachments.deleteAttachment(payload);
      return resp;
    },
    async updateAttachment({ state }, payload) {
      const resp = await api.attachments.updateAttachment(payload);
      return resp;
    },
    async getAttachmentUrlForView({ state }, payload) {
      const resp = await api.attachments.getAttachmentUrlForView(payload);
      return resp;
    },
    async getCompanyAttachmentsbyType({ state }, payload) {
      const resp = await api.attachments.getCompanyAttachmentsbyType(payload);
      return resp;
    },
    async saveAttachmentData({ state }, payload) {
      const resp = await api.attachments.saveAttachmentData(payload);
      return resp;
    },
  },
  getters: {},
};
