import { checkResponse, getHeader } from "../../utils/utils";

const fileUploadUrl = process.env.VUE_APP_ATTACHMENTS_API;

export default {
  async getAuthTokenForAttachments() {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/attachment/token`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      const errorObj = await response.text();
      return Object.assign({ status: "error" }, JSON.parse(errorObj));
    }
  },
  async uploadImages(bodyObject) {
    const token = bodyObject.token;
    const bucket = process.env.VUE_APP_WASABI_BUCKET || "diglogix";
    const formData = new FormData();
    formData.append("file", bodyObject.file);
    formData.append("token", token);
    formData.append("bucket", bucket);
    formData.append("owner", bodyObject.companyId);
    const response = await fetch(`${fileUploadUrl}/upload`, {
      method: "POST",
      body: formData,
    });
    return await checkResponse(response);
  },

  async getAttachmentsByReferenceId(body) {
    // Fetch request options
    const requestOptions = {
      method: "GET",
      headers: getHeader("form", true),
    };

    // Perform the fetch request
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/attachment/${body.referenceId}`,
      requestOptions
    );
    return await checkResponse(response);
  },
  async deleteAttachment(body) {
    const data = {
      attachment_ids: body.attachmentIds,
    };
    const requestOptions = {
      method: "POST",
      headers: getHeader("form", true),
      body: new URLSearchParams(data),
    };
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/attachment/delete`,
      requestOptions
    );
    return await checkResponse(response);
  },
  async updateAttachment(body) {
    let bodyFormData = new URLSearchParams();
    for (let obj in body) {
      if (typeof body[obj] === "object" && ["options"].includes(obj))
        bodyFormData.append(obj, JSON.stringify(body[obj]));
      else bodyFormData.append(obj, body[obj]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/attachment/${body.id}`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async getCompanyAttachmentsbyType(body) {
    // Fetch request options
    const requestOptions = {
      method: "GET",
      headers: getHeader("form", true),
    };

    // Perform the fetch request
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/attachment/companyattachments/${body.referenceType}`,
      requestOptions
    );
    return await checkResponse(response);
  },
  async saveAttachmentData(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body) {
      if (body[key] && typeof body[key] === "object")
        bodyFormData.append(key, JSON.stringify(body[key]));
      else bodyFormData.append(key, body[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/attachment/upload`,
      {
        method: "POST",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
};
