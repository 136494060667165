var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    {
      attrs: {
        nav: "",
        dense: "",
        light: "",
        "aria-label": "Navigation bar List",
        role: "list",
      },
    },
    [
      _c(
        "v-list-item-group",
        {
          attrs: { "aria-label": "Navigation bar items group ", role: "group" },
        },
        _vm._l(_vm.options, function (option, index) {
          return _c(
            "div",
            { key: index },
            [
              !("subOptions" in option)
                ? _c(
                    "v-list-item",
                    {
                      staticClass: "text-subtitle-2 grey--text text--darken-1",
                      attrs: {
                        "active-class":
                          "grey lighten-3 grey--text text--darken-4",
                        role: "listitem",
                        "aria-label": option.label,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.navigateToRoute(option.route)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-3" },
                        [_c("v-icon", [_vm._v(_vm._s(option.icon))])],
                        1
                      ),
                      _c("v-list-item-content", [
                        _vm._v(" " + _vm._s(_vm.$t(option.label)) + " "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              "subOptions" in option
                ? _c(
                    "v-list-group",
                    {
                      attrs: {
                        "active-class":
                          "grey lighten-3 grey--text text--darken-4",
                        "no-action": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item-icon",
                                  { staticClass: "mr-3" },
                                  [_c("v-icon", [_vm._v(_vm._s(option.icon))])],
                                  1
                                ),
                                _c(
                                  "v-list-item-title",
                                  {
                                    staticClass:
                                      "text-subtitle-2 grey--text text--darken-1",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t(option.label))),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    _vm._l(option.subOptions, function (subOption, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i,
                          staticClass: "pl-1 grey--text text--darken-1",
                          attrs: {
                            "active-class":
                              "grey lighten-3 grey--text text--darken-4",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.navigateToRoute(subOption.route)
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "ms-2 mr-2" },
                            [
                              _c("v-icon", { attrs: { size: "12" } }, [
                                _vm._v("mdi-checkbox-blank-circle"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-subtitle-2" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t(subOption.label)) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }