import accessOptions from "@/static/grants.json";
import api from "../api";
import {
  getAuthDetails,
  getAuthStatus,
  removeAuthDetails,
} from "../utils/utils";

const getDefaultState = () => {
  return {
    user: {
      name: "",
      role: "admin",
      email: "",
    },
    centers: [],
    centerMap: {},
    token: "",
    companyUsers: [],
    homeOwners: [],
    isImpersonating: false,
    companyUserMap: {},
  };
};
export default {
  state: getDefaultState(),

  mutations: {
    setUserData(state, userData) {
      state.user = Object.assign({}, state.user, userData);
    },
    setCentersData(state, centerData) {
      state.centers = centerData;
    },
    resetState(state) {
      state = Object.assign(state, getDefaultState());
    },
    setCompanyUsers(state, companyUsersData) {
      state.companyUsers = Object.assign([], companyUsersData);
    },
    setCompanyUserMap(state, companyUserMap) {
      state.companyUserMap = Object.assign([], companyUserMap);
    },
    setCenterMap(state, centerMapData) {
      state.centerMap = Object.assign({}, centerMapData);
    },
    setHomeOwners(state, homeOwnersData) {
      state.homeOwners = [...homeOwnersData];
    },
  },

  actions: {
    async createUser({ commit }, payload) {
      const resp = await api.user.registerUser(payload);
      if (resp && resp.status !== "error") {
        commit("setUserData", resp[0]);
      }
      return resp;
    },
    async inviteUser({ commit }, payload) {
      const resp = await api.user.inviteUser(payload);
      return resp;
    },
    async reInviteUser({ commit }, payload) {
      const resp = await api.user.reInviteUser(payload);
      return resp;
    },
    async authorizeUser({ state }, body) {
      removeAuthDetails();
      const payload = {
        email: body.email || state.user.email,
        password: body.password,
        mfacode: body.mfacode,
      };
      let authStatus = {};
      const resp = await api.auth.authorizeUser(payload);
      authStatus = resp;
      if (resp && resp.token) {
        state.token = resp.token;
        authStatus.status = "success";
      }
      return authStatus;
    },
    async resendMFACode({ dispatch, state }) {
      const resp = await dispatch("createUser", state.user);
      return resp;
    },
    async updateUser({ state, commit }, payload) {
      if (!payload.id) {
        const authDetails = getAuthDetails();
        payload.id = authDetails.userId;
      }
      const resp = await api.user.updateUserDetails(payload);
      if (resp && resp.status !== "error") {
        if (state.user.user_id === payload.id) commit("setUserData", payload);
        const authStatus = getAuthStatus();
        if (!authStatus) {
          localStorage.setItem(
            "authDetails",
            JSON.stringify({ userId: payload.id, token: payload.token })
          );
        }
      }
      return resp;
    },
    async changePassword({ commit }, payload) {
      const resp = await api.user.changeUserPassword(payload);
      return resp;
    },
    async sendPasswordMail({ state }, payload) {
      const resp = await api.user.sendResetPasswordLink(payload);
      return resp;
    },
    async getUserDataById({ state }, payload) {
      const payloadData = payload || getAuthDetails();
      const resp = await api.user.getUserDataById(payloadData);
      return resp;
    },
    async getCompanyUsers({ state }, payload) {
      const resp = await api.user.getCompanyUsers(payload);
      return resp;
    },
    async getCentersData({ state }, payload) {
      const payloadData = payload || getAuthDetails();
      const resp = await api.user.getCentersData();
      return resp;
    },
    async getHomeOwners({ state }) {
      const resp = await api.user.getHomeOwners();
      return resp;
    },
    async getCompanyAdmins({ state }, payload) {
      const resp = await api.user.getCompanyAdmins(payload);
      return resp;
    },
    async getTokenForUser({ state }, payload) {
      const resp = await api.auth.getTokenForUser(payload);
      return resp;
    },
  },

  getters: {
    menuItems: (state) => (rolesAndPermissions) => {
      const persona = state.user.persona;
      const permissions = rolesAndPermissions
        ? rolesAndPermissions[persona]
        : [];
      const menuAccessOption =
        accessOptions.menuAccessOption[persona] ||
        accessOptions.menuAccessOption["admin"];
      let menuItems = menuAccessOption.map((item) => {
        const menuItem = accessOptions.menuOptions[item.name];
        if (
          item.children ||
          accessOptions.menuOptions[item.name].permissionSet.some((item) =>
            permissions.includes(item)
          )
        ) {
          if (item.children) {
            menuItem.subOptions = [];
            item.children.forEach((child) => {
              if (
                accessOptions.menuOptions[child].permissionSet.some((item) =>
                  permissions.includes(item)
                )
              ) {
                menuItem.subOptions.push(accessOptions.menuOptions[child]);
              }
            });
            if (menuItem.subOptions.length === 0) {
              return;
            }
          }
          return menuItem;
        } else {
          return;
        }
      });
      if (!menuItems) return [];
      menuItems = menuItems.filter((item) => {
        return item;
      });
      return menuItems;
    },
    userData(state) {
      return state.user;
    },
    centerMap(state) {
      if (state.centerMap) {
        return state.centerMap;
      } else {
        const centerData = state.centers;
        let centerMapObject = {};
        centerData.forEach((element) => {
          centerMapObject[element.center_id] = element;
        });
        return centerMapObject;
      }
    },
  },
};
