<template>
  <div>
    <v-speed-dial v-model="fab" bottom direction="bottom" transition>
      <template v-slot:activator>
        <v-card
          :width="!$vuetify.breakpoint.smAndDown ? '340' : ''"
          elevation="0"
        >
          <v-row justify="end" class="mr-3">
            <v-btn
              icon
              class="mt-9"
              aria-label="message"
              :disabled="!isInAppNotificationEnabled"
              @click="updateNotifications"
            >
              <v-badge
                v-if="count > 0"
                overlap
                color="#F26227"
                :content="count"
              >
                <v-icon>mdi-bell-outline</v-icon>
              </v-badge>
              <v-icon v-else>mdi-bell-outline</v-icon>
            </v-btn>
          </v-row>
        </v-card>
      </template>
      <v-card
        v-if="!$vuetify.breakpoint.smAndDown && isInAppNotificationEnabled"
        width="340"
        max-height="600"
        class="pb-1 overflow-auto"
      >
        <v-card-title class="pt-1"> Notifications </v-card-title>
        <div v-for="(item, i) in NotificationData" :key="i" class="py-2 px-3">
          <v-row>
            <v-col cols="3" class="px-5 pb-0">
              <v-avatar color="#C8E6C9">
                <v-icon> mdi-ticket-confirmation-outline </v-icon>
              </v-avatar>
            </v-col>
            <v-col cols="9" class="body-2 pb-0">
              <div>
                <span>{{ formatMessage(item.body) }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="p1-1"> </v-col>
            <v-col cols="9" class="pl-4 pt-1 caption">
              {{ item.time }}
            </v-col>
          </v-row>
          <v-row>
            <v-divider></v-divider>
          </v-row>
        </div>
      </v-card>
      <v-card v-else></v-card>
      <v-dialog v-if="$vuetify.breakpoint.smAndDown" v-model="fab" fullscreen>
        <v-card class="pb-1">
          <v-card-title>
            {{ $t("notifications") }}
            <v-spacer></v-spacer>
            <v-btn aria-label="cross" icon @click="fab = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <div v-for="(item, i) in NotificationData" :key="i" class="py-2 px-3">
            <v-row>
              <v-col cols="3" class="px-5 pb-0">
                <v-avatar color="#C8E6C9">
                  <v-icon v-if="item.category === 'ticketCreated'">
                    mdi-ticket-confirmation-outline
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="9" class="body-2 pb-0">
                {{ item.body }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="p1-1"> </v-col>
              <v-col cols="9" class="pt-1 caption">
                {{ item.time }}
              </v-col>
            </v-row>
            <v-row>
              <v-divider></v-divider>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
    </v-speed-dial>
  </div>
</template>

<script>
import moment from "moment";
import { getAuthStatus } from "@/store/utils/utils";
export default {
  data: () => ({
    fab: false,
    NotificationData: [],
    count: 0,
    timer: "",
  }),
  computed: {
    isInAppNotificationEnabled() {
      const user = this.$store.state.User.user;
      const notificationOptions =
        user?.options?.preferences?.notification || [];
      return (
        notificationOptions.filter((item) => {
          return item.inApplication;
        }).length > 0
      );
    },
  },
  watch: {
    isInAppNotificationEnabled() {
      if (this.isInAppNotificationEnabled) {
        const authStatus = getAuthStatus();
        if (authStatus)
          this.timer = setInterval(async () => {
            await this.getNotifications();
          }, 60000);
      }
    },
  },
  async created() {
    this.moment = moment;
    if (this.isInAppNotificationEnabled) {
      const authStatus = getAuthStatus();
      if (authStatus)
        this.timer = setInterval(async () => {
          await this.getNotifications();
        }, 60000);
    }
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
  methods: {
    formatMessage(message) {
      return message.replace(/\n/g, "<br>");
    },
    async updateNotifications() {
      if (!this.fab) {
        let notificationUuids = null;
        this.NotificationData.forEach((item) => {
          if (item.status === "unseen")
            notificationUuids = notificationUuids
              ? notificationUuids + "," + item.id
              : item.id;
        });
        const notificationResp = await this.$store.dispatch(
          "updateNotification",
          { ids: notificationUuids },
        );
        this.getNotifications();
      }
    },
    async getNotifications() {
      const notificationResp = await this.$store.dispatch("getNotifications");
      if (notificationResp) {
        this.count = 0;
        this.NotificationData = notificationResp;
        this.NotificationData.forEach((item) => {
          if (
            moment(new Date()).diff(moment(item.created_at), "minutes") > 60
          ) {
            item.time =
              moment(new Date()).diff(moment(item.created_at), "hours") +
              " hour ago";
            if (moment(new Date()).diff(moment(item.created_at), "hours") > 24)
              item.time =
                moment(new Date()).diff(moment(item.created_at), "days") +
                " day ago";
          } else {
            item.time =
              moment(new Date()).diff(moment(item.created_at), "minutes") +
              " minute ago";
          }
          if (item.status === "unseen") this.count++;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
