import { checkResponse, getHeader } from "../../utils/utils";

export default {
  async getCompanyDataById(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/company/${body.companyId}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async updateCompany(body) {
    let bodyFormData = new URLSearchParams();
    for (let obj in body) {
      if (
        typeof body[obj] === "object" &&
        [
          "options",
          "centers",
          "paymentHistory",
          "paymentMethod",
          "priceList",
          "contactusData",
        ].includes(obj)
      )
        bodyFormData.append(obj, JSON.stringify(body[obj]));
      else bodyFormData.append(obj, body[obj]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/company/update-company/${body.company_id}`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async updateCompanySubscription(body) {
    let bodyFormData = new URLSearchParams();
    for (let obj in body) {
      if (
        typeof body[obj] === "object" &&
        [
          "options",
          "centers",
          "paymentHistory",
          "paymentMethod",
          "priceList",
          "contactusData",
        ].includes(obj)
      )
        bodyFormData.append(obj, JSON.stringify(body[obj]));
      else bodyFormData.append(obj, body[obj]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/company/update-subscription/${body.company_id}`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async sendQuote(body) {
    let bodyFormData = new URLSearchParams();
    for (let obj in body) {
      if (
        typeof body[obj] === "object" &&
        [
          "options",
          "centers",
          "paymentHistory",
          "paymentMethod",
          "priceList",
          "contactusData",
        ].includes(obj)
      )
        bodyFormData.append(obj, JSON.stringify(body[obj]));
      else bodyFormData.append(obj, body[obj]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/company/send-quote/${body.company_id}`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async updateCompanyDetails(body) {
    let bodyFormData = new URLSearchParams();
    for (let obj in body) {
      if (
        typeof body[obj] === "object" &&
        ["options", "centers", "paymentHistory"].includes(obj)
      )
        bodyFormData.append(obj, JSON.stringify(body[obj]));
      else bodyFormData.append(obj, body[obj]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/company/${body.company_id}`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async createCompany(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body) {
      if (body[key] && typeof body[key] === "object")
        bodyFormData.append(key, JSON.stringify(body[key]));
      else bodyFormData.append(key, body[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/company/create-company`,
      {
        method: "POST",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async UpdateSubscription(body) {
    let bodyFormData = new URLSearchParams();
    for (let key in body) {
      if (body[key] && typeof body[key] === "object")
        bodyFormData.append(key, JSON.stringify(body[key]));
      else bodyFormData.append(key, body[key]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/purchasehistory`,
      {
        method: "POST",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      }
    );
    return await checkResponse(response);
  },
  async getCompany() {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/company`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async getPurchaseHistoryById(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/purchasehistory/company/${body.companyId}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async getPurchaseHistory() {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/purchasehistory`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
};
