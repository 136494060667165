var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.showDashboard ? _c("NavDrawer") : _vm._e(),
      _vm.showDashboard ? _c("AppBar") : _vm._e(),
      _c(
        "v-main",
        { class: { "pa-0": !_vm.showDashboard } },
        [
          _vm.showImpersonateAlert
            ? _c(
                "v-alert",
                {
                  staticClass: "caption font-weight-regular py-3 px-5",
                  attrs: {
                    type: "warning",
                    color: "#AD4C0B",
                    icon: "mdi-alert-outline",
                    text: "",
                    height: "44px",
                  },
                },
                [_vm._v(" You're temporarily logged in as another user. ")]
              )
            : _vm._e(),
          _c(
            "v-container",
            {
              class: _vm.showDashboard
                ? _vm.isSubscription
                  ? "fill-height"
                  : ""
                : "pa-0 fill-height",
              attrs: { fluid: "" },
            },
            [
              _c("router-view"),
              _vm.showCenterDialog && _vm.showDashboard
                ? _c("AccountSetupMain")
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showDashboard ? _c("Footer") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }