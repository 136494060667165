import { render, staticRenderFns } from "./ThemeToggleBar.vue?vue&type=template&id=152f2c28&"
import script from "./ThemeToggleBar.vue?vue&type=script&lang=js&"
export * from "./ThemeToggleBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDivider,VFooter,VRow})


/* hot reload */
if (module.hot) {
  var api = require("N:\\diglogix\\diglogix-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('152f2c28')) {
      api.createRecord('152f2c28', component.options)
    } else {
      api.reload('152f2c28', component.options)
    }
    module.hot.accept("./ThemeToggleBar.vue?vue&type=template&id=152f2c28&", function () {
      api.rerender('152f2c28', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/ThemeToggleBar.vue"
export default component.exports