import { checkResponse, getHeader } from "../../utils/utils";

export default {
  async getNotification() {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/appNotification`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async updateNotification(body) {
    let bodyFormData = new URLSearchParams();
    for (let obj in body) {
      if (
        typeof body[obj] === "object" &&
        ["options", "comments"].includes(obj)
      )
        bodyFormData.append(obj, JSON.stringify(body[obj]));
      else bodyFormData.append(obj, body[obj]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/appNotification`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
};
