import { checkResponse, getHeader, getNewtinDetails } from "../../utils/utils";

const geoLocatorApiUrl = process.env.VUE_APP_NEWTIN_GEOLOCATOR_API;
export default {
  async authorizeNewtin(body) {
    const payload = {
      username: body.username,
      password: body.password,
    };
    const response = await fetch(`${body.base_url}/login`, {
      method: "POST",
      body: new URLSearchParams(payload),
      headers: getHeader("form"),
    });
    if (response.ok) {
      return response.json();
    } else {
      const errorObj = await response.text();
      return Object.assign({ status: "error" }, JSON.parse(errorObj));
    }
  },
  async getTypeOfWork(body) {
    let apiUrl = `${process.env.VUE_APP_DIGLOGIX_API}/ticket/work-type?center=${body.center}`;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: getHeader("form", true),
    });
    return await checkResponse(response);
  },
  async getMeansOfExcavation(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/means-of-excavation?center=${body.center}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async getCounties(bodyObject) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/location/counties?center=${bodyObject.center}&state=${bodyObject.state}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },

  async getPlaces(bodyObject) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/location/places?center=${bodyObject.center}&state=${bodyObject.state}&county=${bodyObject.county}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async getAddress(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.body.center];
    const response = await fetch(
      `${geoLocatorApiUrl}/geocoder/addresslookup?center=${bodyObject.body.center}&address=${bodyObject.body.autoSuggestAddress}`,
      {
        method: "GET",
        headers: getHeader("json", true, token),
      }
    );
    return await checkResponse(response, true);
  },

  async getAutoSuggestion(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.body.center];
    const response = await fetch(
      `${geoLocatorApiUrl}/geocoder/suggestions?center=${bodyObject.body.center}&address=${bodyObject.body.autoSuggestAddress}`,
      {
        method: "GET",
        headers: getHeader("json", true, token),
      }
    );
    return await checkResponse(response);
  },
  async getAutoSuggestionCross(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.body.center];
    const response = await fetch(
      `${geoLocatorApiUrl}/geocoder/intersections?center=${
        bodyObject.body.center
      }&address=${encodeURIComponent(bodyObject.body.address)}`,
      {
        method: "GET",
        headers: getHeader("json", true, token),
      }
    );
    return await checkResponse(response);
  },
  async getAutoSuggestionBetween(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.body.center];
    const response = await fetch(
      `${geoLocatorApiUrl}/geocoder/between?center=${
        bodyObject.body.center
      }&address=${encodeURIComponent(bodyObject.body.address)}`,
      {
        method: "GET",
        headers: getHeader("json", true, token),
      }
    );
    return await checkResponse(response);
  },
  async getCountyPlacesWithReverseGeocode(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.body.center];
    const response = await fetch(
      `${geoLocatorApiUrl}/geocoder/reversegeocode?center=${bodyObject.body.center}&layers=counties,location,street&geom=SRID=4326;${bodyObject.body.geom}`,
      {
        method: "GET",
        headers: getHeader("json", false, token),
      }
    );
    return await checkResponse(response);
  },
  async getGeoCoderDescribe(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.body.center];
    const response = await fetch(
      `${geoLocatorApiUrl}/geocoder/describe?center=${bodyObject.body.center}&distance=150&extent=1320&details=false&geom=SRID=4326;${bodyObject.body.geom}`,
      {
        method: "GET",
        headers: getHeader("json", false, token),
      }
    );
    return await checkResponse(response);
  },
  async getTickets(bodyObject) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/near-ticket?center=${
        bodyObject.center
      }&point=${encodeURIComponent(
        JSON.stringify(bodyObject.point)
      )}&radius=500&start=${bodyObject.start.toISOString().split("T")[0]}&end=${
        new Date().toISOString().split("T")[0]
      }`,
      {
        method: "GET",
        headers: getHeader("json", true),
      }
    );
    return await checkResponse(response);
  },
  async getTicketsInfo(bodyObject) {
    let apiUrl = `${process.env.VUE_APP_DIGLOGIX_API}/ticket/newtinTicket`;
    if (bodyObject.revision)
      apiUrl =
        apiUrl +
        `?ticket=${bodyObject.ticket}&revision=${bodyObject.revision}&center=${bodyObject.center}`;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: getHeader("json", true),
    });
    return await checkResponse(response);
  },
  async cancelTicket(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.center];
    const response = await fetch(
      `${bodyObject.url.base_url}/tickets/${bodyObject.ticket}/revisions/cancel`,
      {
        method: "POST",
        body: new URLSearchParams(bodyObject.body),
        headers: getHeader("form", true, token),
      }
    );

    return await checkResponse(response);
  },
  async commentTicket(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.center];
    const response = await fetch(
      `${bodyObject.url.base_url}/tickets/${bodyObject.ticket}/revisions/comment`,
      {
        method: "POST",
        body: new URLSearchParams(bodyObject.body),
        headers: getHeader("form", true, token),
      }
    );

    return await checkResponse(response);
  },
  async getMembersDetailsInCounty(body) {
    const newtinDetails = getNewtinDetails();
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/ticket/county-members/?state=${body.state}&county=${body.county}&center=${body.center}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      }
    );
    return await checkResponse(response);
  },
  async extendTicketJulie(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.center];
    let payload = {
      contact: bodyObject.contact,
      remarks: bodyObject.remarks,
      comments: bodyObject.comments,
      phone_ext: bodyObject.phone_ext,
      needRefresh: bodyObject.needRefresh,
      waiveClear: bodyObject.waiveClear,
      membersToAdd: bodyObject.membersToAdd,
    };
    if (bodyObject.phone) payload.phone = bodyObject.phone;

    const response = await fetch(
      `${bodyObject.url.base_url}/tickets/${bodyObject.ticket}/revisions/extend`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: getHeader("json", true, token),
      }
    );

    return await checkResponse(response);
  },
  async secondNoticeTicketJulie(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.center];
    let payload = {
      reason: bodyObject.reason,
      remarks: bodyObject.remarks,
      workDate: bodyObject.workDate,
      membersToNotify: bodyObject.membersToNotify,
    };
    const response = await fetch(
      `${bodyObject.url.base_url}/tickets/${bodyObject.ticket}/revisions/second-notice`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: getHeader("json", true, token),
      }
    );
    return await checkResponse(response);
  },
  async secondNoticeTicketCO(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.center];
    let payload = {
      reason: bodyObject.reason,
      membersToNotify: bodyObject.membersToNotify,
      caller: bodyObject.caller,
      location: bodyObject.location,
    };
    const response = await fetch(
      `${bodyObject.url.base_url}/tickets/${bodyObject.ticket}/revisions/second-notice`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: getHeader("json", true, token),
      }
    );
    return await checkResponse(response);
  },
  async utilityNotFoundTicketCO(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.center];
    let payload = {
      membersToNotify: bodyObject.membersToNotify,
      caller: bodyObject.caller,
      utilityType: bodyObject.utilityType,
    };
    const response = await fetch(
      `${bodyObject.url.base_url}/tickets/${bodyObject.ticket}/revisions/utility-not-found`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: getHeader("json", true, token),
      }
    );
    return await checkResponse(response);
  },
  async relocateRefreshTicket(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.center];
    let payload = {
      membersToNotify: bodyObject.membersToNotify,
      location: bodyObject.location,
    };
    if (bodyObject && bodyObject.caller) payload.caller = bodyObject.caller;
    const response = await fetch(
      `${bodyObject.url.base_url}/tickets/${bodyObject.ticket}/revisions/relocate-refresh`,
      {
        method: "POST",
        body: new URLSearchParams(payload),
        headers: getHeader("form", true, token),
      }
    );
    return await checkResponse(response);
  },
  async retransmitTicket(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.center];
    let payload = {
      caller: bodyObject.caller,
      done_for: bodyObject.done_for,
      contact: bodyObject.contact,
      subcontractor: bodyObject.subcontractor,
      retransmitRemarks: bodyObject.retransmitRemarks,
      comments: bodyObject.comments,
      membersToNotify: bodyObject.membersToNotify,
      membersToAdd: bodyObject.membersToAdd,
    };
    if (bodyObject.contact_phone)
      payload.contact_phone = bodyObject.contact_phone;
    if (bodyObject.subcontractor_phone)
      payload.subcontractor_phone = bodyObject.subcontractor_phone;

    const response = await fetch(
      `${bodyObject.url.base_url}/tickets/${bodyObject.ticket}/revisions/retransmit`,
      {
        method: "POST",
        body: new URLSearchParams(payload),
        headers: getHeader("form", true, token),
      }
    );

    return await checkResponse(response);
  },
  async repeatTicket(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.center];

    const response = await fetch(
      `${bodyObject.url.base_url}/tickets/${bodyObject.ticket}/revisions/repeat`,
      {
        method: "POST",
        body: new URLSearchParams(bodyObject.body),
        headers: getHeader("form", true, token),
      }
    );

    return await checkResponse(response);
  },
  async addMembersTicket(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.center];

    const response = await fetch(
      `${bodyObject.url.base_url}/tickets/${bodyObject.ticket}/revisions/add-members`,
      {
        method: "POST",
        body: new URLSearchParams(bodyObject.body),
        headers: getHeader("form", true, token),
      }
    );

    return await checkResponse(response);
  },
  async hourNoticeTicket(bodyObject) {
    const newtinDetails = getNewtinDetails();
    let token = newtinDetails[bodyObject.center];
    let payload = {
      caller: bodyObject.caller,
      contact: bodyObject.contact,
      subcontractor: bodyObject.subcontractor,
      remarks: bodyObject.remarks,
      comments: bodyObject.comments,
    };
    if (bodyObject.contact_phone)
      payload.contact_phone = bodyObject.contact_phone;
    if (bodyObject.subcontractor_phone)
      payload.subcontractor_phone = bodyObject.subcontractor_phone;

    const response = await fetch(
      `${bodyObject.url.base_url}/tickets/${bodyObject.ticket}/revisions/three-hour-notice`,
      {
        method: "POST",
        body: new URLSearchParams(payload),
        headers: getHeader("form", true, token),
      }
    );

    return await checkResponse(response);
  },
  async secondaryFunctionsEligibility(bodyObject) {
    let apiUrl = `${process.env.VUE_APP_DIGLOGIX_API}/ticket/secondary-functions?ticket=${bodyObject.ticket}&revision=${bodyObject.revision}&center=${bodyObject.center}`;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: getHeader("json", true),
    });
    return await checkResponse(response);
  },
  async secondaryFunctionsAllowed(bodyObject) {
    let apiUrl = `${process.env.VUE_APP_DIGLOGIX_API}/ticket/secondary-functions?ticket=${bodyObject.ticket}&revision=${bodyObject.revision}&ticket_type=${bodyObject.ticket_type}&center=${bodyObject.center}`;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: getHeader("json", true),
    });
    return await checkResponse(response);
  },
};
