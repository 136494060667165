import { render, staticRenderFns } from "./OptionsList.vue?vue&type=template&id=5b4feafa&v-slot%3Aactivator=%7B%20on%2C%20attrs%20%7D&"
import script from "./OptionsList.vue?vue&type=script&lang=js&"
export * from "./OptionsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VList,VListGroup,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("N:\\diglogix\\diglogix-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b4feafa')) {
      api.createRecord('5b4feafa', component.options)
    } else {
      api.reload('5b4feafa', component.options)
    }
    module.hot.accept("./OptionsList.vue?vue&type=template&id=5b4feafa&v-slot%3Aactivator=%7B%20on%2C%20attrs%20%7D&", function () {
      api.rerender('5b4feafa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/OptionsList.vue"
export default component.exports