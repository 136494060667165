import { checkResponse, getHeader } from "../../utils/utils";

export default {
  async authorizeUser(body) {
    const payload = { email: body.email, password: body.password };
    if (body.mfacode) payload.mfacode = body.mfacode;
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/auth/token`,
      {
        method: "POST",
        body: new URLSearchParams(payload),
        headers: getHeader("form"),
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      const errorObj = await response.text();
      return Object.assign({ status: "error" }, JSON.parse(errorObj));
    }
  },
  async getTokenForUser(payload) {
    const options = {
      method: "GET",
      headers: getHeader("form", true),
    };
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/user/getToken/${payload.userId}`,
      options
    );
    return await checkResponse(response);
  },
};
