import api from "../api";
import { getAuthDetails } from "../utils/utils";

const getDefaultState = () => {
  return {
    project: [],
    projectTickets: {},
  };
};

export default {
  state: getDefaultState(),

  mutations: {
    setProjectData(state, project) {
      state.project = Object.assign([], state.project, project);
    },
    resetState(state) {
      state = Object.assign(state, getDefaultState());
    },
    setAllProjects(state, allProjectData) {
      state.allProjects = allProjectData;
    },
    setProjectTickets(state, ticketsData) {
      state.projectTickets[ticketsData.project_id] = ticketsData.tickets;
    },
  },

  actions: {
    async createProject({ commit }, payload) {
      const authDetails = getAuthDetails();
      payload.token = authDetails.token;
      const resp = await api.project.createProject(payload);
      return resp;
    },
    async getProjectDataById({ state }, payload) {
      const payloadData = payload;
      const resp = await api.project.getProjectDataById(payloadData);
      return resp;
    },
    async updateProject({ commit }, payload) {
      const authDetails = getAuthDetails();
      payload.token = authDetails.token;
      const resp = await api.project.updateProjectDetails(payload);
      return resp;
    },
    async getCompanyProject({ state }) {
      const resp = await api.project.getCompanyProject();
      return resp;
    },
  },
  getters: {},
};
