import { checkResponse, checkResponseOk, getHeader } from "../../utils/utils";

export default {
  async registerUser(body) {
    const response = await fetch(`${process.env.VUE_APP_DIGLOGIX_API}/user`, {
      method: "POST",
      body: new URLSearchParams(body),
      headers: getHeader("form"),
    });
    return await checkResponse(response);
  },
  async updateUserDetails(body) {
    let bodyFormData = new URLSearchParams();
    for (let obj in body) {
      if (typeof body[obj] === "object" && ["options"].includes(obj))
        bodyFormData.append(obj, JSON.stringify(body[obj]));
      else bodyFormData.append(obj, body[obj]);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/user/${body.id}`,
      {
        method: "PUT",
        body: bodyFormData,
        headers: getHeader("form", true, body.token),
      },
    );
    return await checkResponse(response);
  },
  async changeUserPassword(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/password/changePassword`,
      {
        method: "POST",
        body: new URLSearchParams({
          oldPassword: body.oldPassword,
          newPassword: body.newPassword,
        }),
        headers: getHeader("form", true),
      },
    );
    return await checkResponse(response);
  },
  async sendResetPasswordLink(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/password/resetPassword`,
      {
        method: "POST",
        body: new URLSearchParams({
          email: body.email,
        }),
        headers: getHeader("form"),
      },
    );
    return await checkResponse(response);
  },
  async getUserDataById(body) {
    const options = {
      method: "GET",
      headers: getHeader("form", true),
    };
    if (body.token) {
      options.headers = getHeader("form", true, body.token);
    }
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/user/${body.userId}`,
      options,
    );
    return await checkResponse(response);
  },
  async getCompanyUsers(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/user/company/${body.company_id}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      },
    );
    return await checkResponse(response);
  },
  async getCentersData() {
    const response = await fetch(`${process.env.VUE_APP_DIGLOGIX_API}/center`, {
      method: "GET",
      headers: getHeader("form", true),
    });
    return await checkResponse(response);
  },
  async getHomeOwners() {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/user/homeowners`,
      {
        method: "GET",
        headers: getHeader("form", true),
      },
    );
    return await checkResponse(response);
  },
  async getCompanyAdmins(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/user/companyadmins/${body.company_id}`,
      {
        method: "GET",
        headers: getHeader("form", true),
      },
    );
    return await checkResponse(response);
  },
  async inviteUser(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/user/invite`,
      {
        method: "POST",
        body: new URLSearchParams(body),
        headers: getHeader("form", true, body.token),
      },
    );
    return await checkResponse(response);
  },
  async reInviteUser(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/user/${body.id}/reinvite`,
      {
        method: "PUT",
        body: new URLSearchParams(body),
        headers: getHeader("form", true, body.token),
      },
    );
    return await checkResponse(response);
  },
  async deleteUser(body) {
    const response = await fetch(
      `${process.env.VUE_APP_DIGLOGIX_API}/user/${body.id}`,
      {
        method: "DELETE",
        headers: getHeader("form", true, body.token),
      },
    );
    return await checkResponse(response);
  },
};
