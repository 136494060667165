<template>
  <v-app-bar app elevation="0" color="white" class="appbar-header">
    <v-icon
      v-if="isMobile"
      role="button"
      aria-label="navigation open"
      @click="toggleDrawer"
      >{{ "mdi-menu" }}</v-icon
    >
    <v-icon
      v-else
      role="button"
      class="ml-3"
      :aria-label="drawer ? 'navigation close' : 'navigation open'"
      @click="toggleDrawer"
      >{{ drawer ? "mdi-menu" : "mdi-menu-open" }}</v-icon
    >
    <div class="my-2">
      <v-img
        v-if="$vuetify.breakpoint.mobile"
        src="@/assets/images/logoCropped.png"
        height="auto"
        max-width="31"
      ></v-img>
    </div>
    <span
      v-if="!$vuetify.breakpoint.mobile"
      class="grey--text text-h6 text--darken-4 ml-6"
      >{{ companyName }}</span
    >
    <v-spacer></v-spacer>
    <!--TODO remove below to enable envelope icon on screen headers -->
    <!-- <v-btn
      v-if="!$vuetify.breakpoint.mobile || !drawer"
      icon
      aria-label="notification"
    >
      <v-badge color="orange" overlap dot :content="msgCount">
        <v-icon>mdi-email-outline</v-icon>
      </v-badge>
    </v-btn> -->
    <Notification v-if="!$vuetify.breakpoint.mobile || !drawer" />
    <div
      v-if="!$vuetify.breakpoint.mobile"
      class="mr-4 float-right"
      tabindex="0"
    >
      <div class="grey--text text--darken-4 h6">
        {{ user.name }}
      </div>
      <div class="grey--text text--darken-1 h5 float-right">
        {{ $t(persona) }}
      </div>
    </div>
    <UserMenu />
  </v-app-bar>
</template>

<script>
import Notification from "./Notification.vue";
import UserMenu from "./UserMenu";
import UserDataMixin from "@/mixins/UserData";
export default {
  name: "AppBar",
  mixins: [UserDataMixin],

  components: {
    UserMenu,
    Notification,
  },
  data: () => ({}),
  computed: {
    drawer() {
      return this.$store.state.App.drawer;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    msgCount() {
      return this.$store.state.User.msgCount;
    },
    companyName() {
      return this.$store.state.Company.company.name;
    },
    user() {
      return this.$store.state.User.user;
    },
    persona() {
      let persona = this.$store.state.User.user.persona;
      return persona;
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.commit("SET_NAVDRAWER", !this.drawer);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/settings.scss";
.appbar-header.white {
  border: 1px solid $grey-lighten-2 !important;
}
</style>
