import Vue from "vue";
import VueRouter from "vue-router";
import { getAuthDetails, getAuthStatus } from "../store/utils/utils";
import ViewTicket from "../components/workflow/ticket/ViewTicket.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Signin" },
  },
  {
    path: "/signin",
    name: "Signin",

    component: () => import("../components/workflow/signIn/signin-main.vue"),
  },
  {
    path: "/contact-us",
    name: "ContactUs",

    component: () => import("../components/workflow/signUpNew/contact-us.vue"),
  },
  {
    path: "/signup",
    name: "Signup",

    component: () => import("../components/workflow/signUpNew/signup-main.vue"),
  },
  {
    path: "/password",
    name: "Password",
    component: () =>
      import("../components/workflow/password/password-main.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../components/workflow/dashboard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/tickets",
    name: "tickets",
    component: () => import("../components/workflow/ticket/ticket.vue"),
    meta: { requiresAuth: true, permissions: ["viewTickets"] },
  },
  {
    path: "/createTicket",
    name: "createTicket",
    component: () => import("../components/workflow/ticket/create-ticket.vue"),
    meta: { requiresAuth: true, permissions: ["createTickets"] },
  },
  {
    path: "/projects",
    name: "projects",
    component: () => import("../components/workflow/project/Project.vue"),
    meta: { requiresAuth: true, permissions: ["viewProject"] },
  },
  {
    path: "/projectDetails",
    name: "projectDetails",
    component: () =>
      import("../components/workflow/project/ProjectDetails.vue"),
    meta: { requiresAuth: true, permissions: ["viewProject"] },
  },
  {
    path: "/companyInfo",
    component: () => import("../components/workflow/company/CompanyInfo.vue"),
    meta: { requiresAuth: true, permissions: ["viewCompany"] },
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: () => import("../components/workflow/company/Subscription.vue"),
    meta: { requiresAuth: true, permissions: ["viewSubscription"] },
  },
  {
    path: "/users",
    component: () => import("../components/workflow/company/Users.vue"),
    meta: { requiresAuth: true, permissions: ["viewUser"] },
  },
  {
    path: "/rolesAndPermissions",
    component: () =>
      import("../components/workflow/company/RolesAndPermissions.vue"),
    meta: {
      requiresAuth: true,
      permissions: ["viewRoles", "viewRolesAndPermissions"],
    },
  },
  {
    path: "/companies",
    component: () => import("../components/workflow/system/Companies.vue"),
    meta: { requiresAuth: true, permissions: ["manageCompanies"] },
  },
  {
    path: "/homeOwners",
    component: () => import("../components/workflow/system/HomeOwners.vue"),
    meta: { requiresAuth: true, permissions: ["manageHomeowners"] },
  },
  {
    path: "/UserSettings",
    component: () =>
      import("../components/workflow/userSettings/UserSettings.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/AccessDenied",
    name: "AccessDenied",
    component: () => import("../components/workflow/AccessDenied.vue"),
  },
  {
    path: "/disableAccess",
    name: "disableAccess",
    component: () => import("../components/workflow/disableAccess.vue"),
  },
  {
    path: "/feedback",
    name: "feedback",
    beforeEnter() {
      window.open("https://diglogix.airfocus.site/feedback/your-ideas.","_blank");
    },
    permissions:[ViewTicket]
  },
  {
    path: "/helpAndSupport",
    name: "helpAndSupport",
    beforeEnter() {
      window.open("https://norfield.freshdesk.com/a/solutions/categories/150000215651/folders/150000540596","_blank");
    },
    permissions:[ViewTicket]
  }
  
];
const router = new VueRouter({
  mode: "history",
  routes,
});
router.beforeEach(async (to, from, next) => {
  let isAuthenticated = false;
  let authDetails = getAuthDetails();
  if (authDetails && authDetails.token) {
    isAuthenticated = getAuthStatus();
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      if (to.meta.permissions) {
        if (checkAccessPermission(to.meta.permissions)) {
          next();
        } else {
          router.replace("/AccessDenied");
        }
      } else {
        next();
      }
    } else {
      if (to.path !== "signin") {
        router.push("/signin").catch();
      }
    }
  } else {
    next();
  }
});
function checkAccessPermission(routePermissions) {
  const permissions = JSON.parse(localStorage.permissions) || [];
  return permissions.some((element) => routePermissions.includes(element));
}
export default router;
