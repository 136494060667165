import api from "../api";

const getDefaultState = () => {
  return {
    notifications: {},
  };
};
export default {
  state: getDefaultState(),

  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    resetState(state) {
      state = Object.assign(state, getDefaultState());
    },
  },

  actions: {
    async getNotifications({ state }) {
      const resp = await api.notification.getNotification();
      return resp;
    },
    async updateNotification({ state }, payload) {
      const resp = await api.notification.updateNotification(payload);
      return resp;
    },
  },
  getters: {},
};
