import api from "../api";

const getDefaultState = () => {
  return {
    history: {},
  };
};

export default {
  state: getDefaultState(),

  mutations: {
    setTaskHistoryData(state, taskHistory) {
      state.history = Object.assign([], state.history, taskHistory);
    },
    resetState(state) {
      state = Object.assign(state, getDefaultState());
    },
  },

  actions: {
    async getHistoryByTaskId({ state }, payload) {
      const payloadData = payload;
      const resp = await api.project.getHistoryByTaskId(payloadData);
      return resp;
    },
  },
  getters: {},
};
