<template>
  <v-footer absolute class="text-center pa-0">
    <span class="caption">
      {{ appVersion }}
    </span>
    <span class="caption mx-auto">
      {{ $t("footerText") }}
    </span>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({}),
  computed: {
    appVersion() {
      return this.$store.state.App.appver;
    },
  },
};
</script>
