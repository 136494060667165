import api from "../api";

const getDefaultState = () => {
  return {
    approvalDetails: {},
  };
};
export default {
  state: getDefaultState(),

  mutations: {
    setApprovalDetails(state, approvalData) {
      state.approvalDetails = approvalData;
    },
    resetState(state) {
      state = Object.assign(state, getDefaultState());
    },
  },

  actions: {
    async addApproval({ commit }, payload) {
      const resp = await api.approval.addApproval(payload);
      return resp;
    },
    async getAwaitingApprovals({ state }) {
      const resp = await api.approval.getAwaitingApprovals();
      return resp;
    },
    async getPendingApprovals({ state }, payload) {
      const resp = await api.approval.getPendingApprovals(payload);
      return resp;
    },
    async updateApproval({ state }, payload) {
      const resp = await api.approval.updateApproval(payload);
      return resp;
    },
  },
  getters: {},
};
