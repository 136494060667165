import { render, staticRenderFns } from "./Notification.vue?vue&type=template&id=29c91686&scoped=true&"
import script from "./Notification.vue?vue&type=script&lang=js&"
export * from "./Notification.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29c91686",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
installComponents(component, {VAvatar,VBadge,VBtn,VCard,VCardTitle,VCol,VDialog,VDivider,VIcon,VRow,VSpacer,VSpeedDial})


/* hot reload */
if (module.hot) {
  var api = require("N:\\diglogix\\diglogix-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('29c91686')) {
      api.createRecord('29c91686', component.options)
    } else {
      api.reload('29c91686', component.options)
    }
    module.hot.accept("./Notification.vue?vue&type=template&id=29c91686&scoped=true&", function () {
      api.rerender('29c91686', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/Notification.vue"
export default component.exports