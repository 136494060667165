<script>
import {
  setAuthStatus,
  setAuthDetails,
  setPermissions,
  setSubscription,
  getSubscription,
} from "@/store/utils/utils";
import ERRORS from "@/static/errors.json";
import moment from "moment";

export default {
  created() {
    this.moment = moment;
  },
  methods: {
    async fetchUserData(loggedinStatus) {
      if (loggedinStatus) {        
        const userData = await this.$store.dispatch("getUserDataById");
        if (userData.status !== "error") {
          this.$store.commit("setUserData", userData[0]);
          setPermissions(userData[0].permissions);
          if (
            this.$store.state.User.user.role[0] === "sysop" &&
            (this.$route.name === "Signin" ||
              (this.$route.name === "Signup" &&
                this.$store.state.User.user.status === "active"))
          ) {
            this.$router.push("/companyInfo").catch();
          } else if (
            this.$route.name === "Signin" ||
            ((this.$route.name === "Signup" ||
              this.$route.name === "ContactUs") &&
              this.$store.state.User.user.status === "active" &&
              this.$store.state.User.user.company_id !==
                "00000000-0000-0000-0000-000000000000")
          ) {
            this.$router.push("/tickets").catch();
          }
          if (
            this.$store.state.User.user.company_id !==
            "00000000-0000-0000-0000-000000000000"
          )
            await this.fetchCompanyData();
        } else if (
          userData.error === ERRORS.AUTHORIZATION_REQUIRED ||
          userData.error === ERRORS.JWT_EXPIRED ||
          userData.error === ERRORS.INVALID_TOKEN ||
          userData.error.includes(ERRORS.UNEXPECTED_TOKEN)
        ) {
          setAuthStatus(false);
        }
      }
    },
    async fetchCentersData() {
      const centerData = await this.$store.dispatch("getCentersData");
      if (centerData.status !== "error") {
        this.$store.commit("setCentersData", centerData);
        let centerObject = {};
        centerData.forEach((element) => {
          centerObject[element.center_id] = element;
        });
        this.$store.commit("setCenterMap", centerObject);
      }
    },
    async fetchSubscriptiosData() {
      const subscriptionData = await this.$store.dispatch("getsubscriptions");
      if (subscriptionData.status !== "error") {
        let subscriptionObject = {};
        subscriptionData.forEach((element) => {
          subscriptionObject[element.subscription_id] = element;
        });
        this.$store.commit("setSubscriptionMap", subscriptionObject);
        this.$store.commit("setSubscriptions", subscriptionData);
      }
    },
    async fetchCompanyData() {
      const CompanyData = await this.$store.dispatch("getCompanyDataById", {
        companyId: this.$store.state.User.user.company_id,
      });
      if (CompanyData.status !== "error") {
        await this.$store.commit("setCompanyData", CompanyData[0]);
        let centerObject = {};
        if (this.$store.state.User.user.role[0] !== "sysop") {
          CompanyData[0].centers.forEach((element) => {
            centerObject[element.center_id] = element;
          });
          if (CompanyData[0].additionalCenters)
            CompanyData[0].additionalCenters.forEach((element) => {
              centerObject[element.center_id] = element;
            });
          this.$store.commit("setCenterMap", centerObject);
        }
        this.fetchCompanyUserData();
      }
    },
    async fetchCompanyUserData() {
      const userData = await this.$store.dispatch("getCompanyUsers", {
        company_id: this.$store.state.User.user.company_id,
      });
      let companyUserMap = {};
      if (userData.status !== "error") {
        userData.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        userData.forEach((item) => {
          item.created_at = this.moment(item.created_at).format(
            "MM/DD/YYYY hh:mm A"
          );
          item.updated_at = this.moment(item.updated_at).format(
            "MM/DD/YYYY hh:mm A"
          );
          companyUserMap[item.user_id] = item;
        });
        this.$store.commit("setCompanyUsers", userData);
        this.$store.commit("setCompanyUserMap", companyUserMap);
      }
    },
    newtinAuthorizeCentersList() {
      let centersList = [];
      if (
        Object.keys(this.$store.state.Company.company).length > 0 &&
        this.$store.state.Company.company.centers &&
        this.$store.state.Company.company.centers.length > 0
      ) {
        centersList = this.$store.state.Company.company.centers.map((item) => {
          return {
            centerName: this.$store.state.User.centerMap[item.center_id].abbrev,
            center_id: item.center_id,
            excavator_type: item.excavator_type,
          };
        });
      }
      return centersList;
    },
    async impersonateUser(userId) {
      const authData = await this.$store.dispatch("getTokenForUser", {
        userId: userId,
      });
      if (authData.status !== "error") {
        this.$store.commit("resetState");
        setAuthDetails({
          userId: authData.user_id,
          token: authData.token,
          changeAuthStatus: false,
        });
        await this.fetchUserData(true);
        this.$store.state.User.isImpersonating = true;
        localStorage.setItem("isPersonateUser", true);
        this.$router.push("/tickets").catch();
      }
    },
    async fetchProductsData() {
      const subscriptionData = await this.$store.dispatch(
        "getSubscriptionProductsData"
      );
      if (subscriptionData.status !== "error") {
        setSubscription(subscriptionData);
      }
    },
    async setProductData() {
      let subscriptionData = {};
      if (getSubscription()) subscriptionData = getSubscription();
      else {
        await this.fetchProductsData();
        subscriptionData = getSubscription();
      }
      let subscriptionObject = {};
      subscriptionData.subscriptions.forEach((element) => {
        let products = {};
        let storage = [];
        subscriptionData.products.forEach((item) => {
          let price;
          price = subscriptionData.prices.find((obj) => {
            return obj.id == item.default_price;
          });
          item.price = price;
          if (item.id === element.products.baseProduct) {
            products.baseProduct = item;
          }
          if (item.id === element.products.addOn) {
            products.addOn = item;
          }
          if (item.id === element.products.center) {
            products.center = item;
          }
          if (
            element.products.storage &&
            element.products.storage.includes(item.id)
          ) {
            storage.push(item);
          }
        });
        if (storage.length) {
          products.storage = storage;
        }
        if (Object.keys(products).length) {
          element.productsData = products;
        }
        subscriptionObject[element.subscription_id] = element;
      });
      this.$store.commit("setSubscriptionMap", subscriptionObject);
      this.$store.commit("setSubscriptionProduct", subscriptionData);
    },
    checkPermission(permission) {
      const user = this.$store.state.User.user;
      const company = this.$store.state.Company.company;
      if (
        !company.options ||
        !company.options?.rolesAndPermissions ||
        !company.options?.rolesAndPermissions[user.persona]
      )
        return false;
      const permissions = company.options?.rolesAndPermissions[user.persona];
      return permissions.includes(permission);
    },
    featureEnabled(permission) {
      const company = this.$store.state.Company.company;
      if (!company.options || !company.options?.disableRolesAndPermissions)
        return true;
      const permissions = company.options?.disableRolesAndPermissions;
      return !permissions.includes(permission);
    },
  },
};
</script>
