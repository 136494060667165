<template>
  <v-menu bottom left offset-y min-width="200" tabindex="0">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        elevation="0"
        class="ma-0 pa-0 text-none"
        height="42"
        min-width="42"
        v-on="on"
      >
        <v-avatar
          class="avtar-user avatars-group light-blue lighten-4"
          size="42"
        >
          <slot></slot>
          <span class="grey--text text--darken-4">{{ nameInitials }}</span>
        </v-avatar>
      </v-btn>
    </template>
    <div v-if="$vuetify.breakpoint.mobile" class="px-2 py-3 d-flex menu white">
      <v-avatar class="avtar-user avatars-group light-blue lighten-4" size="42">
        <span class="grey--text text--darken-4">{{ nameInitials }} </span>
      </v-avatar>
      <div class="ms-2">
        <div class="text-none">{{ user.name }}</div>
        <div class="text-none">{{ $t(persona) }}</div>
      </div>
    </div>
    <hr v-if="$vuetify.breakpoint.mobile" />
    <v-list nav dense>
      <v-list-item-group active-class="deep-purple--text text--accent-4">
        <div tabindex="0">
          <v-list-item
            :to="'/UserSettings'"
            class="text-subtitle-2 grey--text text--darken-1"
            active-class="grey lighten-3 grey--text text--darken-4"
            role="listitem"
            aria-label="UserSettings"
          >
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ $t("userSettings") }}
            </v-list-item-content>
          </v-list-item>
        </div>
        <div tabindex="0">
          <v-list-item
            class="text-subtitle-2 grey--text text--darken-1"
            active-class="grey lighten-3 grey--text text--darken-4"
            role="listitem"
            aria-label="signOut"
            @click="logOut"
          >
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ $t("signOut") }}
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { removeAuthDetails, setAuthStatus } from "../../store/utils/utils";
export default {
  data: () => ({}),
  computed: {
    user() {
      return this.$store.state.User.user;
    },
    nameInitials() {
      var fullName = this.user.name.split(" ");
      if (fullName.length == 1) return fullName.shift().charAt(0).toUpperCase();
      var initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials.toUpperCase();
    },
    persona() {
      let persona = this.$store.state.User.user.persona;
      return persona;
    },
  },
  methods: {
    logOut() {
      setAuthStatus(false);
    },
  },
};
</script>
