<template>
  <v-app>
    <NavDrawer v-if="showDashboard" />
    <AppBar v-if="showDashboard" />
    <v-main :class="{ 'pa-0': !showDashboard }">
      <v-alert
        v-if="showImpersonateAlert"
        type="warning"
        color="#AD4C0B"
        icon="mdi-alert-outline"
        text
        class="caption font-weight-regular py-3 px-5"
        height="44px"
      >
        You're temporarily logged in as another user.
      </v-alert>
      <v-container
        fluid
        :class="
          showDashboard
            ? isSubscription
              ? 'fill-height'
              : ''
            : 'pa-0 fill-height'
        "
      >
        <router-view />
        <AccountSetupMain v-if="showCenterDialog && showDashboard" />
      </v-container>
    </v-main>
    <Footer v-if="showDashboard" />
  </v-app>
</template>

<script>
import AppBar from "@/components/molecules/AppBar";
import NavDrawer from "@/components/molecules/NavDrawer";
import Footer from "@/components/molecules/Footer";
import UserDataMixin from "@/mixins/UserData";
import { getAuthStatus, removeAuthDetails, getKeys } from "@/store/utils/utils";
import { bus } from "./main";
import AccountSetupMain from "@/components/workflow/accountSetup/AccountSetupMain.vue";

export default {
  name: "App",
  components: {
    AppBar,
    NavDrawer,
    Footer,
    AccountSetupMain,
  },
  mixins: [UserDataMixin],
  data: () => ({}),
  computed: {
    showDashboard() {
      return (
        this.$route.path &&
        this.$route.name !== "Signin" &&
        this.$route.name !== "Signup" &&
        this.$route.name !== "Password" &&
        this.$route.name !== "ContactUs"
      );
    },
    isSubscription() {
      return this.$route.name === "Subscription";
    },
    showCenterDialog() {
      if (
        this.$store.state.User.user.company_id ===
          "00000000-0000-0000-0000-000000000000" &&
        this.$store.state.User.user.type !== "Homeowner"
      ) {
        return true;
      } else return false;
    },
    showImpersonateAlert() {
      return (
        this.$store.state.User.isImpersonating ||
        (JSON.parse(localStorage.getItem("isPersonateUser")) &&
          this.showDashboard)
      );
    },
  },
  async created() {
    const urlParams = new URLSearchParams(location.search);
    const keys = getKeys(urlParams);
    if (keys.length && keys.includes("token")) {
      localStorage.clear();
    }
    const authStatus = getAuthStatus();
    if (this.showDashboard) {
      this.fetchUserData(authStatus);
    }

    bus.$on("loginStateChange", async (loggedinStatus) => {
      if (loggedinStatus) {
        await this.fetchUserData(loggedinStatus);
      } else {
        removeAuthDetails();
        this.$store.commit("resetState");
        this.fetchUserData(loggedinStatus);
        this.$router.push("/signin").catch();
      }
    });
  },
};
</script>
