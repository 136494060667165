var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      ref: "navDrawer",
      style: { overflow: "visible" },
      attrs: {
        app: "",
        value: _vm.drawer,
        "mini-variant": _vm.isMini,
        permanent: !_vm.isMobile,
      },
      on: { input: _vm.onInput },
    },
    [
      _c(
        "div",
        { staticClass: "my-5" },
        [
          !_vm.isMini
            ? _c("v-img", {
                staticClass: "mx-auto",
                attrs: {
                  width: "132",
                  height: "26",
                  src: require("@/assets/images/logo.png"),
                },
              })
            : _vm._e(),
          _vm.isMini
            ? _c("v-img", {
                staticClass: "ms-3 mt-6",
                attrs: {
                  height: "25",
                  width: "25",
                  src: require("@/assets/images/logoCropped.png"),
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isMobile
        ? _c(
            "v-app-bar-nav-icon",
            {
              staticClass: "white grey--text",
              style: {
                top: "5%",
                transform: "translate(100%, -50%)",
                borderRadius: "20%",
              },
              attrs: {
                role: "button",
                "aria-label": "nav",
                absolute: "",
                fab: "",
                right: "",
              },
              on: {
                click: function ($event) {
                  return _vm.toggleDrawer()
                },
              },
            },
            [_c("v-icon", [_vm._v(" mdi-menu-open ")])],
            1
          )
        : _vm._e(),
      _c("OptionsList"),
      _c("ThemeToggleBar", { attrs: { "is-mini": _vm.isMini } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }