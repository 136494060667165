var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-speed-dial",
        {
          attrs: { bottom: "", direction: "bottom", transition: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function () {
                return [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        width: !_vm.$vuetify.breakpoint.smAndDown ? "340" : "",
                        elevation: "0",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mr-3", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-9",
                              attrs: {
                                icon: "",
                                "aria-label": "message",
                                disabled: !_vm.isInAppNotificationEnabled,
                              },
                              on: { click: _vm.updateNotifications },
                            },
                            [
                              _vm.count > 0
                                ? _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        overlap: "",
                                        color: "#F26227",
                                        content: _vm.count,
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-bell-outline"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _c("v-icon", [_vm._v("mdi-bell-outline")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.fab,
            callback: function ($$v) {
              _vm.fab = $$v
            },
            expression: "fab",
          },
        },
        [
          !_vm.$vuetify.breakpoint.smAndDown && _vm.isInAppNotificationEnabled
            ? _c(
                "v-card",
                {
                  staticClass: "pb-1 overflow-auto",
                  attrs: { width: "340", "max-height": "600" },
                },
                [
                  _c("v-card-title", { staticClass: "pt-1" }, [
                    _vm._v(" Notifications "),
                  ]),
                  _vm._l(_vm.NotificationData, function (item, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "py-2 px-3" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "px-5 pb-0",
                                attrs: { cols: "3" },
                              },
                              [
                                _c(
                                  "v-avatar",
                                  { attrs: { color: "#C8E6C9" } },
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        " mdi-ticket-confirmation-outline "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "body-2 pb-0",
                                attrs: { cols: "9" },
                              },
                              [
                                _c("div", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.formatMessage(item.body))
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c("v-col", {
                              staticClass: "p1-1",
                              attrs: { cols: "3" },
                            }),
                            _c(
                              "v-col",
                              {
                                staticClass: "pl-4 pt-1 caption",
                                attrs: { cols: "9" },
                              },
                              [_vm._v(" " + _vm._s(item.time) + " ")]
                            ),
                          ],
                          1
                        ),
                        _c("v-row", [_c("v-divider")], 1),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _c("v-card"),
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-dialog",
                {
                  attrs: { fullscreen: "" },
                  model: {
                    value: _vm.fab,
                    callback: function ($$v) {
                      _vm.fab = $$v
                    },
                    expression: "fab",
                  },
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pb-1" },
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(" " + _vm._s(_vm.$t("notifications")) + " "),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { "aria-label": "cross", icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.fab = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm._l(_vm.NotificationData, function (item, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "py-2 px-3" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "px-5 pb-0",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c(
                                      "v-avatar",
                                      { attrs: { color: "#C8E6C9" } },
                                      [
                                        item.category === "ticketCreated"
                                          ? _c("v-icon", [
                                              _vm._v(
                                                " mdi-ticket-confirmation-outline "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "body-2 pb-0",
                                    attrs: { cols: "9" },
                                  },
                                  [_vm._v(" " + _vm._s(item.body) + " ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c("v-col", {
                                  staticClass: "p1-1",
                                  attrs: { cols: "3" },
                                }),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pt-1 caption",
                                    attrs: { cols: "9" },
                                  },
                                  [_vm._v(" " + _vm._s(item.time) + " ")]
                                ),
                              ],
                              1
                            ),
                            _c("v-row", [_c("v-divider")], 1),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }