var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "appbar-header",
      attrs: { app: "", elevation: "0", color: "white" },
    },
    [
      _vm.isMobile
        ? _c(
            "v-icon",
            {
              attrs: { role: "button", "aria-label": "navigation open" },
              on: { click: _vm.toggleDrawer },
            },
            [_vm._v(_vm._s("mdi-menu"))]
          )
        : _c(
            "v-icon",
            {
              staticClass: "ml-3",
              attrs: {
                role: "button",
                "aria-label": _vm.drawer
                  ? "navigation close"
                  : "navigation open",
              },
              on: { click: _vm.toggleDrawer },
            },
            [_vm._v(_vm._s(_vm.drawer ? "mdi-menu" : "mdi-menu-open"))]
          ),
      _c(
        "div",
        { staticClass: "my-2" },
        [
          _vm.$vuetify.breakpoint.mobile
            ? _c("v-img", {
                attrs: {
                  src: require("@/assets/images/logoCropped.png"),
                  height: "auto",
                  "max-width": "31",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.$vuetify.breakpoint.mobile
        ? _c(
            "span",
            { staticClass: "grey--text text-h6 text--darken-4 ml-6" },
            [_vm._v(_vm._s(_vm.companyName))]
          )
        : _vm._e(),
      _c("v-spacer"),
      !_vm.$vuetify.breakpoint.mobile || !_vm.drawer
        ? _c("Notification")
        : _vm._e(),
      !_vm.$vuetify.breakpoint.mobile
        ? _c(
            "div",
            { staticClass: "mr-4 float-right", attrs: { tabindex: "0" } },
            [
              _c("div", { staticClass: "grey--text text--darken-4 h6" }, [
                _vm._v(" " + _vm._s(_vm.user.name) + " "),
              ]),
              _c(
                "div",
                { staticClass: "grey--text text--darken-1 h5 float-right" },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.persona)) + " ")]
              ),
            ]
          )
        : _vm._e(),
      _c("UserMenu"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }