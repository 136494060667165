var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        left: "",
        "offset-y": "",
        "min-width": "200",
        tabindex: "0",
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _c(
                  "v-btn",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "ma-0 pa-0 text-none",
                        attrs: {
                          elevation: "0",
                          height: "42",
                          "min-width": "42",
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [
                    _c(
                      "v-avatar",
                      {
                        staticClass:
                          "avtar-user avatars-group light-blue lighten-4",
                        attrs: { size: "42" },
                      },
                      [
                        _vm._t("default"),
                        _c(
                          "span",
                          { staticClass: "grey--text text--darken-4" },
                          [_vm._v(_vm._s(_vm.nameInitials))]
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _vm.$vuetify.breakpoint.mobile
        ? _c(
            "div",
            { staticClass: "px-2 py-3 d-flex menu white" },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "avtar-user avatars-group light-blue lighten-4",
                  attrs: { size: "42" },
                },
                [
                  _c("span", { staticClass: "grey--text text--darken-4" }, [
                    _vm._v(_vm._s(_vm.nameInitials) + " "),
                  ]),
                ]
              ),
              _c("div", { staticClass: "ms-2" }, [
                _c("div", { staticClass: "text-none" }, [
                  _vm._v(_vm._s(_vm.user.name)),
                ]),
                _c("div", { staticClass: "text-none" }, [
                  _vm._v(_vm._s(_vm.$t(_vm.persona))),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.mobile ? _c("hr") : _vm._e(),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { "active-class": "deep-purple--text text--accent-4" } },
            [
              _c(
                "div",
                { attrs: { tabindex: "0" } },
                [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "text-subtitle-2 grey--text text--darken-1",
                      attrs: {
                        to: "/UserSettings",
                        "active-class":
                          "grey lighten-3 grey--text text--darken-4",
                        role: "listitem",
                        "aria-label": "UserSettings",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-3" },
                        [_c("v-icon", [_vm._v("mdi-cog-outline")])],
                        1
                      ),
                      _c("v-list-item-content", [
                        _vm._v(" " + _vm._s(_vm.$t("userSettings")) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { tabindex: "0" } },
                [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "text-subtitle-2 grey--text text--darken-1",
                      attrs: {
                        "active-class":
                          "grey lighten-3 grey--text text--darken-4",
                        role: "listitem",
                        "aria-label": "signOut",
                      },
                      on: { click: _vm.logOut },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-3" },
                        [_c("v-icon", [_vm._v("mdi-logout")])],
                        1
                      ),
                      _c("v-list-item-content", [
                        _vm._v(" " + _vm._s(_vm.$t("signOut")) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }